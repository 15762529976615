import { makeStyles } from '@material-ui/core/styles';
import alexandria_brand_images_LR from '../../images/png/4_alexandria_brand_images_LR.png';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    height: '100%',
  },

  container: {
    height: '100%',
    backgroundImage: `url(${alexandria_brand_images_LR})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top 4rem left 31.5rem',
    backgroundSize: '676px',
    [theme.breakpoints.down(theme.breakpoints.values.wide)]: {
      backgroundPosition: 'top 4rem left 24rem',
      backgroundSize: '496px',
    },

    [theme.breakpoints.down(theme.breakpoints.values.lg)]: {
      backgroundPosition: 'top 5.5rem left 22.4rem',
      backgroundSize: '393px',
    },
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      background: 'none',
    },
  },
  'content-container': {
    margin: '11px 0px 0px 150px',
    minHeight: '1000px',
    [theme.breakpoints.down(theme.breakpoints.values.wide)]: {
      minHeight: '815px',
      margin: '0px 0px 0px 120px',
    },

    [theme.breakpoints.down(theme.breakpoints.values.lg)]: {
      margin: '0px 0px 0px 103px',
    },
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      minHeight: '0',
      margin: '0px 0px 0px 103px',
    },
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      margin: '0px 0px 0px 103px',
    },
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      margin: '16px 0px 0px 16px',
    },
    [theme.breakpoints.down(400)]: {
      margin: '0px',
    },
  },
  [theme.breakpoints.up(theme.breakpoints.values.xs)]: {
    container: {
      width: '100%',
    },
  },

  [theme.breakpoints.up(theme.breakpoints.values.tablet)]: {
    container: {
      width: '100%',
    },
    'middle-container': {
      display: 'flex',
      flexDirection: 'row',
      flex: 1,
      justifyContent: 'space-between',
      alignItems: 'center',
      marginLeft: '0px',
      '& .spacer': {
        width: '400px',
      },
    },
    main: {
      display: 'flex',
      flexDirection: 'row',
      flex: 1,
      justifyContent: 'flex-start',
      alignItems: 'center',
      '& .spacer': {
        width: '624px',
      },
    },
  },
  [theme.breakpoints.down(theme.breakpoints.values.wide)]: {
    main: {
      '& .spacer': {
        width: '413px',
      },
    },
  },
  [theme.breakpoints.down(theme.breakpoints.values.lg)]: {
    main: {
      '& .spacer': {
        width: '356px',
      },
    },
  },
  [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
    main: {
      '& .spacer': {
        width: '356px',
      },
    },
  },
  [theme.breakpoints.down(400)]: {
    main: {
      '& .spacer': {
        width: '100%',
      },
    },
  },

  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '16px 0px 0px 32px',
    [theme.breakpoints.down(theme.breakpoints.values.xs)]: {
      margin: '16px 32px 0px 16px',
    },
  },

  button: {
    color: theme.palette.secondary.main,
    '&.header': {
      width: '200px',
      [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
        width: '90px',
      },
    },
    '&.forgot-password': {
      textTransform: 'none',
      textDecoration: 'underline',
    },
    borderRadius: '23px',
  },
  'forgot-password': {
    marginTop: '32px',
  },
  footer: {
    padding: '4rem  4rem 4rem 1rem',
    display: 'flex',
    flexDirection: 'column',
    '& .legal': {
      color: theme.palette.white,
      margin: '1rem 0px 0px 2rem',
      '& a': {
        color: theme.palette.white,
        margin: '1rem 1rem 1rem 0',
        fontSize: '1rem',
        whiteSpace: 'nowrap',
        letterSpacing: '0.1rem',
        textTransform: 'uppercase',
      },
      [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
        margin: 0,
        flex: '0 1 auto',
        display: 'flex',
        flexDirection: 'column',
      },
    },
    '& .info': {
      color: theme.palette.white,
      margin: '1rem 0px 0px 2rem',
      display: 'flex',
      [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
        flexDirection: 'column',
        margin: 0,
      },
      '& div': {
        margin: '1rem 1rem 1rem 0',
      },
    },
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      alignItems: 'center',
      padding: '4rem  4rem 4rem 4rem',
    },
    [theme.breakpoints.down(400)]: {
      padding: '0',
    },
  },
}));

export default useStyles;
