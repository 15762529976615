import { ButtonProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: 'Scto Grotesk A',
    fontSize: '16px',
    height: '46px',
    color: (props: ButtonProps) => {
      switch (props.color) {
        case 'primary':
          return theme.palette.primary.main;
          break;
        case 'secondary':
          return theme.palette.secondary.main;
        default:
          return theme.palette.primary.main;
      }
    },
    background: 'transparent',
    border: (props: ButtonProps) => {
      switch (props.color) {
        case 'primary':
          return `1px solid ${theme.palette.primary.main}`;
          break;
        case 'secondary':
          return `1px solid ${theme.palette.secondary.main}`;
        default:
          return `1px solid ${theme.palette.primary.main}`;
      }
    },
    borderRadius: '23px',
    opacity: 1,
    padding: '0',
    width: '322px',
    '&:hover': {
      background: (props: ButtonProps) => {
        switch (props.color) {
          case 'primary':
            return theme.palette.primary.main;
            break;
          case 'secondary':
            return theme.palette.secondary.main;
          default:
            return theme.palette.primary.main;
        }
      },
      color: theme.palette.white,
    },
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      width: '100%',
    },
  },
}));

export default useStyles;
