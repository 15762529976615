import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Report as ABCReport } from '../../types/abc';
import { UtmostReport } from '../../types/utmost';

const initialState: {
  abcReports: ABCReport[];
  utmostReports?: UtmostReport[];
} = { abcReports: [], utmostReports: undefined };

const reportSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    setUtmostReports(state, action: PayloadAction<UtmostReport[]>) {
      state.utmostReports = action.payload;
    },
    setAbcReports(state, action: PayloadAction<ABCReport[]>) {
      state.abcReports = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase('LOGOUT', () => {
        return initialState;
      })
      .addCase('CHANGE_USER', () => {
        return initialState;
      });
  },
});

export const { setUtmostReports, setAbcReports } = reportSlice.actions;
export default reportSlice.reducer;
