import firebase from 'firebase/app';
import { isToday } from 'date-fns';
import { DataSource } from '../dataSource';
import { store } from '../../store';
import {
  setStructuresPositions,
  setStructuresTransactions,
  setStructuresReports,
  addStructuresSecurities,
  addStructuresSecurityInfo,
} from '../../store/investments/structures/actions';
import { getStructuredInvestmentsInfoAll } from '../../utils/contentful';
import { UpdateType } from '../../types/api';
import {
  FSOverviewPosition,
  FSStructureSecurityData,
  FSReport,
} from '../../types/firestore';
import { FaTransaction, FATransactionStatus } from '../../types/FA';
import { StructuredInvestment } from '../../types/contentful';
import { DataSubscription } from '../dataSubscription';
import { utcToZonedTime } from 'date-fns-tz';
import { TZ } from '../../utils/date';

async function querySecurities(
  db: firebase.firestore.Firestore,
  securityIsins: string[]
) {
  const securityRef = await db
    .collection('securities')
    .where('security.isinCode', 'in', securityIsins)
    .get();
  return securityRef.docs.map((v) => v.data() as FSStructureSecurityData);
}

/** Queries additional Security & Market data from firestore  */
export async function getSecurityData(
  db: firebase.firestore.Firestore,
  securityIsins: string[]
): Promise<FSStructureSecurityData[]> {
  //'in' filters support a maximum of 10 elements in the value array.
  const batchSize = 10;
  let securities = [] as FSStructureSecurityData[];
  for (let i = 0; i < securityIsins.length; i += batchSize) {
    const fsSecurities = await querySecurities(
      db,
      securityIsins.slice(i, i + batchSize)
    );
    securities = [...securities, ...fsSecurities];
  }
  return securities;
}

export const getStructuresContentfulData = async (
  isins: string[]
): Promise<StructuredInvestment[]> => {
  return await getStructuredInvestmentsInfoAll(isins);
};

export class StructuresDataSource extends DataSource {
  name: UpdateType = 'structures-overview-positions';
  subscribe(
    uid: string,
    ownUid: string,
    db: firebase.firestore.Firestore
  ): DataSubscription {
    const overviewsRef = db
      .collection('structures')
      .doc(uid)
      .collection('overviews')
      .doc(utcToZonedTime(Date.now(), TZ).toISOString().split('T')[0]);

    const firestoreUnsubscribe = overviewsRef.onSnapshot(async (snapshot) => {
      const data = snapshot.data() as FSOverviewPosition | undefined;
      const idToken = await this.getIdToken();

      if (data) {
        store.dispatch(setStructuresPositions(data.positions));
      } else if (idToken) {
        this.requestUpdate({ target: this.name, parameters: {}, uid }, idToken);
      }

      if (data) {
        /** get security and its' marketdata information */
        const isins = data.positions.map((p) => p.securityIsinCode.toString());
        getSecurityData(db, isins).then((v) => {
          store.dispatch(addStructuresSecurities(v));
        });
        getStructuresContentfulData(isins).then((v) =>
          store.dispatch(addStructuresSecurityInfo(v))
        );
      }
    });

    const unsubscribe = () => {
      firestoreUnsubscribe();
    };

    return new DataSubscription(this.name, unsubscribe);
  }
}

export class StructuresTransactionDataSource extends DataSource {
  name: UpdateType = 'structures-transactions';
  subscribe(
    uid: string,
    ownUid: string,
    db: firebase.firestore.Firestore
  ): DataSubscription {
    const overviewsRef = db
      .collection('structures')
      .doc(uid)
      .collection('transactions');

    const firestoreUnsubscribe = overviewsRef.onSnapshot(async (snapshot) => {
      const transactions = snapshot.docs
        .map((doc) => doc.data() as FaTransaction)
        .filter((doc) => doc.status === FATransactionStatus.Ok);

      const idToken = await this.getIdToken();

      const dataUpdates =
        store.getState().dataUpdates['structures-transactions'];

      store.dispatch(setStructuresTransactions(transactions));

      // If the data has not been updated today and there isn't an update ongoing, ask for an update
      if (!isToday(dataUpdates?.t || 0) && !dataUpdates?.updating && idToken) {
        this.requestUpdate(
          {
            target: 'structures-transactions',
            parameters: null,
            uid,
          },
          idToken
        );
      }

      /** get security and its' marketdata information */
      const securityIsins = transactions.reduce(
        (prev: string[], curr): string[] => {
          if (curr.security && !prev.includes(curr.security.isinCode))
            return [...prev, curr.security.isinCode];
          return prev;
        },
        []
      );
      if (securityIsins) {
        getSecurityData(db, securityIsins).then((v) => {
          store.dispatch(addStructuresSecurities(v));
        });
        getStructuresContentfulData(securityIsins).then((v) =>
          store.dispatch(addStructuresSecurityInfo(v))
        );
      }
    });

    const unsubscribe = () => {
      firestoreUnsubscribe();
    };
    return new DataSubscription(this.name, unsubscribe);
  }
}

export class StructuresReportDataSource extends DataSource {
  name: UpdateType = 'structures-generate-report';
  subscribe(
    uid: string,
    ownUid: string,
    db: firebase.firestore.Firestore
  ): DataSubscription {
    const overviewsRef = db
      .collection('structures')
      .doc(uid)
      .collection('reports');

    const unsubscribe = overviewsRef.onSnapshot(
      async (snapshot) => {
        const data = snapshot.docs.map((doc) => doc.data()) as FSReport[];
        const idToken = await this.getIdToken();

        /**
         * TODO: Check when the snapshot was last updated
         * if never try to get data from beginning of time
         * else use last update timestamp as BeginDate value
         */
        if (data.length < 1 && idToken) {
          console.log('TODO: Sending update request for: reports');
          /*  this.requestUpdate(
            {
              target: 'structures-generate-report',
              parameters: null,
            },
            idToken
          ); */
        }
        store.dispatch(setStructuresReports(data));
      },
      (error) => {
        console.error(error);
      }
    );
    return new DataSubscription(this.name, unsubscribe);
  }
}
