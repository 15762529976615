import { ViewableUser } from '../../types/local';
import { Logout } from '../base';

export enum ActionTypes {
  SELECT_TAB = 'SELECT_TAB',
  SET_QUERY = 'SET_QUERY',
  SET_AUTHENTICATED = 'SET_AUTHENTICATED',
  SET_ACTIVITY_FEED = 'SET_ACTIVITY_FEED',
  SET_NAVIGATION = 'SET_NAVIGATION',
  ADD_OPENMENU = 'ADD_OPENMENU',
  REMOVE_OPENMENU = 'REMOVE_OPENMENU',
  SET_POPPERMENU = 'SET_POPPERMENU',
  SET_REDIRECT = 'SET_REDIRECT',
  SET_LOADING = 'SET_LOADING',
  SET_VIEWED_USER = 'SET_VIEWED_USER',
  SET_VIEWED_USER_UID = 'SET_VIEWED_USER_UID',
  SET_NOTIFICATION = 'SET_NOTIFICATION',
  SET_SEARCH_FOCUSED = 'SET_SEARCH_FOCUSED',
  SET_INVESTMENT_INFO_OPEN = 'SET_INVESTMENT_INFO_OPEN',
  SET_PAGE_SCROLLED_DOWN = 'SET_PAGE_SCROLLED_DOWN',
}

export interface State {
  selectedTab: string;
  query: string;
  isActivityFeedOpen: boolean;
  isNavigationOpen: boolean;
  openMenus: string[];
  popperMenu: string;
  redirect: Location | null;
  isLoading: boolean;
  /**
   * The user that it currently viewed
   */
  viewedUser: ViewableUser | null;
  /**
   * The currently viewed user's uid, that is set automatically when `viewedUser` is set, and persisted
   * so that we can know the viewedUser after a refresh.
   */
  viewedUserUid: string | null;
  notification: {
    open: boolean;
    message: string;
    type: 'primary' | 'secondary' | 'error';
  };
  isSearchFocused: boolean;
  isInvestmentInfoOpen: boolean;
  pageScrolledDown: boolean;
}

interface SelectTabAction {
  type: typeof ActionTypes.SELECT_TAB;
  payload: State['selectedTab'];
}

interface SetQueryAction {
  type: typeof ActionTypes.SET_QUERY;
  payload: State['query'];
}

interface SetActivityFeed {
  type: typeof ActionTypes.SET_ACTIVITY_FEED;
  payload: State['isActivityFeedOpen'];
}

interface SetNavigation {
  type: typeof ActionTypes.SET_NAVIGATION;
  payload: State['isNavigationOpen'];
}

interface AddOpenMenu {
  type: typeof ActionTypes.ADD_OPENMENU;
  payload: string;
}

interface RemoveOpenMenu {
  type: typeof ActionTypes.REMOVE_OPENMENU;
  payload: string;
}

interface SetPopperMenu {
  type: typeof ActionTypes.SET_POPPERMENU;
  payload: string;
}

interface SetRedirect {
  type: typeof ActionTypes.SET_REDIRECT;
  payload: Location | null;
}
interface SetLoading {
  type: typeof ActionTypes.SET_LOADING;
  payload: boolean;
}

interface SetViewedUserUid {
  type: typeof ActionTypes.SET_VIEWED_USER_UID;
  payload: State['viewedUserUid'];
}

interface SetViewedUser {
  type: typeof ActionTypes.SET_VIEWED_USER;
  payload: State['viewedUser'];
}

interface SetNotification {
  type: typeof ActionTypes.SET_NOTIFICATION;
  payload: State['notification'];
}

interface SetSearchFocused {
  type: typeof ActionTypes.SET_SEARCH_FOCUSED;
  payload: State['isSearchFocused'];
}

interface SetInvestmentInfoOpen {
  type: typeof ActionTypes.SET_INVESTMENT_INFO_OPEN;
  payload: State['isInvestmentInfoOpen'];
}

interface SetPageScrolledDown {
  type: typeof ActionTypes.SET_PAGE_SCROLLED_DOWN;
  payload: State['pageScrolledDown'];
}

export type Actions =
  | Logout
  | SelectTabAction
  | SetQueryAction
  | SetActivityFeed
  | SetNavigation
  | AddOpenMenu
  | RemoveOpenMenu
  | SetPopperMenu
  | SetRedirect
  | SetLoading
  | SetViewedUserUid
  | SetViewedUser
  | SetNotification
  | SetSearchFocused
  | SetInvestmentInfoOpen
  | SetPageScrolledDown;
