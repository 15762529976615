import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = 354;
const burgerWidth = 52;
const headerHeight = 85;
const screenMaxWidth = 1920;

const useStyles = makeStyles((theme) => ({
  logo: {
    border: 0,
    backgroundColor: 'transparent',
    padding: 0,
    height: '53px',
    cursor: 'pointer',
    '& > svg': {
      width: '204px',
      height: '53px',
    },
    [theme.breakpoints.down(400)]: {
      '& > svg': {
        width: '145px',
        height: '53px',
      },
    },
  },
  'header-container': {
    position: 'fixed',
    left: 0,
    zIndex: 9999,
    width: '100%',
    [theme.breakpoints.up(screenMaxWidth)]: {
      width: screenMaxWidth,
      left: `calc((100vw - ${screenMaxWidth}px) / 2)`,
      overflowX: 'hidden',
    },
    backgroundColor: 'white',

    transition: theme.transitions.create(['left', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    '&.open': {
      left: `${drawerWidth}px`,
      width: `calc(100vw - ${drawerWidth}px)`,
      [theme.breakpoints.up(screenMaxWidth)]: {
        width: `calc(${screenMaxWidth}px - ${drawerWidth}px)`,
        left: `calc((100vw - ${screenMaxWidth}px) / 2 + ${drawerWidth}px)`,
      },
      transition: theme.transitions.create(['left', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      [theme.breakpoints.down(400)]: {
        width: `100vw`,
        left: 'calc(100% - 35px)',
      },
      '&::after': {
        [theme.breakpoints.up(screenMaxWidth)]: {
          width: `calc(${screenMaxWidth}px - ${drawerWidth}px)`,
          left: `calc((100vw - ${screenMaxWidth}px) / 2 + ${drawerWidth}px)`,
        },
      },
    },
    // The shadow below header is created with a pseudo-element that has the shadow.
    // The animation is done by transitioning the opacity of the pseudo-element.
    // This is supposed to need a lot less re-painting, and so perform better.
    '&::after': {
      content: '""',
      position: 'fixed',
      top: 0,
      zIndex: -1,
      width: '100%',
      maxWidth: screenMaxWidth,
      height: headerHeight,
      opacity: 0,
      boxShadow: '4px 4px 12px #00000029',
      transition: theme.transitions.create(['opacity'], {
        easing: theme.transitions.easing.sharp,
        duration: 80,
      }),
    },
    '&.scrolled-down::after': {
      opacity: 1,
      transition: theme.transitions.create(['opacity'], {
        easing: theme.transitions.easing.sharp,
        duration: 80,
      }),
    },
  },
  header: {
    width: '100vw',
    [theme.breakpoints.up(screenMaxWidth)]: {
      width: screenMaxWidth,
    },
    display: 'flex',
    justifyContent: 'flex-start',
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.secondary.main,
    textTransform: 'uppercase',
    height: headerHeight,
    '& > *': {
      paddingTop: '16px',
      backgroundColor: theme.palette.white,
    },
    '& h3': {
      paddingLeft: '10px',
    },
    '&.open': {
      width: '100vw',
      [theme.breakpoints.up(screenMaxWidth)]: {
        width: screenMaxWidth,
      },
    },
    '&.viewed-user': {
      marginTop: '32px',
    },
  },
  burger: {
    cursor: 'pointer',
    backgroundColor: theme.palette.primary.main,
    border: '0px',
    width: `${burgerWidth}px`,
    height: `${burgerWidth}px`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'fixed',
    transition: theme.transitions.create('left', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    '&.open': {
      left: drawerWidth - burgerWidth / 2,
      [theme.breakpoints.up(screenMaxWidth)]: {
        left: `calc((100vw - ${screenMaxWidth}px)/2 + ${
          drawerWidth - burgerWidth / 2
        }px)`,
      },
      zIndex: 999999,
      [theme.breakpoints.down(400)]: {
        left: `calc(100vw - ${burgerWidth + 10}px)`,
      },
    },
    [theme.breakpoints.up('xs')]: {
      borderRadius: '0px 12px 12px 0px ',
      '&.open': {
        borderRadius: '12px',
      },
      left: 0,
    },
    [theme.breakpoints.up('md')]: {
      borderRadius: '12px',
      left: 16,
    },
    [theme.breakpoints.up(screenMaxWidth)]: {
      left: `calc((100vw - ${screenMaxWidth}px)/2 + 16px)`,
    },
  },
  search: {
    flexGrow: 1,
    justifyContent: 'flex-end',
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    [theme.breakpoints.up('xs')]: {
      paddingRight: '65px',
    },
    [theme.breakpoints.up('md')]: {
      paddingRight: '270px',
    },
  },
  'search-mobile': {
    display: 'flex',
    alignItems: 'center',
    marginRight: '16px',
    height: '52px',
  },
  'logo-container': {
    display: 'flex',
    borderRadius: '0px 0px 0px 0px',
    paddingLeft: '68px',
    position: 'relative',
    transition: theme.transitions.create('border-radius', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    '&.open': {
      marginLeft: '0px',
      borderRadius: '46px 0px 0px 0px',
      paddingLeft: '46px',
      transition: theme.transitions.create('border-radius', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
  },
  'activity-header': {
    marginTop: '0px',
    paddingTop: '0px',
    overflowX: 'hidden',
    backgroundColor: 'transparent',
    position: 'absolute',
    right: 0,
    transition: theme.transitions.create('right', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    '&.nav-open': {
      right: '-354px',
      transition: theme.transitions.create('right', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      [theme.breakpoints.down(400)]: {
        right: 'calc(-(100% - 35px))',
      },
    },
  },
}));

export default useStyles;
