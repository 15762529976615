import React, { useEffect, useState } from 'react';
import firebase from 'firebase/app';
import { useLocation } from 'react-router-dom';

import {
  handleResetPassword,
  handleRecoverEmail,
  handleVerifyEmail,
} from './handlers';

import Spinner from '../../components/LoadingIndicator';

import useStyles from './styles';
import Response from './Response';
import { Paper } from '@material-ui/core';

/**
 * View for Firebase custom email action handler
 * https://firebase.google.com/docs/auth/custom-email-handler
 */

const LANGUAGE = 'fi';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const isValidQuery = (query: URLSearchParams) => {
  const requiredParams = ['mode', 'oobCode', 'apiKey'];
  const hasAllParams = requiredParams.every((v) => {
    if (!query.get(v)) {
      return false;
    }
    return true;
  });

  return hasAllParams;
};

const handleManagementAction = async (
  mode: string,
  actionCode: string,
  continueUrl: string,
  lang: string
): Promise<React.ReactElement> => {
  const auth = firebase.auth();

  switch (mode) {
    case 'resetPassword':
      // Display reset password handler and UI.
      return handleResetPassword(auth, actionCode, continueUrl, lang);
    case 'recoverEmail':
      // Display email recovery handler and UI.
      return handleRecoverEmail(auth, actionCode, lang);
    case 'verifyEmail':
      // Display email verification handler and UI.
      return await handleVerifyEmail(auth, actionCode, continueUrl, lang);
    default:
      return Promise.resolve(
        <Response success={false}>
          <div>Virhe: tuntematon toiminto</div>
        </Response>
      );
  }
};
const UserManagement = () => {
  const classes = useStyles();
  const query = useQuery();
  const [component, setComponent] = useState(<></>);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function doAction() {
      if (!loading) {
        setLoading(true);
        const response = await handleManagementAction(
          query.get('mode') || '',
          query.get('oobCode') || '',
          query.get('continueUrl') || '',
          query.get('lang') || LANGUAGE
        );
        setComponent(response);
        setLoading(false);
      }
    }

    if (isValidQuery(query)) doAction();
    else
      setComponent(
        <Response success={false}>
          <div>Virhe: Puuttuvia parametreja</div>
        </Response>
      );
  }, []);

  return (
    <div className={classes.container}>
      {loading ? <Spinner variant="medium" /> : <>{component}</>}
    </div>
  );
};

export default UserManagement;
