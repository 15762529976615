import { Office } from '../../types/abc';
import { FSAdvisor } from '../../types/firestore';
import { Logout } from '../base';

export enum ActionTypes {
  ADD_ACTIVITY = 'ADD_ACTIVITY',
  SET_ACTIVITIES = 'SET_ACTIVITIES',
  RESET_ACTIVITIES = 'RESET_ACTIVITIES',
  SET_BOOKINGS = 'SET_BOOKINGS',
}

export interface Timestamp {
  nanoseconds: number;
  seconds: number;
}
export interface Activity {
  id: string;
  title: string;
  body: string;
  type: string;
  header: string;
  author: {
    name: string;
    photo: string;
    time?: Timestamp;
  };
  created_at?: Timestamp;
  updated_at?: Timestamp;
  seen?: boolean;
  url?: string;
}

export enum Kanava {
  Toimistolla = 1,
  'Toimiston ulkopuolella' = 2,
  Puhelimessa = 3,
  Videotapaaminen = 4,
}

export interface Booking {
  Alkuaika: Timestamp | null;
  Kanava: Kanava;
  KanavanSelite: string;
  KayntiID: number;
  Loppuaika: Timestamp | null;
  NeuvonantajaID: number;
  Neuvonantaja?: FSAdvisor;
  Pvm: Timestamp;
  ToimistoID: number;
}

export type Meeting = Booking & {
  Toimisto?: Office;
};

export interface State {
  activities: Activity[];
  bookings: Booking[];
}

interface AddActivity {
  type: typeof ActionTypes.ADD_ACTIVITY;
  payload: Activity;
}

interface SetActivities {
  type: typeof ActionTypes.SET_ACTIVITIES;
  payload: State['activities'];
}

interface SetBookings {
  type: typeof ActionTypes.SET_BOOKINGS;
  payload: State['bookings'];
}

export type Actions = Logout | AddActivity | SetActivities | SetBookings;
