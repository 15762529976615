import { gql } from 'graphql-request';
import { getClient } from './graphql';
import {
  Fund,
  StructuredInvestment,
  Office,
  Page,
  Insurance,
} from '../types/contentful';

export const getFundsInfo = async (ISIN: string): Promise<Fund> => {
  const client = getClient();
  const query = gql`
    query getData($ISIN: String) {
      fund: fundCollection(where: { isin: $ISIN }) {
        items {
          sys {
            id
          }
          title
          description
          previewBody
          slug
          isin
          riskRating
          image {
            url
          }
          portfolioManagersReview {
            title
            fileName
            url
          }
        }
      }
    }
  `;

  const data = (await client.request(query, { ISIN })) as {
    fund: { items: Fund[] };
  };
  return data.fund.items[0];
};

export const getFundsInfoAll = async (ISIN: string[]): Promise<Fund[]> => {
  const client = getClient();
  const query = gql`
    query getData($ISIN: [String]) {
      fund: fundCollection(where: { isin_in: $ISIN }) {
        items {
          sys {
            id
          }
          title
          description
          previewBody
          isin
          slug
          riskRating
          image {
            url
          }
          portfolioManagersReview {
            title
            fileName
            url
          }
        }
      }
    }
  `;

  const data = (await client.request(query, { ISIN })) as {
    fund: { items: Fund[] };
  };
  return data.fund.items;
};

export const getStructuredInvestmentsInfo = async (
  ISIN: string
): Promise<StructuredInvestment> => {
  const client = getClient();
  const query = gql`
    query getData($ISIN: String) {
      structuredInvestment: structuredInvestmentCollection(
        where: { isin: $ISIN }
      ) {
        items {
          sys {
            id
          }
          title
          description
          isin
          slug
          image {
            url
          }
        }
      }
    }
  `;

  const data = (await client.request(query, { ISIN })) as {
    structuredInvestment: { items: StructuredInvestment[] };
  };

  return data.structuredInvestment.items[0];
};

export const getStructuredInvestmentsInfoAll = async (
  ISIN: string[]
): Promise<StructuredInvestment[]> => {
  const client = getClient();
  const query = gql`
    query getData($ISIN: [String]) {
      structuredInvestment: structuredInvestmentCollection(
        where: { isin_in: $ISIN }
      ) {
        items {
          sys {
            id
          }
          title
          description
          isin
          slug
          image {
            url
          }
          overviewLink
        }
      }
    }
  `;

  const data = (await client.request(query, { ISIN })) as {
    structuredInvestment: { items: StructuredInvestment[] };
  };

  return data.structuredInvestment.items;
};

export const getInsurancesInfoAll = async (): Promise<Insurance[]> => {
  const client = getClient();
  const query = gql`
    query {
      insuranceCollection {
        items {
          sys {
            id
          }
          title
          description
          slug
          image {
            url
          }
        }
      }
    }
  `;
  const data = (await client.request(query)) as {
    insuranceCollection: { items: Insurance[] };
  };

  return data.insuranceCollection.items;
};

export const getOfficeInfo = async (title: string): Promise<Office> => {
  const client = getClient();
  const query = gql`
    query getData($title: String) {
      office: officeCollection(where: { title_contains: $title }) {
        items {
          sys {
            id
          }
          title
          city
          description
          address
          location {
            lat
            lon
          }
        }
      }
    }
  `;

  const data = (await client.request(query, { title })) as {
    office: { items: Office[] };
  };

  return data.office.items[0];
};

export const getPageInfo = async (title: string): Promise<Page> => {
  const client = getClient();
  const query = gql`
    query getData($title: String) {
      page: pageCollection(where: { title: $title }) {
        items {
          sys {
            id
          }
          title
          heading
          leadParagraph
          slug
          image {
            url
          }
        }
      }
    }
  `;

  const data = (await client.request(query, { title })) as {
    page: { items: Page[] };
  };

  return data.page.items[0];
};

export const getPage = async (slug: string): Promise<Page> => {
  const client = getClient();
  const query = gql`
    query getData($slug: String) {
      page: pageCollection(where: { slug: $slug }) {
        items {
          sys {
            id
          }
          slug
          title
          heading
          leadParagraph
          image {
            url
          }
          body
        }
      }
    }
  `;

  const data = (await client.request(query, { slug })) as {
    page: { items: Page[] };
  };

  return data.page.items[0];
};
