import React from 'react';
import clsx from 'clsx';
import useStyles from '../styles';

/**
 *  Response component to show on success or error
 */
const Response = (props: { success: boolean; children: React.ReactNode }) => {
  const classes = useStyles();
  const { children, success } = props;
  return (
    <div className={clsx(classes.response, success ? 'success' : 'error')}>
      {children}
    </div>
  );
};

export default Response;
