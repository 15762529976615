import React, { useEffect, useContext } from 'react';
import firebase from 'firebase/app';
import { GlobalContentDataSource } from '../../data-loader/dataSources';

import { useSelector } from 'react-redux';
import { RootState } from '../../store';

import Page from '../Page';
import RichText from '../../components/RichText';
import { DataSubscription } from '../../data-loader/dataSubscription';
import { MiddleContext } from '../../layouts/Middle';

const PrivacyPolicy = (): React.ReactElement => {
  const privacyPolicy = useSelector(
    (state: RootState) => state.content.globalContent?.privacyPolicy
  );
  const { anonymous } = useContext(MiddleContext);
  useEffect(() => {
    let globalContent = null as null | DataSubscription;
    if (anonymous) {
      const db = firebase.firestore();
      globalContent = new GlobalContentDataSource().subscribe('', '', db);
    }
    return () => {
      if (globalContent) globalContent.unsubscribe(true);
    };
  }, []);

  return (
    <Page title="Tietosuoja">
      <RichText
        body={privacyPolicy?.body.json}
        links={privacyPolicy?.body.links}
      />
    </Page>
  );
};

export default PrivacyPolicy;
