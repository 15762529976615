import React from 'react';
import clsx from 'clsx';
import { Typography, useMediaQuery, useTheme, Link } from '@material-ui/core';

import Button from '../../../components/Button';
import useStyles from './styles';

const question = <strong>Ei vielä tunnuksia?</strong>;

const getMobile = () => {
  return (
    <>
      <Typography variant="body1">{question}</Typography>
      <Typography variant="body1">
        Valitse alta “Aloita palvelun käyttö” <br /> tunnusten luomiseksi.
      </Typography>
    </>
  );
};

const getSmall = () => {
  return (
    <Typography variant="body1">
      {question} Valitse alta “Aloita palvelun käyttö” tunnusten luomiseksi.
    </Typography>
  );
};

const getDesktop = () => {
  return (
    <>
      <Typography variant="body1" className="question">
        {question}
      </Typography>
      <Typography variant="body1">
        Valitse alta “Aloita palvelun käyttö” <br /> tunnusten luomiseksi.
      </Typography>
    </>
  );
};

const Register = (): React.ReactElement => {
  const classes = useStyles();
  const theme = useTheme();
  const matchesMD = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.tablet)
  );
  const matchesSM = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.sm)
  );
  const matchesLG = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.lg)
  );

  const getInfoText = () => {
    if (matchesSM) return getMobile();
    else if (matchesMD) return getSmall();
    else if (matchesLG) return getMobile();
    return getDesktop();
  };

  return (
    <div className={classes['register-container']}>
      {getInfoText()}
      <Link
        component={Button}
        className={clsx(classes.button, 'register')}
        href="/auth/auth"
      >
        Aloita palvelun käyttö
      </Link>
    </div>
  );
};

export default Register;
