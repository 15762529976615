export interface LoginState {
  username: string;
  password: string;
  showPasswordField: boolean;
  showAzureInfo: boolean;
  error: string;
}

export type LoginAction =
  | { type: 'set-username'; payload: string }
  | { type: 'set-password'; payload: string }
  | { type: 'set-show-password'; payload: boolean }
  | { type: 'set-show-azure-info'; payload: boolean }
  | { type: 'set-error'; payload: string };

export const reducer = (state: LoginState, action: LoginAction): LoginState => {
  switch (action.type) {
    case 'set-username':
      return { ...state, username: action.payload };
    case 'set-password':
      return { ...state, password: action.payload };
    case 'set-show-password':
      return { ...state, showPasswordField: action.payload };
    case 'set-show-azure-info':
      return { ...state, showAzureInfo: action.payload };
    case 'set-error':
      return { ...state, error: action.payload };
    default:
      return { ...state };
  }
};
