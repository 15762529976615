import { Transaction as FundTransaction } from '../../../types/difs';
import { ChangeUser, Logout } from '../../base';

import { SecurityDetail } from '../../../types/morningstar';
import { PortfolioPosition } from '../../../types/difs';
import { Fund } from '../../../types/contentful';

export enum ActionTypes {
  SET_POSITION = 'SET_FUNDS_POSITION',
  SET_TRANSACTION = 'SET_FUNDS_TRANSACTION',
  CLEAR_FUNDS = 'CLEAR_FUNDS',
  SET_SECURITY_DETAILS = 'SET_FUNDS_SECURITY_DETAILS',
  ADD_SECURITY_DETAILS = 'ADD_FUNDS_SECURITY_DETAILS',
  SET_SECURITY_INFO = 'SET_SECURITY_INFO',
  ADD_SECURITY_INFO = 'ADD_SECURITY_INFO',
}

export interface FundsState {
  positions: PortfolioPosition[] | null;
  transactions: FundTransaction[] | null;
  securityDetail: SecurityDetail[];
  securityInfo: Fund[];
}

interface SetPortfolioPositions {
  type: typeof ActionTypes.SET_POSITION;
  payload: FundsState['positions'];
}

interface SetFundTransaction {
  type: typeof ActionTypes.SET_TRANSACTION;
  payload: FundsState['transactions'];
}

interface ClearFunds {
  type: typeof ActionTypes.CLEAR_FUNDS;
}

interface SetSecurityDetails {
  type: typeof ActionTypes.SET_SECURITY_DETAILS;
  payload: FundsState['securityDetail'];
}
interface AddSecurityDetails {
  type: typeof ActionTypes.ADD_SECURITY_DETAILS;
  payload: FundsState['securityDetail'];
}

interface SetSecurityInfo {
  type: typeof ActionTypes.SET_SECURITY_INFO;
  payload: FundsState['securityInfo'];
}
interface AddSecurityInfo {
  type: typeof ActionTypes.ADD_SECURITY_INFO;
  payload: FundsState['securityInfo'];
}

export type Actions =
  | Logout
  | ChangeUser
  | SetPortfolioPositions
  | SetFundTransaction
  | ClearFunds
  | SetSecurityDetails
  | AddSecurityDetails
  | SetSecurityInfo
  | AddSecurityInfo;
