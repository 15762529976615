import { Reducer } from '@reduxjs/toolkit';
import { State, ActionTypes as actions, Actions } from './types';

const INITIAL_STATE: State = {};

export const calendarReducer: Reducer<State, Actions> = (
  state: State = INITIAL_STATE,
  action: Actions
): State => {
  switch (action.type) {
    case actions.SET_HASH:
      return {
        ...state,
        hash: action.payload,
      };
    case 'LOGOUT':
      return {
        ...INITIAL_STATE,
        hash: state.hash,
      };
    default:
      return state;
  }
};
