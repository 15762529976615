import { Reducer } from '@reduxjs/toolkit';
import { AuthUser } from '../../types/firestore';
import { State, Actions, ActionTypes as actions } from './types';

const INITIAL_STATE: State = {
  users: [],
  usersLoading: false,
  usersGroups: {},
};

export const adminReducer: Reducer<State, Actions> = (
  state: State = INITIAL_STATE,
  action: Actions
): State => {
  switch (action.type) {
    case actions.SET_ADMIN_USERS:
      return {
        ...state,
        users: action.payload,
      };
    case actions.ADD_ADMIN_USERS:
      return {
        ...state,
        users: [...state.users, ...action.payload],
      };
    case actions.UPDATE_ADMIN_USER: {
      const targetIndex = state.users.findIndex(
        (user: AuthUser) => user.uid === action.payload.uid
      );

      const newUsers = [...state.users];
      if (targetIndex >= 0) {
        newUsers[targetIndex] = action.payload;
      }

      return {
        ...state,
        users: newUsers,
      };
    }
    case actions.SET_ADMIN_USERS_LOADING:
      return {
        ...state,
        usersLoading: action.payload,
      };
    case actions.SET_ADMIN_USER_GROUPS:
      return {
        ...state,
        usersGroups: { ...state.usersGroups, ...action.payload },
      };
    case actions.REMOVE_ADMIN_USER: {
      const indexToRemove = state.users.findIndex(
        (u) => u.uid === action.payload.uid
      );

      const newUsers = [...state.users];
      if (indexToRemove >= 0) {
        newUsers.splice(indexToRemove, 1);
      }

      return {
        ...state,
        users: newUsers,
      };
    }
    case 'LOGOUT':
      return INITIAL_STATE;
    default:
      return state;
  }
};
