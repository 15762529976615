import React, { useEffect, useReducer } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import validator from 'validator';
import { isValid } from 'date-fns';
import { format, utcToZonedTime } from 'date-fns-tz';
import { RootState } from '../../../store';
import { reducer, CalendarAction, INITIAL_STATE } from '../../Calendar/Form/state';

import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import { ChannelToLabel } from './SelectChannel';
import Stepper from './Stepper';
import { Booking } from '../../../types/local';
import { FormatDate, FormatTime, TZ } from '../../../utils/date';
import { addBookingRequest } from '../../../utils/api';
import { getIdToken } from '../../../utils/firebase';

import useStyles from './styles';
import LoadingOverlay from '../../../components/LoadingOverlay';
import { isMobilePhone, showNotification } from '../../../utils/common';
import { setHash } from '../../../store/calendar/actions';

export interface Step {
  label: string;
  isValidStep: boolean;
}

interface ICalendarContext {
  anonymous?: boolean;
}

export const CalendarContext = React.createContext<ICalendarContext>({});

/**
 * Meeting Time that is sent to the backend service.
 * Expects time as string eg. "2021-04-16T10:00:00.000Z"
 */
export const formatMeetingTime = (time: string) =>
  format(utcToZonedTime(time, TZ), 'yyyy-MM-dd HH:mm', {
    timeZone: TZ,
  });

const isValidStep1 = (state: Booking) => 
  state.view === 1 ? (
    state.date !== undefined &&
    (state.time !== undefined && state.time !== '') &&
    isValid(state.date)
  ) : (
    state.advisorId !== undefined
  );
const isValidStep2 = (state: Booking) =>
  state.view === 1 ? (
    state.advisorId !== undefined &&
    (state.time !== undefined && state.time !== '') &&
    isValid(Date.parse(state.time)) &&
    isValid(utcToZonedTime(state.time, TZ))
  ) : (
    state.advisorId !== undefined &&
    state.date !== undefined &&
    (state.time !== undefined && state.time !== '') &&
    isValid(state.date)
  );
const isValidFormToSubmit = (state: Booking) => {
  if (state.personal.phone.length === 0) {
    return (
      isValidStep1(state) &&
      isValidStep2(state) &&
      !validator.isEmpty(state.personal.firstnames) &&
      !validator.isEmpty(state.personal.surname) &&
      validator.isEmail(state.personal.email)
    );
  } else {
    return (
      isValidStep1(state) &&
      isValidStep2(state) &&
      !validator.isEmpty(state.personal.firstnames) &&
      !validator.isEmpty(state.personal.surname) &&
      validator.isEmail(state.personal.email) &&
      isMobilePhone(state.personal.phone)
    );
  }
};

/**
 * Labels change based on active step
 */
const getSteps = (state: Booking, activeStep: number): Step[] => {
  return [
    {
      label: '',
      isValidStep: isValidStep1(state),
    },
    {
      label: '',
      isValidStep: isValidStep2(state),
    },
    {
      label: '',
      isValidStep: activeStep === 3 ? true : isValidFormToSubmit(state),
    },
  ];
};

/**
 * List of step content
 */
const getStepsContent = (
  state: Booking,
  dispatch: React.Dispatch<CalendarAction>
): React.ReactElement[] => {
  const step1 = <Step1 state={state} dispatch={dispatch} />;
  const step2 = <Step2 state={state} dispatch={dispatch} />;
  const step3 = <Step3 state={state} dispatch={dispatch} />;
  const step4 = <Step4 state={state} dispatch={dispatch} />;

  return [step1, step2, step3, step4];
};

export interface FormProps {
  anonymous?: boolean;
}

const Form = (props: FormProps): React.ReactElement => {
  const classes = useStyles(props);
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const globalDispatch = useDispatch();
  const [activeStep, setActiveStep] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const user = useSelector((state: RootState) => state.user.fsUser);
  const hash = useSelector((state: RootState) => state.calendar.hash);
  const allAdvisors = useSelector((state: RootState) => state.content.advisors);
  const advisor = allAdvisors
    ? allAdvisors.find((v) => v.NeuvonantajaID === state.advisorId)
    : null;

  useEffect(() => {
    // Set office and advisor on activeStep change if step is 0,
    // this way the those are set also when coming back from confirmation page.
    if (activeStep === 0) {
      dispatch({
        type: 'set-office',
        payload: state.officeId || user?.toimistoID,
      });
      dispatch({ type: 'set-advisor', payload: user?.neuvonantajaID });
    }
  }, [user, activeStep]);

  useEffect(() => {
    if (advisor !== null && advisor !== undefined) {
      const channels = {
        Kanava_Puhelin: advisor.Kanava_Puhelin,
        Kanava_Toimisto: advisor.Kanava_Toimisto,
        Kanava_Video: advisor.Kanava_Video
      }
      dispatch({ type: 'set-available-channels', payload: channels});
      dispatch({ type: 'set-advisor-phonenumber', payload: advisor.Puhelinnumero});
    }
  }, [advisor]);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const idToken = await getIdToken();
      if (state.advisorId) {
        const result = await addBookingRequest(idToken, {
          advisorId: state.advisorId,
          officeId: state.officeId || advisor?.ToimistoID || user?.toimistoID || -1,
          firstnames: state.personal.firstnames,
          surname: state.personal.surname,
          phone: state.personal.phone,
          email: state.personal.email,
          channel: state.channel,
          meetingDuration: state.meetingDuration,
          meetingTime: formatMeetingTime(state.time),
          messageForAdvisor: state.personal.messageForAdvisor,
          hash,
        }).then((v) => v.json());
        if ('ok' !== result.result) {
          throw new Error('Koeta toista kellonaikaa tai päivää');
        }
        setActiveStep(3);

        // Clear hash after it is sent
        globalDispatch(setHash(undefined));
      }
      setLoading(false);
      
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.error('Error while sending booking:', error);
      setLoading(false);
      showNotification(
        'Ajanvarauksen lisääminen epäonnistui: ' + error.message,
        'error'
      );
    }
  };

  const context: ICalendarContext = {
    anonymous: props.anonymous || false,
  };

  return (
    <div className={classes['form-container']}>
      {loading && <LoadingOverlay />}
      <CalendarContext.Provider value={context}>
        <Stepper
          content={getStepsContent(state, dispatch)}
          steps={getSteps(state, activeStep)}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          handleSubmit={async () => await handleSubmit()}
          handleReset={() => dispatch({ type: 'reset' })}
        />
      </CalendarContext.Provider>
    </div>
  );
};

export default Form;
