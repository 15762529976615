import {
  Content,
  CorporateFinancingContent,
  CustomPagesContent,
  FrontpageContent,
  GlobalContent,
  InvestmentInfoType,
  OmasalkkuContent,
  ReviewContent,
} from '../../types/contentful';
import { Office } from '../../types/abc';
import { FSAdvisor } from '../../types/firestore';
import { Fund, StructuredInvestment, Page } from '../../types/contentful';

export enum ActionTypes {
  SET_CONTENT = 'SET_CONTENT',
  SET_OFFICES = 'SET_OFFICES',
  SET_ADVISORS = 'SET_ADVISORS',
  SET_INVESTMENT_INFO_DATA = 'SET_INVESTMENT_INFO_DATA',
  SET_PAGE_INFO = 'SET_PAGE_INFO',
  ADD_PAGE_INFO = 'ADD_PAGE_INFO',
  SET_GLOBAL_CONTENT = 'SET_GLOBAL_CONTENT',
  SET_FRONTPAGE_CONTENT = 'SET_FRONTPAGE_CONTENT',
  SET_REVIEW_CONTENT = 'SET_REVIEW_CONTENT',
  SET_CORPORATE_FINANCING_CONTENT = 'SET_CORPORATE_FINANCING_CONTENT',
  SET_CUSTOM_PAGES_CONTENT = 'SET_CUSTOM_PAGES_CONTENT',
}

export interface State {
  content: Content[];
  offices: Office[];
  advisors: FSAdvisor[];
  investmentInfoData:
    | ((Fund | StructuredInvestment) & {
        type: InvestmentInfoType;
      })
    | null;
  pageInfo: Page[];
  globalContent?: GlobalContent;
  frontpageContent?: FrontpageContent;
  reviewContent?: ReviewContent;
  corporateFinancingContent?: CorporateFinancingContent;
  customPagesContent?: CustomPagesContent;
}

interface SetContent {
  type: typeof ActionTypes.SET_CONTENT;
  payload: State['content'];
}

interface SetOffices {
  type: typeof ActionTypes.SET_OFFICES;
  payload: State['offices'];
}

interface SetAdvisors {
  type: typeof ActionTypes.SET_ADVISORS;
  payload: State['advisors'];
}

interface SetInvestmentInfoData {
  type: typeof ActionTypes.SET_INVESTMENT_INFO_DATA;
  payload: State['investmentInfoData'];
}

interface SetPageInfo {
  type: typeof ActionTypes.SET_PAGE_INFO;
  payload: State['pageInfo'];
}

interface AddPageInfo {
  type: typeof ActionTypes.ADD_PAGE_INFO;
  payload: Page;
}

interface SetGlobalContent {
  type: typeof ActionTypes.SET_GLOBAL_CONTENT;
  payload: State['globalContent'];
}

interface SetFrontpageContent {
  type: typeof ActionTypes.SET_FRONTPAGE_CONTENT;
  payload: State['frontpageContent'];
}

interface SetReviewContent {
  type: typeof ActionTypes.SET_REVIEW_CONTENT;
  payload: State['reviewContent'];
}

interface SetCorporateFinancingContent {
  type: typeof ActionTypes.SET_CORPORATE_FINANCING_CONTENT;
  payload: State['corporateFinancingContent'];
}

interface SetCustomPagesContent {
  type: typeof ActionTypes.SET_CUSTOM_PAGES_CONTENT;
  payload: State['customPagesContent'];
}

export type Actions =
  | SetContent
  | SetOffices
  | SetAdvisors
  | SetInvestmentInfoData
  | SetPageInfo
  | AddPageInfo
  | SetGlobalContent
  | SetFrontpageContent
  | SetReviewContent
  | SetCorporateFinancingContent
  | SetCustomPagesContent;
