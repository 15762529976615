import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import { useMediaQuery, useTheme } from '@material-ui/core';

import useStyles from './styles';

interface AuthorProps {
  name: string;
  photo: string;
  additionalInfo?: React.ReactElement | string;
}

export const Author = (props: AuthorProps): React.ReactElement => {
  const classes = useStyles(props);
  const { name, photo, additionalInfo } = props;
  // The placeholder can be hardcoded because it shouldn't be needed for existing advisors
  const image =
    photo ||
    'https://images.ctfassets.net/3gh7jphuo2o7/6IVxqVPD4NABPLNJalY7D6/1d85f5e61b8d7ed63ea6a802138cfd04/imageplaceholder.jpg';
  const theme = useTheme();
  const isXS = useMediaQuery(theme.breakpoints.down(400));
  const thumbnailSize = 65;
  return (
    <div className={classes.author}>
      <Avatar alt={name} className={classes.avatar}>
        <picture>
          <source
            srcSet={`${image}?fit=thumb&w=${thumbnailSize}&h=${thumbnailSize}&f=top&r=max&fm=webp`}
            type="image/webp"
          />
          <source
            srcSet={`${image}?fit=thumb&w=${thumbnailSize}&h=${thumbnailSize}&f=top&r=max&fm=jpg`}
            type="image/jpeg"
          />
          <img
            src={`${image}?fit=thumb&w=${thumbnailSize}&h=${thumbnailSize}&f=top&r=max&fm=jpg`}
            loading="lazy"
          />
        </picture>
      </Avatar>
      <div className={classes['author-info']}>
        <div className={classes['author-name']}>{name}</div>
        {!isXS && (
          <div className={classes['author-additional-info']}>
            {additionalInfo}
          </div>
        )}
      </div>
      {isXS && (
        <div className={classes['author-additional-info']}>
          {additionalInfo}
        </div>
      )}
    </div>
  );
};
