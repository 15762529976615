const getCspValue = (nonce: string) => {
  const authDomain =
    process.env.REACT_APP_FIREBASE_AUTHDOMAIN || 'https://*.firebaseapp.com';

  const defaultCsp = [
    'default-src',
    "'self'",
    '*.sentry.io',
    'https://apis.google.com',
    'https://stats.g.doubleclick.net',
    'https://*.google-analytics.com',
    'https://*.googleapis.com',
    'https://*.contentful.com',
    'https://*.gstatic.com',
    'https://player.vimeo.com/',
    'https://consentcdn.cookiebot.com/',
    'https://consentcdn.cookiebot.com/*',
    'https://www.google.com/pagead/*',
    'https://google.com/pagead/*',
    authDomain,
    "'unsafe-eval'", // This is bad, but needed because of @react-google-maps/api
  ];

  const scriptCsp = [
    'script-src',
    "'self'",
    nonce ? "'nonce-" + nonce + "'" : "'nonce-'",
    "'strict-dynamic'",
    'googletagmanager.com',
    'https://apis.google.com',
    'https://*.google-analytics.com',
    'https://*.googleapis.com',
    'https://*.contentful.com',
    'https://*.gstatic.com',
    authDomain,
    "'unsafe-eval'", // This is bad, but needed because of @react-google-maps/api
  ];

  const styleCsp = [
    'style-src',
    "'self'",
    "'unsafe-inline'",
    'https://*.googleapis.com',
  ];

  const imageCsp = [
    'img-src',
    "'self'",
    'data:',
    'https://images.ctfassets.net',
    'http://images.ctfassets.net',
    'https://*.gstatic.com',
    'https://*.googleapis.com',
    'http://images.contentful.com',
    'https://www.google-analytics.com',
    'https://www.googletagmanager.com',
    'https://www.google.com',
    'https://www.google.fi',
  ];

  if (process.env.NODE_ENV === 'development') {
    defaultCsp.push('localhost:3000');
  }

  return [
    ...defaultCsp,
    ';',
    ...scriptCsp,
    ';',
    ...styleCsp,
    ';',
    ...imageCsp,
  ].join(' ');
};

export default getCspValue;
