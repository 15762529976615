import React from 'react';
import {
  documentToReactComponents,
  Options,
} from '@contentful/rich-text-react-renderer';
import {
  BLOCKS,
  Block,
  Inline,
  INLINES,
  Document,
} from '@contentful/rich-text-types';
import { Link, Typography } from '@material-ui/core';
import { getLinkOverrideStyles } from './utils';

import { Links } from '../../types/contentful';

/** Article formatting options */
const options = (linkData?: Links, noStyles?: boolean): Options => ({
  renderNode: {
    ['iframe']: (node: any, children: React.ReactNode) => {
      if (!node?.data?.iframe) {
        return null;
      }
      return <div dangerouslySetInnerHTML={{ __html: node.data.iframe }} />;
    },
    //eslint-disable-next-line react/display-name
    [BLOCKS.PARAGRAPH]: (node: Block | Inline, children: React.ReactNode) => {
      return noStyles ? (
        <p>{children}</p>
      ) : (
        <Typography variant="body1" style={{ margin: '0px 0px 1.45em' }}>
          {children}
        </Typography>
      );
    },
    //eslint-disable-next-line react/display-name
    [BLOCKS.EMBEDDED_ASSET]: (node: Block | Inline) => {
      // Only handle embedded images for now
      if (!node.data?.image) {
        return null;
      }

      const { url, alt } = node.data.image;
      return <img src={url} alt={alt} width="100%" />;
    },
    //eslint-disable-next-line react/display-name
    [BLOCKS.HEADING_3]: (node: Block | Inline, children: React.ReactNode) =>
      noStyles ? (
        <h3>{children}</h3>
      ) : (
        <Typography variant="h3" style={{ margin: '32px 0px' }}>
          {children}
        </Typography>
      ),
    // eslint-disable-next-line react/display-name
    [INLINES.ASSET_HYPERLINK]: (
      node: Block | Inline,
      children: React.ReactNode
    ) => {
      const targetId = node.data?.target?.sys?.id;
      const link = linkData?.assets?.hyperlink?.find(
        (x) => x.sys.id === targetId
      );
      if (!link) return null;

      return (
        <Link href={link?.url} target="_blank" underline="always">
          {children}
        </Link>
      );
    },
    [INLINES.HYPERLINK]: (node: Block | Inline) => {
      const href = node.data?.uri;
      const content = node.content?.[0];
      if (!href || content?.nodeType !== 'text') return null;

      const style = getLinkOverrideStyles(content.value);
      return (
        <Link href={href} target="_blank" underline="always" style={style}>
          {content.value}
        </Link>
      );
    },
  },
  renderText: (text: string) => {
    return text.split('\n').map((text, i) => [i > 0 && <br key={i} />, text]);
  },
});

interface RichTextProps {
  body?: Document;
  links?: Links;
  noStyles?: boolean;
}

const RichText = ({
  body,
  links,
  noStyles,
}: RichTextProps): React.ReactElement | null => {
  return body ? (
    <>{documentToReactComponents(body, options(links, noStyles))}</>
  ) : null;
};

export default RichText;
