import { useEffect } from 'react';
import firebase from 'firebase/app';
import { useLogger } from './logger';

/**
 * Logs the current user out after given time of inactivity
 * @param time The time of inactivity to kick the user out in ms
 */
const useInactivityLogout = (time: number): void => {
  const logger = useLogger('Inactivity tracking');

  /** Logs out the current user if there is one */
  function tryToLogout() {
    if (firebase.auth().currentUser !== null) {
      logger.log('Signed out because of timeout');
      firebase.auth().signOut();
    }
  }

  useEffect(() => {
    let lastTimestamp = Date.now();
    let documentActive = true;

    /* 
      This interval runs every second and saves the current timestamp
      if the document is active.
      When the document is not active, or when this interval doesn't run
      for some other reason (computer sleep etc.), the timestamp is not
      updated.

      The saved timestamp is compared to the current timestamp, and if
      it differs more than the given `time`, the current user is logged out.
    */
    setInterval(() => {
      const currentTime = Date.now();
      const difference = currentTime - lastTimestamp;
      
      if (documentActive) {
        lastTimestamp = currentTime;
      }
      if (difference > time) {
        tryToLogout();
      }
    }, 1000);

    function onFocus() {
      if (document.visibilityState === 'visible') {
        logger.log('Removed logout timeout');
        documentActive = true;
      }
    }
    function onBlur() {
      logger.log(`Added ${time/1000}s timeout for logout`);
      documentActive = false;
    }
    window.addEventListener('focus', onFocus);
    window.addEventListener('blur', onBlur);

    return () => {
      window.removeEventListener('focus', onFocus);
      window.removeEventListener('blur', onBlur);
      logger.log('Removed inactivity tracking');
    };
  }, []);
};

export default useInactivityLogout;
