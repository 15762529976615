import React, { Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';

import MiddleLayout from '../layouts/Middle';

/**
 * Lazy load main routes (code splitting)
 */
const Main = React.lazy(() => import('../views/Main'));
const UserInfo = React.lazy(() => import('../views/UserInfo'));
const InvestmentsRoute = React.lazy(() => import('./investments'));
const MeetingsRoute = React.lazy(() => import('./meetings'));
const Reports = React.lazy(() => import('../views/Reports'));
const Admin = React.lazy(() => import('../views/Admin'));
const Calendar = React.lazy(() => import('../views/Calendar'));
const ContentRoute = React.lazy(() => import('./content'));
const ContactUs = React.lazy(() => import('../views/ContactUs'));
const BenefitProgram = React.lazy(() => import('../views/BenefitProgram'));

import GeneralRoute from './general';

/**
 *  Components
 */
import PrivateRoute from './privateRoute';
import NotFound from '../views/NotFound';
import Loading from '../views/Loading';
import { groups } from '../utils/common';

const MainRoute = (): React.ReactElement => {
  return (
    <MiddleLayout>
      <Suspense fallback={<Loading />}>
        <Switch>
          <Route exact path="/">
            <Main />
          </Route>
          <Route path="/sijoitukset">
            <InvestmentsRoute />
          </Route>
          <Route path="/varaa_aika">
            <Calendar />
          </Route>
          <Route path="/omat_tiedot">
            <UserInfo />
          </Route>
          <Route path="/raportit">
            <Reports />
          </Route>
          <Route path="/tapaamiset">
            <MeetingsRoute />
          </Route>
          <Route path="/sisallot">
            <ContentRoute />
          </Route>
          <Route path="/yleista">
            <GeneralRoute />
          </Route>
          <Route path="/ota_yhteytta">
            <ContactUs />
          </Route>
          <Route path="/etuohjelma">
            <BenefitProgram />
          </Route>
          <PrivateRoute
            path="/hallinta"
            roles={[groups.admin]}
            component={Admin}
          />
          <Route>
            <NotFound />
          </Route>
        </Switch>
      </Suspense>
    </MiddleLayout>
  );
};
export default MainRoute;
