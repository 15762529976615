import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
  },
  icon: {
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 1,
    },
  },
  checkedIcon: {},
}));

export default useStyles;
