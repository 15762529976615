import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { store } from './store';
import { App } from './App';
import ReactGA, { InitializeOptions } from 'react-ga';
import TagManager from 'react-gtm-module-nonce';
import { Helmet } from 'react-helmet';
import getCspValue from './utils/csp';
import { featureIsEnabled, FeatureName } from './utils/featureFlags';

const GA_TRACKING_ID = process.env.GA_TRACKING_ID || 'test';
const gaOptions: InitializeOptions = {
  debug: process.env.NODE_ENV !== 'production',
  testMode: GA_TRACKING_ID === 'test',
};
ReactGA.initialize(GA_TRACKING_ID, gaOptions);

const nonce = (Math.random() + 1).toString(36).substring(2);
const csp = getCspValue(nonce);

if (process.env.GTM_ID) {
  console.log('Initialize GTM');
  TagManager.initialize({
    gtmId: process.env.GTM_ID,
    nonce: nonce,
  });
}

featureIsEnabled(FeatureName.Sentry) &&
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    integrations: [new BrowserTracing()],
    environment: process.env.NODE_ENV,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: parseFloat(process.env.SENTRY_TRACES_SAMPLE_RATE || '0'),
  });

ReactDOM.render(
  <>
    <Helmet>
      <meta httpEquiv="Content-Security-Policy" content={csp} />
    </Helmet>
    <Provider store={store}>
      <PersistGate persistor={persistStore(store)}>
        <App />
      </PersistGate>
    </Provider>
  </>,
  document.getElementById('root')
);
