import { CSSProperties } from '@material-ui/core/styles/withStyles';

/**
 * Scto Grotesk A Imports
 */
import SctoGroteskARegularWoff2 from './SctoGroteskARegular.woff2';
import SctoGroteskARegularWoff from './SctoGroteskARegular.woff';
import SctoGroteskARegularItalicWoff2 from './SctoGroteskARegularItalic.woff2';
import SctoGroteskARegularItalicWoff from './SctoGroteskARegularItalic.woff';
import SctoGroteskAMediumWoff2 from './SctoGroteskAMedium.woff2';
import SctoGroteskAMediumWoff from './SctoGroteskAMedium.woff';
import SctoGroteskAMediumItalicWoff2 from './SctoGroteskAMediumItalic.woff2';
import SctoGroteskAMediumItalicWoff from './SctoGroteskAMediumItalic.woff';

/**
 * SaolText imports
 */
import SaolTextBookWoff2 from './SaolText-Book.woff2';
import SaolTextBookWoff from './SaolText-Book.woff';
import SaolTextBookItalicWoff2 from './SaolText-BookItalic.woff2';
import SaolTextBookItalicWoff from './SaolText-BookItalic.woff';
import SaolTextSemiboldWoff2 from './SaolText-Semibold.woff2';
import SaolTextSemiboldWoff from './SaolText-Semibold.woff';
import SaolTextSemiboldItalicWoff2 from './SaolText-SemiboldItalic.woff2';
import SaolTextSemiboldItalicWoff from './SaolText-SemiboldItalic.woff';

/**
 * SaolDisplay
 */
import SaolDisplaySemiboldWoff2 from './SaolDisplay-Semibold.woff2';
import SaolDisplaySemiboldWoff from './SaolDisplay-Semibold.woff';
import SaolDisplaySemiboldItalicWoff2 from './SaolDisplay-SemiboldItalic.woff2';
import SaolDisplaySemiboldItalicWoff from './SaolDisplay-SemiboldItalic.woff';

/**
 *  Scto Grotesk A font-face
 */
export const sctoRegular: CSSProperties['@font-face'] = {
  fontFamily: 'Scto Grotesk A',
  src: `
    local('Scto Grotesk A'),
    local('Scto Grotesk A Regular'),
    url(${SctoGroteskARegularWoff}) format('woff'),
    url(${SctoGroteskARegularWoff2}) format('woff2');
`,
  fontStyle: 'normal',
  fontWeight: 400,
  fontDisplay: 'swap',
};

export const sctoItalic: CSSProperties['@font-face'] = {
  fontFamily: 'Scto Grotesk A',
  src: `
    local('Scto Grotesk A'),
    local('Scto Grotesk A Regular Italic'),
    url(${SctoGroteskARegularItalicWoff}) format('woff'),
    url(${SctoGroteskARegularItalicWoff2}) format('woff2');
`,
  fontStyle: 'italic',
  fontWeight: 400,
  fontDisplay: 'swap',
};

export const sctoMedium: CSSProperties['@font-face'] = {
  fontFamily: 'Scto Grotesk A',
  src: `
    local('Scto Grotesk A'),
    local('Scto Grotesk A Medium'),
    url(${SctoGroteskAMediumWoff}) format('woff'),
    url(${SctoGroteskAMediumWoff2}) format('woff2');
`,
  fontStyle: 'normal',
  fontWeight: 500,
  fontDisplay: 'swap',
};

export const sctoMediumItalic: CSSProperties['@font-face'] = {
  fontFamily: 'Scto Grotesk A',
  src: `
    local('Scto Grotesk A'),
    local('Scto Grotesk A Medium Italic'),
    url(${SctoGroteskAMediumItalicWoff}) format('woff'),
    url(${SctoGroteskAMediumItalicWoff2}) format('woff2');
`,
  fontStyle: 'italic',
  fontWeight: 500,
  fontDisplay: 'swap',
};

/**
 * SaolText font-face
 */

export const saoltextBook: CSSProperties['@font-face'] = {
  fontFamily: 'Saol Text',
  src: `
  local('Saol Text'),
  local('Saol Text Book'),
    url(${SaolTextBookWoff}) format('woff'),
    url(${SaolTextBookWoff2}) format('woff2');
`,
  fontStyle: 'normal',
  fontWeight: 400,
  fontDisplay: 'swap',
};

export const saoltextBookItalic: CSSProperties['@font-face'] = {
  fontFamily: 'Saol Text',
  src: `
    local('Saol Text'),
    local('Saol Text Book Italic'),
    url(${SaolTextBookItalicWoff}) format('woff'),
    url(${SaolTextBookItalicWoff2}) format('woff2');
`,
  fontStyle: 'italic',
  fontWeight: 400,
  fontDisplay: 'swap',
};

export const saoltextSemibold: CSSProperties['@font-face'] = {
  fontFamily: 'Saol Text',
  src: `
    local('Saol Text'),
    local('Saol Text Semibold'),
    url(${SaolTextSemiboldWoff}) format('woff'),
    url(${SaolTextSemiboldWoff2}) format('woff2');
`,
  fontStyle: 'normal',
  fontWeight: 600,
  fontDisplay: 'swap',
};

export const saoltextSemiboldItalic: CSSProperties['@font-face'] = {
  fontFamily: 'Saol Text',
  src: `
    local('Saol Text'),
    local('Saol Text Semibold Italic'),
    url(${SaolTextSemiboldItalicWoff}) format('woff'),
    url(${SaolTextSemiboldItalicWoff2}) format('woff2');
`,
  fontStyle: 'italic',
  fontWeight: 600,
  fontDisplay: 'swap',
};

/**
 * SaolDisplay font-face
 *
 */

export const saolDisplaySemibold: CSSProperties['@font-face'] = {
  fontFamily: 'Saol Display',
  src: `
    local('Saol Display'),
    local('Saol Text Semibold'),
    url(${SaolDisplaySemiboldWoff}) format('woff'),
    url(${SaolDisplaySemiboldWoff2}) format('woff2');
`,
  fontStyle: 'normal',
  // fontWeight: 600,
  fontDisplay: 'swap',
};

export const saolDisplaySemiboldItalic: CSSProperties['@font-face'] = {
  fontFamily: 'Saol Display',
  src: `
    local('Saol Display'),
    local('Saol Text Semibold Italic'),
    url(${SaolDisplaySemiboldItalicWoff}) format('woff'),
    url(${SaolDisplaySemiboldItalicWoff2}) format('woff2');
`,
  fontStyle: 'italic',
  // fontWeight: 600,
  fontDisplay: 'swap',
};

export default [
  sctoRegular,
  sctoItalic,
  sctoMedium,
  sctoMediumItalic,
  saolDisplaySemibold,
  saolDisplaySemiboldItalic,
  saoltextBook,
  saoltextBookItalic,
  saoltextSemibold,
  saoltextSemiboldItalic,
];
