import React, { forwardRef } from 'react';
import Input, { InputProps } from '@material-ui/core/Input';

import useStyles from './styles';

const TextField = forwardRef((props: InputProps, ref): React.ReactElement => {
  const { root, input, focused, error } = useStyles(props);
  return (
    <>
      <Input
        disableUnderline={true}
        classes={{
          focused,
          root,
          error,
          input
        }}
        {...props}
        ref={ref} 
      />
    </>
  );
});

TextField.displayName = 'TextField';

export default TextField;