import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  'mui-drawer-paper': {
    overflowY: 'hidden',
  },
  root: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    overflow: 'hidden',
    width: '100%',
    height: '100vh',
    position: 'relative',
    backgroundColor: theme.palette.white,
    '&.nav-open': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  pages: {
    marginTop: '85px',
    marginLeft: '152px',
    backgroundColor: theme.palette.white,
    width: '100%',
    maxWidth: '1620px',
  },
  activity: {
    zIndex: 9,
    position: 'relative',
    display: 'flex',
    flexBasis: '0px',
    '&.open': {
      flexBasis: '472px',
      marginRight: '0px',
    },
  },
  'activity-feed': {
    position: 'absolute',
    right: 0,
    height: '100%',
    marginTop: '85px',
    '&.nav-open': {},
  },
}));

export default useStyles;
