import React from 'react';
import { Fade, Backdrop } from '@material-ui/core';

import MuiModal from '@material-ui/core/Modal';

const fadeTime = 500;
const Modal = (props: {
  open: boolean;
  onClose: () => void;
  children: React.ReactElement;
}) => {
  const { open, onClose, children } = props;
  return (
    <MuiModal
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: fadeTime,
      }}
    >
      <Fade in={open}>{children}</Fade>
    </MuiModal>
  );
};

export default Modal;
