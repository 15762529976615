import React, { forwardRef } from 'react';
import Input, { InputProps } from '@material-ui/core/Input';

import useStyles from './styles';

const TextArea = forwardRef(
  (props: InputProps, ref): React.ReactElement => {
    const { root, input, focused, error } = useStyles(props);
    return (
      <Input
        multiline={true}
        rows={8}
        disableUnderline={true}
        classes={{
          focused,
          root,
          error,
          input,
        }}
        {...props}
        ref={ref}
      />
    );
  }
);

TextArea.displayName = 'TextArea';

export default TextArea;
