import { Reducer } from '@reduxjs/toolkit';
import { State, Actions, ActionTypes as actions } from './types';

const INITIAL_STATE: State = {
  content: [],
  offices: [],
  advisors: [],
  investmentInfoData: null,
  pageInfo: [],
};

export const contentReducer: Reducer<State, Actions> = (
  state: State = INITIAL_STATE,
  action: Actions
): State => {
  switch (action.type) {
    case actions.SET_CONTENT:
      return {
        ...state,
        content: action.payload,
      };
    case actions.SET_OFFICES:
      return {
        ...state,
        offices: action.payload,
      };
    case actions.SET_ADVISORS:
      return {
        ...state,
        advisors: action.payload,
      };
    case actions.SET_INVESTMENT_INFO_DATA: {
      return {
        ...state,
        investmentInfoData: action.payload,
      };
    }
    case actions.ADD_PAGE_INFO: {
      const oldIds = state.pageInfo.map((v) => v.sys.id);
      const newPageInfo = oldIds.includes(action.payload.sys.id)
        ? [...state.pageInfo]
        : [...state.pageInfo, action.payload];
      return {
        ...state,
        pageInfo: newPageInfo,
      };
    }
    case actions.SET_PAGE_INFO: {
      return {
        ...state,
        pageInfo: action.payload,
      };
    }
    case actions.SET_GLOBAL_CONTENT: {
      return {
        ...state,
        globalContent: action.payload,
      };
    }
    case actions.SET_FRONTPAGE_CONTENT: {
      return {
        ...state,
        frontpageContent: action.payload,
      };
    }
    case actions.SET_REVIEW_CONTENT: {
      return {
        ...state,
        reviewContent: action.payload,
      };
    }
    case actions.SET_CORPORATE_FINANCING_CONTENT: {
      return {
        ...state,
        corporateFinancingContent: action.payload,
      };
    }
    case actions.SET_CUSTOM_PAGES_CONTENT: {
      return {
        ...state,
        customPagesContent: action.payload,
      };
    }
    default:
      return state;
  }
};
