import firebase from 'firebase/app';
import { isToday } from 'date-fns';
import { DataSource } from '../dataSource';
import { store } from '../../store';
import {
  setFundsPosition,
  setFundsTransaction,
  addSecurityDetails,
  addFundsSecurityInfo,
} from '../../store/investments/funds/actions';
import { UpdateType } from '../../types/api';
import { FSPortfolioPosition } from '../../types/firestore';
import { Transaction } from '../../types/difs';
import { DataSubscription } from '../dataSubscription';
import { Timestamp } from '../../types/local';
import { fetchMorningStarData } from '../../utils/api';
import { getFundsInfoAll } from '../../utils/contentful';
import { SecurityDetail } from '../../types/morningstar';
import { Fund } from '../../types/contentful';
import { utcToZonedTime } from 'date-fns-tz';
import { TZ } from '../../utils/date';

export const getMorningStarData = async (
  idToken: string,
  ISINs: string[]
): Promise<SecurityDetail[][]> => {
  return Promise.all(
    ISINs.map(async (i) => {
      const response = await fetchMorningStarData(idToken, i);
      return response.json();
    })
  );
};

export const getFundsContentfulData = async (
  isins: string[]
): Promise<Fund[]> => {
  return await getFundsInfoAll(isins);
};

export class FundsDataSource extends DataSource {
  name: UpdateType = 'funds-positions';
  subscribe(
    uid: string,
    ownUid: string,
    db: firebase.firestore.Firestore
  ): DataSubscription {
    const overviewsRef = db
      .collection('funds')
      .doc(uid)
      .collection('positions')
      .doc(utcToZonedTime(Date.now(), TZ).toISOString().split('T')[0]);

    const firestoreUnsubscribe = overviewsRef.onSnapshot(
      async (snapshot) => {
        const data = snapshot.data() as FSPortfolioPosition | undefined;
        const idToken = await this.getIdToken();

        if (data) {
          store.dispatch(setFundsPosition(data.positions));
        } else if (idToken) {
          this.requestUpdate(
            { target: 'funds-positions', parameters: null, uid },
            idToken
          );
        }

        if (data?.positions.length && idToken) {
          /** get additional data related to the funds */
          const isins = data.positions[0].Securities.SecuritySumPosition.map(
            (p) => p.ISIN
          );
          getMorningStarData(idToken, isins).then((v: SecurityDetail[][]) => {
            const securities = v.map((s) => s[0]);
            store.dispatch(addSecurityDetails(securities));
          });

          getFundsContentfulData(isins).then((v) =>
            store.dispatch(addFundsSecurityInfo(v))
          );
        }
      },
      (error) => {
        console.error(error);
      }
    );

    const unsubscribe = () => {
      firestoreUnsubscribe();
    };

    return new DataSubscription(this.name, unsubscribe);
  }
}

export class FundsTransactionDataSource extends DataSource {
  name: UpdateType = 'funds-transactions';
  subscribe(
    uid: string,
    ownUid: string,
    db: firebase.firestore.Firestore
  ): DataSubscription {
    const overviewsRef = db
      .collection('funds')
      .doc(uid)
      .collection('transactions');

    const firestoreUnsubscribe = overviewsRef.onSnapshot(
      async (snapshot) => {
        const transactions = snapshot.docs.map(
          (doc) => doc.data() as Transaction
        );
        const idToken = await this.getIdToken();

        const lastUpdate = transactions.reduce((prev, curr) => {
          if (prev < curr.TransactionDate) {
            return curr.TransactionDate;
          }
          return prev;
        }, firebase.firestore.Timestamp.fromDate(new Date(2010, 1)) as Timestamp);

        const dataUpdates = store.getState().dataUpdates['funds-transactions'];

        // If the data has not been updated today and there isn't an update ongoing, ask for an update
        if (
          !isToday(dataUpdates?.t || 0) &&
          !dataUpdates?.updating &&
          idToken
        ) {
          this.requestUpdate(
            {
              target: 'funds-transactions',
              parameters: {
                BeginDate: (lastUpdate as firebase.firestore.Timestamp)
                  .toDate()
                  .toISOString(),
                TransactionTypes: ['11', '12'],
              },
              uid,
            },
            idToken
          );
        }

        store.dispatch(setFundsTransaction(transactions));
      },
      (error) => {
        console.error(error);
      }
    );

    const unsubscribe = () => {
      firestoreUnsubscribe();
    };
    return new DataSubscription(this.name, unsubscribe);
  }
}
