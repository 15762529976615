
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  fieldTitle: {
    fontFamily: 'Saol Display',
    fontWeight: 600,
    fontSize: '22px',
    margin: '10px 0 10px 0',
  },
  timesContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    width: '100%',
  },
  times: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(75px, 0.5fr))',
    width: '100%',
    columnGap: '5px',
  },
  timeButton: {
    background: 'rgba(122, 184, 255, 0.4)',
    borderRadius: '30px',
    border: 'none',
    height: '37px',
    flexGrow: 1,
    marginBottom: '5px',
    '&.active': {
      background: theme.palette.lightBlue.main,
    },
    '&:hover': {
      background: 'rgba(122, 184, 255, 0.6)',
    },
  },
  time: {
    cursor: 'pointer',
    background: theme.palette.grey['100'],
    borderRadius: '23px',
    padding: '8px 16px',
    margin: '8px 16px 0px 0px',
    border: 'none',
    color: theme.palette.primary.main,
    '&.selected': {
      background: theme.palette.secondary.light,
    },
  },
  line: {
    flexGrow: 1,
    border: '0.5px solid' + theme.palette.primary.main,
  },
  check: {
    position: 'absolute',
    display: 'flex',
    top: '-6px',
    right: '-5px',
    width: '24px',
    height: '24px',
    borderRadius: '50%',
    background: theme.palette.primary.main,
    justifyContent: 'center',
    alignItems: 'center',
  },
  checkIcon: {
    fill: 'none',
    stroke: theme.palette.white,
    strokeWidth: '1px',
    width: '10.35px',
    height: '7.2px',
  },
}));

export default useStyles;
