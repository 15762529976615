import React from 'react';
import {
  useMediaQuery,
  useTheme,
  Button,
  Typography,
  Link,
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

//import Button from '../../../components/Button';
import { ReactComponent as AlexandriaLogo } from '../../../images/svg/alexandria_pun.svg';
import { ReactComponent as AlexandriaLogo156 } from '../../../images/svg/alexandria_pun_156.svg';

import useStyles from './styles';

const linkStyles = {
  display: 'flex',
  alignItems: 'center',
};

const AlexandriaLink = (props: {
  children: React.ReactNode;
}): React.ReactElement => (
  <a href="https://alexandria.fi" style={linkStyles}>
    {props.children}
  </a>
);

const Header = (): React.ReactElement => {
  const classes = useStyles();
  const theme = useTheme();
  const matchesXS = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.xs)
  );

  return (
    <div className={classes.header}>
      {matchesXS ? (
        <AlexandriaLink>
          <AlexandriaLogo156 />
        </AlexandriaLink>
      ) : (
        <AlexandriaLink>
          <AlexandriaLogo />
        </AlexandriaLink>
      )}
      <div className={classes.buttons}>
        <Button
          component={RouterLink}
          className={classes.button}
          to="/yhteydenotto"
        >
          Ota yhteyttä
        </Button>
      </div>
    </div>
  );
};

export default Header;
