import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  'register-container': {
    //width: '322px',
    color: theme.palette.secondary.main,
    height: '172px',
    '& .question': {
      marginBottom: '22px',
    },
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      width: '100%',
      marginTop: '76px',
      textAlign: 'center',
    },
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      marginTop: '32px',
    },
  },
  button: {
    color: theme.palette.secondary.main,
    '&.register': {
      textTransform: 'none',
      marginTop: '32px',
      border: `solid 1px ${theme.palette.secondary.main}`,
      [theme.breakpoints.down(theme.breakpoints.values.lg)]: {
        width: '224px',
      },
      [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
        width: '320px',
      },
      [theme.breakpoints.down(400)]: {
        width: '90%',
      },
    },
  },
}));

export default useStyles;
