import { Reducer } from '@reduxjs/toolkit';
import { State, Actions, ActionTypes as actions } from './types';

const INITIAL_STATE: State = {};

export const dataUpdatesReducer: Reducer<State, Actions> = (
  state: State = INITIAL_STATE,
  action: Actions
): State => {
  switch (action.type) {
    case actions.SET_DATA_UPDATES:
      return action.payload;
    default:
      return state;
  }
};
