import { ActionTypes, Actions, State } from './types';

export function setUser(user: State['user']): Actions {
  return {
    type: ActionTypes.SET_USER,
    payload: user,
  };
}

export function setFSUser(user: State['fsUser']): Actions {
  return {
    type: ActionTypes.SET_FS_USER,
    payload: user,
  };
}

export function setAccessToken(accessToken: State['accessToken']): Actions {
  return {
    type: ActionTypes.SET_ACCESS_TOKEN,
    payload: accessToken,
  };
}

export function setControllableUsers(
  users: State['controllableUsers']
): Actions {
  return {
    type: ActionTypes.SET_CONTROLLABLE_USERS,
    payload: users,
  };
}

export function setUsersAdvisor(advisor: State['advisor']): Actions {
  return {
    type: ActionTypes.SET_USERS_ADVISOR,
    payload: advisor,
  };
}

export function clearUser(): Actions {
  return {
    type: ActionTypes.CLEAR_USER,
  };
}
