import React from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import List from '@material-ui/core/List';
import Collapse from '@material-ui/core/Collapse';
import { AppDispatch, RootState } from '../../../store';
import { addOpenMenu, removeOpenMenu } from '../../../store/ui/actions';
import NavItem from '../../NavItem';
import MenuIcon from '../MenuIcon';
import { NavSpec } from '../../../types/local';
import useStyles from '../styles';

const openMenu = (menu: string, dispatch: AppDispatch) => {
  dispatch(addOpenMenu(menu));
};

const closeMenu = (menu: string, dispatch: AppDispatch) => {
  dispatch(removeOpenMenu(menu));
};

const menuFactory = (props: {
  navItems: NavSpec[];
  isChild?: boolean;
  parentKey?: string;
  parentTo?: string;
}): React.ReactElement[] => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { navItems, parentKey, parentTo } = props;

  const selectedNav = useSelector((state: RootState) => state.ui.selectedTab);
  const openMenus = useSelector((state: RootState) => state.ui.openMenus);

  const menu = navItems.map((item, index) => {
    const to = `${parentTo ? parentTo + item.to : item.to}`;
    const itemKey = `${parentKey ? parentKey : 'nav-item'}${item.to.replace(
      '/',
      '-'
    )}`;
    const childMenu =
      item.children && item.children.length > 0
        ? menuFactory({
            navItems: item.children,
            isChild: true,
            parentKey: itemKey,
            parentTo: to,
          })
        : undefined;

    const selectedInPath =
      selectedNav.includes(itemKey) && childMenu !== undefined;
    const isSelected = selectedNav === itemKey;
    const isOpen = openMenus.indexOf(itemKey) > -1;
    return (
      <React.Fragment key={itemKey + index}>
        <NavItem
          label={item.label}
          to={to}
          onClick={() => {
            if (childMenu && !isOpen) {
              openMenu(itemKey, dispatch);
            }
          }}
          onIconClick={() => {
            if (childMenu) {
              isOpen
                ? closeMenu(itemKey, dispatch)
                : openMenu(itemKey, dispatch);
            }
          }}
          selectedInPath={selectedInPath}
          selected={isSelected}
          drawer={true}
          open={isOpen}
          icon={
            childMenu ? (
              <MenuIcon open={isOpen} selected={isSelected} />
            ) : undefined
          }
        />
        {childMenu && (
          <Collapse in={isOpen} timeout="auto" unmountOnExit>
            <List
              className={clsx(
                classes['child-menu'],
                'drawer',
                selectedInPath && classes['selected-path']
              )}
            >
              {childMenu}
            </List>
          </Collapse>
        )}
      </React.Fragment>
    );
  });

  return menu;
};

export default menuFactory;
