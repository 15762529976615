import { Reducer } from '@reduxjs/toolkit';
import {
  CorporateFinancingState as State,
  Actions,
  ActionTypes as actions,
} from './types';

const INITIAL_STATE: State = {
  investments: null,
  balance: null,
  loanStatuses: [],
  loanReports: [],
  account: null,
  cashBalance: null,
  depositInfo: null,
  scheduledWithdrawalAmount: null,
};

export const corporateFinancingReducer: Reducer<State, Actions> = (
  state: State = INITIAL_STATE,
  action: Actions
): State => {
  switch (action.type) {
    case actions.SET_INVESTMENTS:
      return {
        ...state,
        investments: action.payload,
      };
    case actions.SET_BALANCE:
      return {
        ...state,
        balance: action.payload,
      };
    case actions.SET_LOAN_STATUSES:
      return {
        ...state,
        loanStatuses: action.payload,
      };
    case actions.ADD_LOAN_STATUS: {
      const oldIds = state.loanStatuses.map((v) => v.loanId);
      const newStatuses = oldIds.includes(action.payload.loanId)
        ? state.loanStatuses
        : [...state.loanStatuses, action.payload];
      return {
        ...state,
        loanStatuses: newStatuses,
      };
    }
    case actions.SET_LOAN_REPORTS:
      return {
        ...state,
        loanReports: action.payload,
      };
    case actions.ADD_LOAN_REPORTS: {
      const oldIds = state.loanReports.map((v) => v.loanId);
      const newStatuses = oldIds.includes(action.payload[0].loanId)
        ? state.loanReports
        : [...state.loanReports, ...action.payload];
      return {
        ...state,
        loanReports: newStatuses,
      };
    }
    case actions.SET_BALANCE_TIMESTAMP:
      return {
        ...state,
        balanceTimestamp: action.payload,
      };
    case actions.SET_INVESTMENTS_TIMESTAMP:
      return {
        ...state,
        investmentsTimestamp: action.payload,
      };
    case actions.SET_ACCOUNT:
      return {
        ...state,
        account: action.payload,
      };
    case actions.SET_CASH_BALANCE:
      return {
        ...state,
        cashBalance: action.payload,
      };
    case actions.SET_DEPOSIT_INFO:
      return {
        ...state,
        depositInfo: action.payload,
      };
    case actions.SET_SCHEDULED_WITHDRAWAL_AMOUNT:
      return {
        ...state,
        scheduledWithdrawalAmount: action.payload,
      };
    case 'LOGOUT':
    case 'CHANGE_USER':
      return INITIAL_STATE;
    default:
      return state;
  }
};
