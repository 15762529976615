import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  'app-center': {
    backgroundColor: theme.palette.grey['100'],
    '&.login': {
      minHeight: '100%',
      backgroundColor: theme.palette.primary.main,
    },
    '&.loading': {
      backgroundColor: theme.palette.grey['100'],
    },
    [theme.breakpoints.up('xs')]: {
      display: 'block',
      height: '100%',
    },
    [theme.breakpoints.up('xl')]: {
      display: 'flex',
      justifyContent: 'center',
      flex: 1,
    },
  },
  'app-content-area': {
    maxWidth: '1920px',
    height: '100%',
    display: 'flex',
    flex: 1,
    alignItems: 'flex-start',
  },
  filler: {
    position: 'absolute',
    zIndex: -1,
    width: '100%',
    bottom: 0,
    background: theme.palette.primary.main,
  },
}));

export default useStyles;
