import React, { useReducer, useEffect } from 'react';
import clsx from 'clsx';
import validator from 'validator';

import { useSelector } from 'react-redux';
import { RootState } from '../../../store';

import Button from '../../../components/Button';
import TextField from '../../../components/TextField';
import TextArea from '../../../components/TextArea';

import { INITIAL_STATE, reducer } from './state';
import SelectContactPreference from './SelectContactPreference';
import SelectTo from './SelectRecipient';
import { ContactUs, ContactUsPersonal } from '../../../types/local';
import { isMobilePhone, showNotification } from '../../../utils/common';
import { contactUsRequest } from '../../../utils/api';
import { getIdToken } from '../../../utils/firebase';
import { ReactComponent as Forward } from '../../../images/svg/forward_blue.svg';

import useStyles from './styles';
import LoadingOverlay from '../../../components/LoadingOverlay';
import { Link } from 'react-router-dom';

const InfoToLabel = (info: keyof ContactUsPersonal) => {
  switch (info) {
    case 'firstnames':
      return 'Etunimi';
    case 'surname':
      return 'Sukunimi';
    case 'email':
      return 'Sähköposti';
    case 'phone':
      return 'Puhelinnumero';
    case 'message':
      return 'Viestisi';
    default:
      return '';
  }
};

const isValidForm = (state: ContactUs) =>
  !validator.isEmpty(state.personal.firstnames) &&
  !validator.isEmpty(state.personal.surname) &&
  validator.isEmail(state.personal.email) &&
  isMobilePhone(state.personal.phone)

const Form = (props: { anonymous?: boolean }): React.ReactElement => {
  const classes = useStyles();
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const [loading, setLoading] = React.useState(false);
  const fsUser = useSelector((state: RootState) => state.user.fsUser);
  const user = useSelector((state: RootState) => state.user.user);

  useEffect(() => {
    dispatch({
      type: 'set-personal',
      payload: {
        ...state.personal,
        firstnames: fsUser?.etunimet || state.personal.firstnames,
        surname: fsUser?.sukunimi || state.personal.surname,
        email: user?.email || state.personal.email,
      },
    });
  }, [user, fsUser]);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const idToken = await getIdToken();
      if (state.recipient && state.personal.email) {
        const result = await contactUsRequest(idToken, {
          recipient: state.recipient,
          contactPreference: state.contactPreference,
          firstnames: state.personal.firstnames,
          surname: state.personal.surname,
          phone: state.personal.phone,
          email: state.personal.email,
          message: state.personal.message,
        }).then((v) => v.json());

        if ('ok' !== result.result) {
          throw new Error('Koeta lähettää viesti uudelleen');
        }

        dispatch({ type: 'reset' });
        showNotification('Yhteydenottoviestisi on lähetetty');
      }
      setLoading(false);
    } catch (error: any) {
      console.error('Error while sending booking:', error);
      setLoading(false);
      showNotification(
        'Yhteydenottoviestin lähettäminen epäonnistui: ' + error.message,
        'error'
      );
    }
  };

  return (
    <div className={classes['container']}>
      {loading && <LoadingOverlay />}
      <div className={classes['form-container']}>
        <div className="left">
          {Object.keys(state.personal).map((v) => {
            const k = v as keyof ContactUsPersonal;
            return (
              k !== 'message' && (
                <div key={k} className={classes['form-item']}>
                  <TextField
                    className={clsx(classes.field, classes.white)}
                    placeholder={InfoToLabel(k)}
                    name={k}
                    value={state.personal[k]}
                    onChange={(event) =>
                      dispatch({
                        type: `set-personal`,
                        payload: { ...state.personal, [k]: event.target.value },
                      })
                    }
                  />
                </div>
              )
            );
          })}
        </div>
        <div className="right">
          <div className={classes['form-item']} style={{ height: '100%' }}>
            <TextArea
              key={'message'}
              className={classes.white}
              placeholder={InfoToLabel('message')}
              value={state.personal.message}
              onChange={(event) =>
                dispatch({
                  type: 'set-personal',
                  payload: { ...state.personal, message: event.target.value },
                })
              }
            />
          </div>
        </div>
      </div>
      <div className={classes['form-container']}>
        <div className="left">
          <SelectContactPreference
            value={state.contactPreference}
            dispatch={dispatch}
          />
        </div>
        <div>
          <SelectTo value={state.recipient} dispatch={dispatch} />
        </div>
      </div>
      <div className={classes['form-container']}>
        <div className="left">
          {props.anonymous ? (
            <>
              Täyttämällä lomakkeen annan Alexandrialle suostumukseni tietojeni
              käyttämiseen Alexandrian{' '}
              <Link to="/yleista/tietosuoja">
                tietojenkäsittelyperiaatteiden
              </Link>{' '}
              mukaisesti. Voin peruuttaa suostumukseni ilmoittamalla siitä
              Alexandriaan.
            </>
          ) : (
            ''
          )}
        </div>
        <Button
          className={classes.button}
          disabled={!isValidForm(state)}
          onClick={() => handleSubmit()}
        >
          <span className="button-label">Lähetä viesti</span>
          <Forward className="button-icon" />
        </Button>
      </div>
    </div>
  );
};

export default Form;
