import React from 'react';
import { useSelector } from 'react-redux';
import { useTheme, useMediaQuery } from '@material-ui/core';

import { RootState } from '../../../store';

import Nav from '../../Nav';
import {
  filterByIncentiveClassStatus,
  HorizontalNav,
} from '../../../routes/navItems';
import { groups } from '../../../utils/common';
import { useUserHasIncentiveClass } from '../../../utils/user';

const Navigation = (): React.ReactElement | null => {
  const theme = useTheme();
  const incentiveClassActive = useUserHasIncentiveClass();
  const useSearchVisible = useSelector(
    (state: RootState) =>
      (state.user.controllableUsers?.length || 0) > 0 ||
      state.user.fsUser?.groups?.includes(groups.admin) ||
      false
  );

  const matchesTABLETPLUS = useMediaQuery(theme.breakpoints.up('tabletPlus'));
  const matchesLG = useMediaQuery(theme.breakpoints.up('lg'));
  const matchesWIDE = useMediaQuery(theme.breakpoints.up('wide'));
  const matchesTABLET = useMediaQuery(theme.breakpoints.up('tablet'));

  const getNumberOfNavItems = (): number => {
    switch (useSearchVisible) {
      case true: {
        if (matchesWIDE) return 5;
        if (matchesLG) return 4;
        if (matchesTABLETPLUS) return 3;
        if (matchesTABLET) return 2;
        return 0;
      }
      case false: {
        if (matchesLG) return 6;
        if (matchesTABLETPLUS) return 4;
        if (matchesTABLET) return 3;
        return 0;
      }
    }
  };

  const navItemsAmount = getNumberOfNavItems();
  const filteredHorizontalNav = filterByIncentiveClassStatus(
    incentiveClassActive,
    HorizontalNav
  );

  return navItemsAmount > 0 ? (
    <Nav
      navItems={filteredHorizontalNav.slice(0, navItemsAmount)}
      type="horizontal"
    />
  ) : null;
};

export default Navigation;
