import { getDay } from 'date-fns';
import { format, utcToZonedTime } from 'date-fns-tz';

export const TZ = 'Europe/Helsinki';

/**
 * Expects date in UTC!
 * if in doubt use Date.now()
 */
export const FormatDate = (
  date: Date | string | number | null,
  showTime?: boolean,
  hideYear?: boolean,
): string =>
  date
    ? format(utcToZonedTime(date, TZ), getFormatString(showTime, hideYear), {
        timeZone: TZ,
      })
    : '-';

export const getFormatString = (showTime?: boolean, hideYear?: boolean): string =>
  hideYear ? 'd.M.' : showTime ? 'd.M.yyyy H.mm' : 'd.M.yyyy';

/**
 * Expects date in UTC!
 * if in doubt use Date.now()
 */
export const FormatTime = (date: Date | string | number | null, hideMinutes?: boolean): string => {
  if (date) {
    const startHour = format(utcToZonedTime(date, TZ), 'H', { timeZone: TZ });
    if (hideMinutes) {
      const endHour = parseInt(startHour, 10) + 1;
      return `${startHour}-${endHour}`;
    } else {
      const formattedTime = format(utcToZonedTime(date, TZ), 'H.mm', { timeZone: TZ });
      return formattedTime;
    }
  } else {
    return '-';
  }
};

/** Returns day of week in human readable format */
export const FormatDayOfWeek = (date: Date | number): string => {
  const day = getDay(date);
  const weekdays = [
    'Sunnuntai',
    'Maanantai',
    'Tiistai',
    'Keskiviikko',
    'Torstai',
    'Perjantai',
    'Lauantai',
  ];
  return weekdays[day];
};

/**
 * Returns last saturday if the given date is sunday or monday,
 * otherwise returns the given day.
 * @param date The given date
 */
export const getBankingDate = (date: Date): Date => {
  const day = date.getDay();

  if (day < 2) {
    date.setDate(date.getDate() - day - 1);
    return date;
  }
  return date;
};
