import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  'anonymous-page': {
    width: '100%',
    '& > .content': {
      marginTop: '64px',
      marginLeft: '150px',
      [theme.breakpoints.down(theme.breakpoints.values.wide)]: {
        marginLeft: '120px',
      },

      [theme.breakpoints.down(theme.breakpoints.values.lg)]: {
        marginLeft: '103px',
      },
      [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
        marginLeft: '16px',
      },
      [theme.breakpoints.down(400)]: {
        marginLeft: '0px',
      },
    },

    '& h1': {
      color: theme.palette.secondary.main,
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    marginRight: '32px',

    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      flexDirection: 'column',
      '& > *:not(:first-child)': {
        marginTop: '16px',
      },
    },
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      marginRight: '16px',
    },
    [theme.breakpoints.down(400)]: {
      marginRight: '0px',
    },
  },
}));

export default useStyles;
