import React, { ReactElement } from 'react';
import { Link, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.secondary.main,
    fontSize: '16px',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  button: {
    '&:hover': {
      textDecoration: 'none',
    },
  },
  paper: {
    boxSizing: 'border-box',
    backgroundColor: 'white',
    position: 'absolute',
    top: '50%',
    left: '50%',
    maxWidth: '600px',
    transform: 'translate(-50%, -50%)',
    padding: '20px',
    borderRadius: '26px',
    outline: 'none',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

type LoginHelpProps = {
  className: string;
};

const LoginHelp = ({ className }: LoginHelpProps): ReactElement => {
  const classes = useStyles();

  return (
    <div className={className}>
      <Link
        href={'/static/pdfs/kirjautumisohje.pdf'}
        target={'_blank'}
        classes={{ root: classes.link }}
      >
        Avaa tästä ohje Omasalkun käyttöön
      </Link>
    </div>
  );
};

export default LoginHelp;
