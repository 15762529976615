import {
  ContactUs,
  ContactUsPersonal,
  ContactPreference,
} from '../../../types/local';

export const INITIAL_STATE: ContactUs = {
  personal: {
    firstnames: '',
    surname: '',
    phone: '',
    email: '',
    message: '',
  },
  recipient: 'omasalkku@alexandria.fi',
  contactPreference: ContactPreference.Sähköpostilla,
};

export type ContactUsAction =
  | { type: 'set-personal'; payload: ContactUsPersonal }
  | { type: 'set-to'; payload: string }
  | { type: 'set-contact-preference'; payload: ContactPreference }
  | { type: 'reset' };

export const reducer = (
  state: ContactUs,
  action: ContactUsAction
): ContactUs => {
  switch (action.type) {
    case 'set-personal': {
      return {
        ...state,
        personal: action.payload,
      };
    }
    case 'set-contact-preference': {
      return {
        ...state,
        contactPreference: action.payload,
      };
    }
    case 'set-to': {
      return {
        ...state,
        recipient: action.payload,
      };
    }
    case 'reset': {
      return INITIAL_STATE;
    }
    default:
      return { ...state };
  }
};
