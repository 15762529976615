import { ActionTypes, Actions, State } from './types';

export function selectTab(tab: State['selectedTab']): Actions {
  return {
    type: ActionTypes.SELECT_TAB,
    payload: tab,
  };
}

export function setQuery(query: State['query']): Actions {
  return {
    type: ActionTypes.SET_QUERY,
    payload: query,
  };
}

export function setActivityFeed(open: State['isActivityFeedOpen']): Actions {
  return {
    type: ActionTypes.SET_ACTIVITY_FEED,
    payload: open,
  };
}

export function setNavigation(open: State['isNavigationOpen']): Actions {
  return {
    type: ActionTypes.SET_NAVIGATION,
    payload: open,
  };
}

export function addOpenMenu(menu: string): Actions {
  return {
    type: ActionTypes.ADD_OPENMENU,
    payload: menu,
  };
}

export function removeOpenMenu(menu: string): Actions {
  return {
    type: ActionTypes.REMOVE_OPENMENU,
    payload: menu,
  };
}

export function setPopperMenu(menu: string): Actions {
  return {
    type: ActionTypes.SET_POPPERMENU,
    payload: menu,
  };
}

export function setLoading(loading: boolean): Actions {
  return {
    type: ActionTypes.SET_LOADING,
    payload: loading,
  };
}

export function setRedirect(location: Location | null): Actions {
  return {
    type: ActionTypes.SET_REDIRECT,
    payload: location,
  };
}

export function setViewedUser(user: State['viewedUser']): Actions {
  return {
    type: ActionTypes.SET_VIEWED_USER,
    payload: user,
  };
}

export function setNotification(data: State['notification']): Actions {
  return {
    type: ActionTypes.SET_NOTIFICATION,
    payload: data,
  };
}

export function setSearchFocused(focus: State['isSearchFocused']): Actions {
  return {
    type: ActionTypes.SET_SEARCH_FOCUSED,
    payload: focus,
  };
}

export function setInvestmentInfoOpen(
  open: State['isInvestmentInfoOpen']
): Actions {
  return {
    type: ActionTypes.SET_INVESTMENT_INFO_OPEN,
    payload: open,
  };
}

export function setPageScrolledDown(down: State['pageScrolledDown']): Actions {
  return {
    type: ActionTypes.SET_PAGE_SCROLLED_DOWN,
    payload: down,
  };
}
