import { Reducer } from '@reduxjs/toolkit';
import { State, Actions, ActionTypes as actions } from './types';

const INITIAL_STATE: State = {
  selectedTab: '',
  query: '',
  isActivityFeedOpen: false,
  isNavigationOpen: false,
  openMenus: [],
  popperMenu: '',
  isLoading: true,
  redirect: null,
  viewedUserUid: null,
  viewedUser: null,
  notification: {
    open: false,
    message: '',
    type: 'primary',
  },
  isSearchFocused: false,
  isInvestmentInfoOpen: false,
  pageScrolledDown: false,
};

export const uiReducer: Reducer<State, Actions> = (
  state: State = INITIAL_STATE,
  action: Actions
): State => {
  switch (action.type) {
    case actions.SELECT_TAB:
      return {
        ...state,
        selectedTab: action.payload,
      };
    case actions.SET_QUERY:
      return {
        ...state,
        query: action.payload,
      };
    case actions.SET_ACTIVITY_FEED:
      return {
        ...state,
        isActivityFeedOpen: action.payload,
      };
    case actions.SET_NAVIGATION:
      return {
        ...state,
        isNavigationOpen: action.payload,
      };
    case actions.ADD_OPENMENU:
      return state.openMenus.indexOf(action.payload) > -1
        ? state
        : {
            ...state,
            openMenus: [...state.openMenus, action.payload],
          };
    case actions.REMOVE_OPENMENU: {
      // remove indexof menu...
      const menuIndex = state.openMenus.indexOf(action.payload);

      return {
        ...state,
        openMenus: [
          ...state.openMenus.slice(0, menuIndex),
          ...state.openMenus.slice(menuIndex + 1),
        ],
      };
    }
    case actions.SET_POPPERMENU: {
      return {
        ...state,
        popperMenu: action.payload,
      };
    }
    case actions.SET_LOADING: {
      return {
        ...state,
        isLoading: action.payload,
      };
    }
    case actions.SET_REDIRECT: {
      return {
        ...state,
        redirect: action.payload,
      };
    }
    case actions.SET_VIEWED_USER: {
      return {
        ...state,
        viewedUser: action.payload,
        viewedUserUid: action.payload?.uid || null,
      };
    }
    case actions.SET_NOTIFICATION: {
      return {
        ...state,
        notification: action.payload,
      };
    }
    case actions.SET_SEARCH_FOCUSED: {
      return {
        ...state,
        isSearchFocused: action.payload,
      };
    }
    case actions.SET_INVESTMENT_INFO_OPEN: {
      return {
        ...state,
        isInvestmentInfoOpen: action.payload,
      };
    }
    case actions.SET_PAGE_SCROLLED_DOWN: {
      return {
        ...state,
        pageScrolledDown: action.payload,
      };
    }
    case 'LOGOUT':
      return {
        ...INITIAL_STATE,
        isLoading: state.isLoading,
        isActivityFeedOpen: state.isActivityFeedOpen,
      };
    default:
      return state;
  }
};
