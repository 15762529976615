import React from 'react';
import { useSelector } from 'react-redux';
import { Typography } from '@material-ui/core';
import clsx from 'clsx';

import { RootState } from '../../../../store';
import { Booking } from '../../../../types/local';
import { ChannelToLabel } from '../SelectChannel';
import { AdvisorCard } from '../SelectAdvisor';
import useStyles from './styles';
import {
  FormatDate,
  FormatDayOfWeek,
  FormatTime,
} from '../../../../utils/date';
import { getOfficeName } from '../SelectOffice';

const Summary = (props: { value: Booking, details: boolean }): React.ReactElement => {
  const classes = useStyles();
  const { value, details } = props;
  const offices = useSelector((state: RootState) => state.content.offices);
  const selectedOffice = offices?.find((v) => v.ToimistoID === value.officeId);
  const officeName = selectedOffice ? getOfficeName(selectedOffice, false) : '';

  return (
    <div className={clsx(classes['summary-container'], { compact: !details })}>
      <div className={classes['form-item']}>
        <Typography variant="h1" className={classes['field-heading']}>
          <span style={{ fontStyle: 'italic' }}>Varaa</span> aika sijoitusneuvontaan
        </Typography>
      </div>
      <div>
        {value.advisorId && 
          <AdvisorCard
            advisorId={value.advisorId}
            officeName={officeName}
            onClick={() => null}
            summary={
              value.date && (
                details
                ? [FormatDate(value.date, false, true), FormatDayOfWeek(value.date), FormatTime(value.time, true), ChannelToLabel(value.channel)]
                : [ChannelToLabel(value.channel)]
              )
            }
          />
        }
      </div>
    </div>
  );
};

export default Summary;
