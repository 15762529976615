import React from 'react';
import { useSelector } from 'react-redux';
// import { useHistory } from 'react-router-dom';

import { RootState } from '../../store';

// Nav variants
import { Horizontal } from './Horizontal';
import { Drawer } from './Drawer';

import menuFactoryDrawer from './Drawer/menuFactory';
import menuFactoryHorizontal from './Horizontal/menuFactory';
import { isUserInGroup, useUserHasIncentiveClass } from '../../utils/user';

import { NavSpec } from '../../types/local';
import { filterByIncentiveClassStatus } from '../../routes/navItems';

const Nav = (props: {
  type: 'horizontal' | 'vertical';
  navItems: NavSpec[];
}): React.ReactElement => {
  const { type, navItems } = props;
  const user = useSelector((state: RootState) => state.user.fsUser);
  const incentiveClassActive = useUserHasIncentiveClass();

  const filterByincentiveClassStatus = filterByIncentiveClassStatus(
    incentiveClassActive,
    navItems
  );

  const filteredNavItems = filterByincentiveClassStatus.filter((item) => {
    if (item.requiredRoles?.length) {
      return item.requiredRoles.every(
        (role) => user && isUserInGroup(user, role)
      );
    }
    return true;
  });

  const children =
    type === 'vertical'
      ? menuFactoryDrawer({
          navItems: filteredNavItems,
        })
      : menuFactoryHorizontal({
          navItems: filteredNavItems,
        });

  return (
    // <div className="navigation">
    <>
      {type === 'horizontal' && <Horizontal>{children}</Horizontal>}
      {type === 'vertical' && <Drawer>{children}</Drawer>}
    </>
    // </div>
  );
};

export default Nav;
