import { store } from '../store';
import { setNotification } from '../store/ui/actions';

/**
 * Returns full url with given basepath and params object.
 * Filters null values from params
 */
export const createURL = (
  basePath: string,
  params: Record<string, string | number | null>
): string => {
  const url = new URL(basePath, window.location.origin);
  const searchParams = Object.entries(params)
    .filter(([_, v]) => v != null)
    .reduce((prev, [key, value]) => ({ ...prev, [key]: value }), {});
  url.search = new URLSearchParams(searchParams).toString();

  return url.toString();
};

export const showNotification = (
  message: string,
  type: 'primary' | 'secondary' | 'error' = 'primary',
  time = 5000
): void => {
  store.dispatch(
    setNotification({
      open: true,
      message,
      type,
    })
  );
  setTimeout(
    () =>
      store.dispatch(
        setNotification({
          open: false,
          message: '',
          type: 'primary',
        })
      ),
    time
  );
};

export const noInvestmentsMessage = 'Sinulla ei ole sijoituksia';

/** Disclaimer texts to use where needed */
export const disclaimers = {
  main: 'Tämän järjestelmän kautta voit seurata sijoitusrahastojesi, strukturoitujen sijoitustuotteittesi, vakuutustesi ja yrityslainojesi omistuksia. Mahdolliset sijoitusvakuutuksessa olevat sijoitusrahastojen ja strukturoitujen sijoitustuotteiden omistukset näkyvät vakuutuksia koskevalla välilehdellä. Mahdolliset muualla säilytyksessä olevat strukturoitujen tuotteiden omistukset eivät näy palvelussa. Tällä hetkellä sijoitusrahastojen arvot ja arvostuspäivät pyritään päivittämään jokaisena pankkipäivänä sekä strukturoitujen sijoitustuotteiden arvot kerran viikossa perjantaisin ja kuukauden viimeisenä arkipäivänä. Vakuutusten osalta osa tarvittavista päivitettävistä tiedoista saadaan Alexandrian ulkoisilta yhteistyökumppaneilta. Yhteistyökumppanien sijaitessa muualla kuin Suomessa pankkipäivät saattavat erota Suomen pankkipäivistä. Alexandria pitää tätä järjestelmää ja yhteistyökumppaneilta saatuja tietoja luotettavina, mutta ei vastaa palvelun käytettävyydestä, päivitysten viivästyksistä tai tietojen oikeellisuudesta ja mahdollisesta puutteellisuudesta. Näkyvillä olevat arvot ja arvostuspäivät eivät ole tae sijoitustuotteen tai vakuutuksen kyseisen hetken mukaisesta tilanteesta. Markkinatilanne voi muuttua nopeastikin ja asiakkaan tulee aina tarkistaa kyseisen sijoitustuotteen tai vakuutuksen todellinen ajantasainen markkina-arvo mahdollista toimeksiantoa tehdessään.',
  funds:
    'Sijoitusrahasto-osuuksien arvot ja arvostuspäivät pyritään päivittämään järjestelmään jokaisena pankkipäivänä. Osa tarvittavista päivitettävistä tiedoista saadaan Alexandrian ulkoisilta yhteistyökumppaneilta. Alexandria pitää tätä järjestelmää ja yhteistyökumppaneilta saatuja tietoja luotettavina, mutta ei vastaa palvelun käytettävyydestä, päivitysten viivästyksistä tai tietojen oikeellisuudesta ja mahdollisesta puutteellisuudesta. Näkyvillä olevat arvot ja arvostuspäivät eivät ole tae sijoitusrahaston rahasto-osuuksien kyseisen hetken mukaisesta markkina-arvosta. Markkinatilanne voi muuttua nopeastikin ja asiakkaan tulee aina tarkistaa sijoitusrahaston rahasto-osuuksien tarkka markkina-arvo ennen päätöstä merkitä tai lunastaa sijoitusrahasto-osuuksia.',
  corporateFinancing:
    '** Korkotuotto ei kuvasta sijoitusten lopullista tuottoa, sillä laskenta ei huomioi mahdollisia jäljellä olevien lainapääomien luottotappioita.',
  corporateFinancingInterest: '* Saadut korot ennen ennakonpidätyksiä',
  structures:
    'Näkyvillä olevat arvot ja arvostuspäivät eivät ole tae sijoitustuotteen kyseisen hetken mukaisesta tilanteesta. Markkinatilanne voi muuttua nopeastikin ja asiakkaan tulee aina tarkistaa tarkka markkinahinta ennen päätöstä ostaa tai myydä arvopapereita. Markkinahinnat edustavat aina Alexandrian parasta käsitystä ajankohtaisesta markkinahinnasta, mutta osa markkinahinnoista voi perustua laskennallisiin hintoihin tai aiempien päivien markkinahintoihin. Huomioi markkinahintojen päivämäärä. Alexandria ei vastaa mahdollisista tietojen oikeellisuudesta, puutteellisuudesta eikä päivitysten viivästyksistä tai vahingosta, joka voi syntyä sivustolla näkyvän markkinahinnan virheellisyydestä. Voit seurata strukturoitujen sijoitustesi kehitystä sivun oikean laidan katsauksista. Sijoitusten katsaukset havainnollistavat sijoitustuotteen ehtojen mukaista tuotonlaskennan kehitystä liikkeeseenlaskuhetkestä lähtien. Huomioithan, että katsauksessa näkyvä laskennallinen erääntymisarvo eroaa sijoituksen kulloisestakin markkinahinnasta muulloin paitsi eräpäivänä. Mahdolliset muualla säilytyksessä olevat omistukset eivät näy palvelussa.',
  'structures-profits':
    'Huomioithan, että tuotonmaksut näkyvät tällä sivulla vain, jos arvopaperi on Alexandrian asiakkaalle järjestämässä säilytyksessä. Alexandria ei vastaa tietojen oikeellisuudesta tai mahdollisesta puutteellisuudesta.',
  'structures-star':
    '* Strukturoidut sijoitukset, joita säilytetään arvopaperitilillä Alexandria Group Oyj:ssä. Jos säilytys on jossain muualla, tiedon saa säilytysyhteisön raportilta.',
  insurances:
    'Sijoitusvakuutusten arvot ja arvostuspäivät pyritään päivittämään järjestelmään jokaisena pankkipäivänä. Osa tarvittavista päivitettävistä tiedoista saadaan Alexandrian ulkoisilta yhteistyökumppaneilta. Yhteistyökumppanien sijaitessa muualla kuin Suomessa pankkipäivät saattavat erota Suomen pankkipäivistä. Alexandria pitää tätä järjestelmää ja yhteistyökumppaneilta saatuja tietoja luotettavina, mutta ei vastaa palvelun käytettävyydestä, päivitysten viivästyksistä tai tietojen oikeellisuudesta ja mahdollisesta puutteellisuudesta. Näkyvillä olevat arvot ja arvostuspäivät eivät ole tae sijoitustuotteen tai vakuutuksen kyseisen hetken mukaisesta tilanteesta. Näkyvillä olevat arvot, markkina-arvot ja arvostuspäivät eivät ole tae vakuutuksen, sijoitusrahaston rahasto-osuuksien tai strukturoidun sijoitustuotteen kyseisen hetken mukaisesta markkina-arvosta. Markkinatilanne voi muuttua nopeastikin ja asiakkaan tulee aina tarkistaa vakuutuksen, sijoitusrahaston rahasto-osuuksien tai strukturoidun sijoitustuotteen tarkka markkina-arvo ennen päätöstään antaa osto- tai myyntitoimeksianto tai muu mahdollinen toimeksianto.',
  'insurances-report1':
    'Sijoitusvakuutusten arvot ja arvostuspäivät pyritään päivittämään järjestelmään jokaisena pankkipäivänä. Osa tarvittavista päivitettävistä tiedoista saadaan Alexandrian ulkoisilta yhteistyökumppaneilta. Yhteistyökumppanien sijaitessa muualla kuin Suomessa pankkipäivät saattavat erota Suomen pankkipäivistä. Alexandria pitää tätä järjestelmää ja yhteistyökumppaneilta saatuja tietoja luotettavina, mutta ei vastaa palvelun käytettävyydestä, päivitysten viivästyksistä tai tietojen oikeellisuudesta ja mahdollisesta puutteellisuudesta. Näkyvillä olevat arvot ja arvostuspäivät eivät ole tae sijoitustuotteen tai vakuutuksen kyseisen hetken mukaisesta tilanteesta. Näkyvillä olevat arvot, markkina-arvot ja arvostuspäivät eivät ole tae vakuutuksen, sijoitusrahaston rahasto-osuuksien tai strukturoidun sijoitustuotteen kyseisen hetken mukaisesta markkina-arvosta. Markkinatilanne voi muuttua nopeastikin ja asiakkaan tulee aina tarkistaa vakuutuksen, sijoitusrahaston rahasto-osuuksien tai strukturoidun sijoitustuotteen tarkka markkina-arvo ennen päätöstään antaa osto- tai myyntitoimeksianto tai muu mahdollinen toimeksianto.',
  'insurances-report2':
    'Nämä tiedot eivät ole vakuutusyhtiön antama virallinen Vakuutussopimuslain (172/2017) tai Komission delegoidun asetuksen (EU) 2017/2359 mukainen selvitys/tiedote lainsäädännön mukaan annettavista tiedoista. Näitä tietoja ei tule käyttää veroilmoituksen antamista varten. Vakuutusyhtiö toimittaa sijoitusvakuutuksia koskevat lainsäädännön mukaiset viralliset selvitykset/tiedotteet suoraan asiakkaalle.',
  generic:
    'Alexandria ei vastaa tietojen oikeellisuudesta tai mahdollisesta puutteellisuudesta.',
  contactUs:
    'Täyttämällä lomakkeen annan Alexandrialle suostumukseni tietojeni käyttämiseen Alexandrian tietojenkäsittelyperiaatteiden mukaisesti.',
  corporateFinancingHoldings:
    '* Lainan lyhennyserien ja korkoerien määrät voivat poiketa toisistaan, mikäli lainalle on laina-aikana myönnetty lyhennysvapaata.',
} as const;

/** The used user groups from Azure AD */
export const groups = {
  admin: 'Extranetadmin',
} as const;

/** Definitions to show instead of morningstar ratings for funds that don't have morningstar ratings */
export const fundsMorningstarAlternatives = [
  {
    isins: ['FI4000270053', 'FI4000426044'],
    definition:
      'Morningstar ei laske Alexandria Kiinteistö ja Reaaliomaisuus erikoissijoitusrahastoille erillistä tähtiluokitusta rahastojen omaisuusluokista johtuen',
  },
  {
    isins: ['FI4000513163'],
    definition:
      'Alexandria Pienyhtiöt Erikoissijoitusrahasto aloittanut toimintansa 22.11.2021, joten sillä ei ole vielä vaadittavaa 3 vuoden tuottohistoriaa Morningstar -luokituksen saamiseksi.',
  },
] as const;

/**
 * Replaces the usage of `validator` library becauase it doesn't recognize 7-digit phonenumbers which are valid.
 * This regex is copied from `validator` library but slightly modified
 */
export const isMobilePhone = (num: string) => {
  const validator = /^(\+?358|0)\s?(4(0|1|2|4|5|6)?|50)\s?(\d\s?){3,8}\d$/;
  const matches = num.match(validator);
  return !!matches;
};
