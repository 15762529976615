import { getIdToken } from '../utils/firebase';
import { sendAbcLogAction } from '../utils/api';
import { LogInfo, LogActionName } from '../types/api';

export const createLogInfoData = (
  response: Response,
  logActionName: LogActionName,
  logActionInfo: string,
  customerId?: number,
  customerSSC = '',
  viewerEmail = '',
  viewerId?: number,
  viewerSSC = '',
  signInTime = ''
) => {
  try {
    const isEmployee = viewerEmail?.endsWith('@alexandria.fi');

    const logInfo = {
      Action: {
        Name: logActionName,
        Info: logActionInfo,
        Successful: response?.status === 200,
      },
      Session: {
        ID: signInTime,
        CustomerID: customerId || 0,
        ViewerCustomerID: viewerId || 0,
        Email: viewerEmail,
        EmployeeView: isEmployee,
      },
      Meta: {
        Status: response?.status,
        StatusDescription: response?.statusText,
        // ErrorText is set if the response status is anything other than 200.
        ErrorText: response?.status !== 200 ? response?.statusText : undefined,
        CustomerSSC: customerId === 0 ? customerSSC : undefined,
        // ViewerCustomerSSC is only set if user is not employee and ViewerCustomerID is not set
        ViewerCustomerSSC: isEmployee && viewerId === 0 ? viewerSSC : undefined,
      },
    };
    return logInfo;
  } catch (error) {
    console.error('Error enriching logInfo:', error);
    throw error;
  }
};

export const handleAbcLogAction = async (logInfo: LogInfo) => {
  const idToken = await getIdToken();
  return await sendAbcLogAction(logInfo, idToken);
};
