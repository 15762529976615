import React from 'react';
import { Switch, Route } from 'react-router-dom';

import PrivacyPolicy from '../views/General/PrivacyPolicy';
import TermsOfUse from '../views/General/TermsOfUse';
import Accessibility from '../views/General/Accessibility';
import NotFound from '../views/NotFound';

const GeneralRoute = (): React.ReactElement => {
  return (
    <Switch>
      <Route path="/yleista/tietosuoja">
        <PrivacyPolicy />
      </Route>
      <Route path="/yleista/kayttoehdot">
        <TermsOfUse />
      </Route>
      <Route path="/yleista/saavutettavuus">
        <Accessibility />
      </Route>
      <Route>
        <NotFound />
      </Route>
    </Switch>
  );
};

export default GeneralRoute;
