/* eslint-disable  @typescript-eslint/no-empty-function */
import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { format } from 'date-fns-tz';
import { Typography, List, ListItem } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { getIdToken } from '../../../../utils/firebase';
import { RootState } from '../../../../store';
import { FSAdvisor } from '../../../../types/firestore';
import { CalendarAction } from '../../../Calendar/Form/state';
import { getComparator, stableSort } from '../../../../utils/sort';
import { getSlotsByOfficeDate_v2 } from '../../../../utils/api';
import { getOfficeName } from '../SelectOffice';
import { Office } from '../../../../types/abc';

import { ReactComponent as SearchIcon } from '../../../../../src/images/svg/searchIcon.svg';
import { ReactComponent as Check } from '../../../../images/svg/check-nostyle.svg';

import useStyles from './styles';
import { Booking } from '../../../../types/local';
import { Slot } from '../../../../types/abc';
import Spinner from '../../../../components/LoadingIndicator';
import { FormatTime, TZ } from '../../../../utils/date';

/** returns Advisor card by advisorId */
export const AdvisorCard = (props: {
  advisorId: number;
  officeName?: string;
  onClick?: () => void;
  selected?: boolean;
  summary?: string[] | undefined;
  dispatch?: React.Dispatch<CalendarAction>;
}): React.ReactElement | null => {
  const allAdvisors = useSelector((state: RootState) => state.content.advisors);
  const advisor = allAdvisors
    ? allAdvisors.find((v) => v.NeuvonantajaID === props.advisorId)
    : null;
  const offices = useSelector((state: RootState) => state.content.offices);
  return advisor ? (
    <Advisor
      {...advisor}
      selected={props.selected || false}
      officeName={(offices.find(office => office.ToimistoID === advisor.ToimistoID) || {}).ToimistoNimi || undefined}
      onClick={props.onClick || (() => {})}
      summary={props.summary}
      dispatch={props.dispatch || (() => {})}
    />
  ) : null;
};

const Advisor = (
  props: FSAdvisor & {
    onClick: (advisorId: number) => void;
    selected: boolean;
    times?: Slot[];
    selectedTime?: string;
    onTimeClick?: (advisorId: number, time: string) => void;
    office?: number;
    officeName?: string;
    summary?: string[];
    dispatch: React.Dispatch<CalendarAction>;
  }
): React.ReactElement => {
  const classes = useStyles();
  const {
    NeuvonantajaID,
    NeuvonantajaNimi,
    Kuva,
    Titteli,
    TittelinTarkenne,
    Koulutukset,
    Sahkopostiosoite,
    onClick,
    selected,
    selectedTime,
    times,
    onTimeClick,
    office,
    officeName,
    summary,
    dispatch,
  } = props;
  const image =
    Kuva ||
    'https://images.ctfassets.net/3gh7jphuo2o7/6IVxqVPD4NABPLNJalY7D6/1d85f5e61b8d7ed63ea6a802138cfd04/imageplaceholder.jpg';

  const handleAdvisorSelect = (advisorId: number) => {
    dispatch({ type: 'set-advisor', payload: advisorId });
  };

  return (
    <ListItem
      className={clsx(classes.card, { selected: selected }, { summary: summary })}
      onClick={() => { !summary && handleAdvisorSelect(NeuvonantajaID) }}
    >
      <div className="left">
        <div className={classes.avatar}>
          <picture>
            <source
              srcSet={image}
              type="image/webp"
            />
            <source
              srcSet={image}
              type="image/jpeg"
            />
            <img
              src={image}
              alt={NeuvonantajaNimi}
              loading="lazy"
            />
          </picture>
        </div>{' '}
      </div>
      <div className="right">
        <div className="contact">
          <div className="name">
            <Typography variant="h3">{NeuvonantajaNimi}</Typography>
          </div>
          <div className="body">
            <Typography variant="body2">{Titteli}</Typography>
          </div>
          <div className="footer">
            <Typography variant="body2">
              {officeName}
            </Typography>
          </div>
        </div>
        {summary &&
          <div className="summaryContainer">
            {summary[2] && <Typography variant="body2" className="title">{summary[0]} {summary[1]} klo {summary[2]}</Typography>}
            <Typography variant="body2" className="footer">{summary[3] || summary[0]}</Typography>
          </div>
        }
        {selected && (
          <span className={classes.check}>
            <Check className={classes.checkIcon} />
          </span>
        )}
      </div>
    </ListItem>
  );
};

/*
const resetSelectedAdvisor = (
  state: Booking,
  slots: Slot[],
  dispatch: React.Dispatch<CalendarAction>
) => {
  const availableAdvisorIds = slots.map((v) => v.NeuvonantajaID);
  if (state.advisorId && !availableAdvisorIds.includes(state.advisorId)) {
    dispatch({ type: 'set-advisor', payload: undefined });
    dispatch({ type: 'set-time', payload: '' });
  }
};
*/

const SelectAdvisor = (props: {
  state: Booking;
  value: number | undefined;
  selectedOffice?: number;
  dispatch: React.Dispatch<CalendarAction>;
  onClick?: () => void;
}): React.ReactElement => {
  const classes = useStyles();
  const { state, value, dispatch } = props;
  const [slots, setSlots] = useState<Slot[]>([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState<(Office | FSAdvisor)[]>([]);
  const offices = useSelector((state: RootState) => state.content.offices);

  async function updateDates() {
    const idToken = await getIdToken();
    if (state.date) {
      setLoading(true);
      try {
        const requestOptions: {
          office: number;
          advisor: number;
          channel: number;
          date?: string;
        } = {
          office: -1,
          advisor: -1,
          channel: state.channel,
        };
        
        if (state.view !== 2) {
          requestOptions.date = format(state.date, 'yyyy-MM-dd', {
            timeZone: TZ,
          });
        }
        
        const response = await (
          await getSlotsByOfficeDate_v2(idToken, requestOptions)
        ).json();
        setSlots(response);
        // resetSelectedAdvisor(state, response, dispatch);
        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    }
  }
  useEffect(() => {
    updateDates();
  }, [state.channel, state.date, state.view]);

  /** sorted advisors */
  let availableAdvisorIds: number[];
  const officeOrder = [
    'helsinki',
    'espoo',
    'vantaa',
    'vantaa-loiste',
    'tampere',
    'turku', 'oulu',
    'jyväskylä',
    'lahti',
    'hämeenlinna',
    'vaasa',
    'pori',
    'seinäjoki',
    'kuopio',
    'joensuu',
    'hyvinkää',
    'rauma',
    'savonlinna',
    'kouvola',
    'kokkola',
    'forssa',
    'kotka',
    'lappeenranta',
    'mikkeli',
    'salo',
    'kajaani',
    'rovaniemi',
    'tornio',
    'porvoo',
    'lempäälä',
    'ylivieska'
  ];

  if (state.view === 1) {
    availableAdvisorIds = slots
      .filter(v => v.Aika === state.time)
      .map(v => v.NeuvonantajaID);
  } else {
    availableAdvisorIds = slots.map(v => v.NeuvonantajaID);
  }
  
  const advisors = stableSort(
    useSelector((state: RootState) =>
      state.content.advisors
        .filter((a) => availableAdvisorIds.includes(a.NeuvonantajaID))
    ),
    (a, b) => {
      const officeNameA = ((offices.find(office => office.ToimistoID === a.ToimistoID) || {}).ToimistoNimi as string).toLowerCase();
      const officeNameB = ((offices.find(office => office.ToimistoID === b.ToimistoID) || {}).ToimistoNimi as string).toLowerCase();
  
      const indexA = officeOrder.indexOf(officeNameA);
      const indexB = officeOrder.indexOf(officeNameB);
  
      if (indexA === -1) return 1;
      if (indexB === -1) return -1;
  
      if (indexA !== indexB) {
        return indexA - indexB;
      } else {
        // Sort advisors alphabetically for the office
        const advisorNameA = a.NeuvonantajaNimi.toLowerCase();
        const advisorNameB = b.NeuvonantajaNimi.toLowerCase();
        return advisorNameA.localeCompare(advisorNameB);
      }
    }
  );

  const handleAdvisorSelect = (advisorId: number) => {
    dispatch({ type: 'set-advisor', payload: advisorId });
  };

  const handleTimeSelect = (advisorId: number, time: string) => {
    dispatch({ type: 'set-advisor', payload: advisorId });
    dispatch({ type: 'set-time', payload: time });
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchTerm(value);
  
    if (value.length >= 3) {
      const filteredAdvisors = advisors.filter(
        (advisor) => {
          const advisorName = (advisor.NeuvonantajaNimi as string).toLowerCase();
          const officeName = ((offices.find(office => office.ToimistoID === (advisor as FSAdvisor).ToimistoID) || {}).ToimistoNimi as string).toLowerCase();

          return advisorName.includes(value.toLowerCase()) || officeName.includes(value.toLowerCase());
        }
      );
      setSearchResults(filteredAdvisors as FSAdvisor[]);
    } else {
      setSearchResults(advisors as FSAdvisor[]);
    }
  };
  
  return !loading ? (
    <div className={classes.advisorContainer}>
      <div className={classes.searchContainer}>
        <input
          type="text"
          className={classes.search}
          value={searchTerm}
          onChange={handleSearch}
          placeholder='Hae paikkakuntaa tai henkilöä'
        />
        <SearchIcon className={classes.searchIcon} />
      </div>
      {searchTerm.length >= 3 ? (
        <List className={classes.container}>
          {(searchResults as FSAdvisor[]).map((v: FSAdvisor) => (
            <Advisor
              key={v.NeuvonantajaID}
              selected={value === v.NeuvonantajaID}
              onClick={handleAdvisorSelect}
              times={slots.filter((s) => s.NeuvonantajaID === v.NeuvonantajaID)}
              selectedTime={state.time}
              onTimeClick={handleTimeSelect}
              office={v.ToimistoID}
              officeName={(offices.find(office => office.ToimistoID === v.ToimistoID) || {}).ToimistoNimi || undefined}
              dispatch={dispatch}
              {...v}
            />
          ))}
        </List>
      ) : (
        <List className={classes.container}>
          {advisors.map((v: FSAdvisor) => (
            <Advisor
              key={v.NeuvonantajaID}
              selected={value === v.NeuvonantajaID}
              onClick={handleAdvisorSelect}
              times={slots.filter((s) => s.NeuvonantajaID === v.NeuvonantajaID)}
              selectedTime={state.time}
              onTimeClick={handleTimeSelect}
              office={v.ToimistoID}
              officeName={(offices.find(office => office.ToimistoID === v.ToimistoID) || {}).ToimistoNimi || undefined}
              dispatch={dispatch}
              {...v}
            />
          ))}
        </List>
      )}
    </div>
  ) : (
    <Spinner variant="medium" />
  );
};

export default SelectAdvisor;
