import React, { useState, Children, ReactElement, useEffect } from 'react';
import MuiSelect, { SelectProps } from '@material-ui/core/Select';
import { MenuItem } from '@material-ui/core';

import { useStylesSelect, useStylesMenu, useStylesMenuList } from './styles';

import { ReactComponent as DownIcon } from '../../images/svg/down_small.svg';

interface CustomSelectProps extends SelectProps {
  /** This is called with the updated values when they update */
  onUpdate?(values: [] | string): void;

  /** Use @material-ui/core/MenuItem's */
  children: React.ReactNode;

  value: string[] | string;
}

const Select = ({
  children,
  multiple,
  onUpdate,
  placeholder,
  value,
  ...otherProps
}: CustomSelectProps): React.ReactElement => {
  const { root, select, icon } = useStylesSelect();
  const { paper, list } = useStylesMenu();
  const { root: menuListroot } = useStylesMenuList();
  const [selectedItems, setSelectedItems] = useState(value || []);
  const [selectedItem, setSelectedItem] = useState(value || '');

  useEffect(() => {
    if (multiple) setSelectedItems(value);
    else setSelectedItem(value);
  }, [value]);

  const handleChange = (
    event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>
  ): void => {
    if (multiple) {
      setSelectedItems(event.target.value as []);
      onUpdate && onUpdate(event.target.value as []);
    } else {
      setSelectedItem(String(event.target.value));
      onUpdate && onUpdate(String(event.target.value));
    }
  };

  return (
    <MuiSelect
      classes={{
        root,
        select,
        icon,
      }}
      disableUnderline={true}
      displayEmpty
      IconComponent={(iconProps) => <DownIcon {...iconProps} />}
      MenuProps={{
        classes: { paper, list },
        getContentAnchorEl: null,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
        MenuListProps: {
          classes: {
            root: menuListroot,
          },
        },
      }}
      multiple={multiple}
      onChange={handleChange}
      renderValue={(selected) => {
        if (!multiple && !selected) {
          return placeholder;
        }
        if (!multiple) {
          const _children = Children.map(children, (child) => {
            const childElement = child as ReactElement;
            return {
              name: childElement.props.children,
              value: childElement.props.value,
            };
          });
          const correctChild = _children?.find((x) => x.value === selected);
          return String(correctChild?.name);
        }
        if ((selected as []).length === 0) {
          return placeholder;
        }

        return `${(selected as []).length} valittu`;
      }}
      value={multiple ? selectedItems : selectedItem}
      {...otherProps}
    >
      <MenuItem disabled value="">
        {placeholder}
      </MenuItem>
      {children}
    </MuiSelect>
  );
};

export default Select;
