import { ActionTypes, Actions, State } from './types';
import { Page } from '../../types/contentful';

export function setContent(content: State['content']): Actions {
  return {
    type: ActionTypes.SET_CONTENT,
    payload: content,
  };
}

export function setOffices(offices: State['offices']): Actions {
  return {
    type: ActionTypes.SET_OFFICES,
    payload: offices,
  };
}

export function setAdvisors(advisors: State['advisors']): Actions {
  return {
    type: ActionTypes.SET_ADVISORS,
    payload: advisors,
  };
}

export function setInvestmentInfoData(
  data: State['investmentInfoData']
): Actions {
  return {
    type: ActionTypes.SET_INVESTMENT_INFO_DATA,
    payload: data,
  };
}

export function addPageInfo(data: Page): Actions {
  return {
    type: ActionTypes.ADD_PAGE_INFO,
    payload: data,
  };
}

export function setPageInfo(data: State['pageInfo']): Actions {
  return {
    type: ActionTypes.SET_PAGE_INFO,
    payload: data,
  };
}

export function setGlobalContent(data: State['globalContent']): Actions {
  return {
    type: ActionTypes.SET_GLOBAL_CONTENT,
    payload: data,
  };
}

export function setFrontpageContent(data: State['frontpageContent']): Actions {
  return {
    type: ActionTypes.SET_FRONTPAGE_CONTENT,
    payload: data,
  };
}

export function setCustomPagesContent(data: any): Actions {
  return {
    type: ActionTypes.SET_CUSTOM_PAGES_CONTENT,
    payload: data,
  };
}

export function setReviewContent(data: State['reviewContent']): Actions {
  return {
    type: ActionTypes.SET_REVIEW_CONTENT,
    payload: data,
  };
}

export function setCorporateFinancingContent(
  data: State['corporateFinancingContent']
): Actions {
  return {
    type: ActionTypes.SET_CORPORATE_FINANCING_CONTENT,
    payload: data,
  };
}
