import React from 'react';
import ListItemText from '@material-ui/core/ListItemText';

import { useDisplayName } from '../../utils/user';

import useStyles from './styles';

/**
 * TODO: copy Author component to here
 *  from ActivityFeedItem/Author
 *
 * Avatar might be used in the future
 */

const Avatar = (props: {
  photoUrl?: string | null | undefined;
}): React.ReactElement => {
  const classes = useStyles();
  const displayName = useDisplayName();

  return (
    <div className={classes.root}>
      {/* <ListItemAvatar>
        <MuiAvatar
          className={classes.avatar}
          src={props.photoUrl ? props.photoUrl : ''}
        />
      </ListItemAvatar> */}
      <ListItemText
        primaryTypographyProps={{
          className: classes.name,
        }}
        primary={displayName}
      />
    </div>
  );
};

export default Avatar;
