import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTheme, useMediaQuery } from '@material-ui/core';
import useStyles from '../styles';
import { ReactComponent as AlexandriaLogo } from '../../../images/svg/alexandria_header_sin.svg';

import { RootState } from '../../../store';

const Logo = (): React.ReactElement | null => {
  const history = useHistory();
  const theme = useTheme();
  const classes = useStyles();

  const users = useSelector((state: RootState) => state.user.controllableUsers);
  const isSearchFocused = useSelector(
    (state: RootState) => state.ui.isSearchFocused
  );

  const matchesSM = useMediaQuery(theme.breakpoints.up('sm'));
  const matchesXS = useMediaQuery(theme.breakpoints.down('xs'));

  const shouldShowLogo = () => {
    if (matchesSM) return true;
    else if (matchesXS && !users?.length) return true;
    else if (matchesXS && users?.length && !isSearchFocused) return true;

    return false;
  };
  return shouldShowLogo() ? (
    <button
      className={classes.logo}
      onClick={() => history.push('/')}
      aria-label="Etusivulle"
    >
      <AlexandriaLogo />{' '}
    </button>
  ) : null;
};

export default Logo;
