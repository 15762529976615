import React from 'react';

import { Author } from './Author';
import { Content } from './Content';

import useStyles from './styles';
import { ContentItemProps } from './Content';
export type ActivityType = 'funds' | 'meeting' | 'news' | 'milestone' | string;
export type ActivityFeedItemProps = ContentItemProps & {
  type: ActivityType;
  author: {
    name: string;
    photo: string;
    additionalInfo: React.ReactElement | string;
  };
};

export const ActivityFeedItem = (
  props: ActivityFeedItemProps
): React.ReactElement => {
  const classes = useStyles();
  const { author, ...content } = props;

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Content {...content} classes={classes} />
        <Author {...author} />
      </div>
    </div>
  );
};
