import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    [theme.breakpoints.down(400)]: {
      textAlign: 'left',
      display: 'flex',
      flexDirection: 'column-reverse',
    },
  },
  title: {
    color: theme.palette.secondary.main,
    [theme.breakpoints.down(theme.breakpoints.values.wide)]: {
      '& h1': {
        fontSize: '64px',
      },
    },
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      textAlign: 'center',
      '& h1': {
        fontSize: '48px',
      },
    },
  },
  decorations: {
    marginLeft: '300px',
    [theme.breakpoints.down(theme.breakpoints.values.wide)]: {
      marginLeft: '248px',
    },
    [theme.breakpoints.down(theme.breakpoints.values.lg)]: {
      marginLeft: '239px',
    },
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      marginLeft: 'auto',
      marginRight: 'auto',
      width: '200px',
    },
    [theme.breakpoints.down(400)]: {
      width: '35px',
      marginLeft: 'unset',
      marginRight: 'unset',
      position: 'absolute',
      top: '-29px',
      right: '7px',
    },
    '& .star': {
      marginLeft: '42px',
      [theme.breakpoints.down(theme.breakpoints.values.wide)]: {
        marginLeft: '24px',
      },

      [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
        marginLeft: '0px',
        textAlign: 'right',
      },
    },
    '& .star-large': {
      marginLeft: '6px',
      [theme.breakpoints.down(theme.breakpoints.values.wide)]: {
        marginLeft: '0px',
      },
      [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
        textAlign: 'right',
        marginRight: '8px',
      },
    },
  },
}));

export default useStyles;
