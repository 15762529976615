import firebase from 'firebase/app';
import { isToday } from 'date-fns';
import { DataSource } from '../dataSource';
import { store } from '../../store';
import { UpdateType } from '../../types/api';
import { DataSubscription } from '../dataSubscription';
import { setUtmostReports } from '../../store/reports/reportsSlice';
import { FsUtmostReports } from '../../types/firestore';

export class UtmostReportsDataSource extends DataSource {
  name: UpdateType = 'utmost-reports';
  subscribe(
    uid: string,
    _ownUid: string,
    db: firebase.firestore.Firestore
  ): DataSubscription {
    const userUtmostReportsRef = db.collection('utmostReports').doc(uid);
    const dispatch = store.dispatch;
    const firestoreUnsubscribe = userUtmostReportsRef.onSnapshot(
      async (snapshot) => {
        const data = snapshot.data() as FsUtmostReports | undefined;
        const updateDate = data?.updatedAt;
        if (updateDate && isToday(updateDate)) {
          dispatch(setUtmostReports(data.reports ?? []));
        } else {
          const idToken = await this.getIdToken();
          if (idToken) {
            this.requestUpdate({ target: 'utmost-reports', uid }, idToken);
          }
        }
      },
      (error) => {
        console.error(error);
      }
    );

    const unsubscribe = () => {
      firestoreUnsubscribe();
    };

    return new DataSubscription(this.name, unsubscribe);
  }
}
