import React, { useContext } from 'react';
import useStyles from './styles';
import { ReactComponent as AlexandriaLogo } from '../../images/svg/alexandria_pun.svg';
import { Link as MuiLink } from '@material-ui/core';
import { Link } from 'react-router-dom';

import { filterByIncentiveClassStatus, FullNav } from '../../routes/navItems';
import { NavSpec } from '../../types/local';
import { MiddleContext } from '../../layouts/Middle';
import { useUserHasIncentiveClass } from '../../utils/user';

export const FooterInfo = (): React.ReactElement => {
  return (
    <div className="info">
      <div>© 2023 ALEXANDRIA GROUP OYJ</div>
      <div>OYJ Y-TUNNUS 1063450-9</div>
    </div>
  );
};

const Footer = (): React.ReactElement => {
  const classes = useStyles();
  const incentiveClassActive = useUserHasIncentiveClass();
  const { anonymous } = useContext(MiddleContext);

  const investmentsNavItem = FullNav.find((x) => x.label === 'Sijoitukset');
  const investmentsNavItems: Pick<NavSpec, 'to' | 'label'>[] = [];

  if (investmentsNavItem) {
    investmentsNavItems.push(investmentsNavItem);
    investmentsNavItem.children?.forEach((x) =>
      investmentsNavItems.push({
        to: investmentsNavItem.to + x.to,
        label: x.label,
      })
    );
  }

  const otherNavItems = filterByIncentiveClassStatus(
    incentiveClassActive,
    FullNav
  ).filter(
    (x) =>
      x !== investmentsNavItem && x.label !== 'Yhteenveto' && !x.requiredRoles
  );

  return (
    <div className={classes.root}>
      <div className="container">
        <div className="logo">
          {anonymous ? (
            <MuiLink href="/">
              <AlexandriaLogo />
            </MuiLink>
          ) : (
            <AlexandriaLogo />
          )}
        </div>

        <div className="nav">
          {!anonymous && (
            <div className="nav-section investments">
              {investmentsNavItems.map((item) => (
                <Link to={item.to} key={item.to}>
                  {item.label}
                </Link>
              ))}
            </div>
          )}
          {!anonymous && (
            <div className="nav-section other">
              {otherNavItems.map((item) => (
                <Link to={item.to} key={item.to}>
                  {item.label}
                </Link>
              ))}
            </div>
          )}
        </div>

        <div className="legal">
          <Link to="/yleista/tietosuoja">Tietosuoja</Link>
          <Link to="/yleista/kayttoehdot">Käyttöehdot</Link>
          <Link to="/yleista/saavutettavuus">Saavutettavuus</Link>
        </div>
        <FooterInfo />
      </div>
    </div>
  );
};

export default Footer;
