import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  'personal-container': {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '16px',
    width: '100%',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '30px',
    alignItems: 'center',
    '& p:nth-child(2)': {
      fontSize: '14px',
    },
  },
  title: {
    fontFamily: 'Saol Display',
    fontSize: '22px',
    fontWeight: 600,
  },
  fields: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridColumnGap: '20px',
    gridRowGap: '10px',
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: '1fr',
    },
  },
  textField: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    marginBottom: '0',
    [theme.breakpoints.up('sm')]: {
      '&:nth-child(5):last-child': {
        gridColumn: 'span 2',
      },
    },
  },
  fieldPlaceholder: {
    position: 'absolute',
    top: '50%',
    fontSize: '20px',
    transform: 'translateY(-60%)',
    transition: 'transform 0.3s ease-out, font-size 0.3s ease-out',
    pointerEvents: 'none',
    transformOrigin: 'top left',
    zIndex: 1,
    [theme.breakpoints.down('xs')]: {
      fontSize: '18px',
    },
    '&.area': {
      top: '28px',
    },
    '&.focused': {
      transform: 'translateY(-180%)',
      fontSize: '14px',
      [theme.breakpoints.down('xs')]: {
        fontSize: '12px',
      },
    },
    '&.filled': {
      transform: 'translateY(-180%)',
      fontSize: '14px',
      [theme.breakpoints.down('xs')]: {
        fontSize: '12px',
      },
    },
  },
  field: {
    '& .MuiInputBase-root': {
      paddingTop: '28px',
      '& .MuiInputBase-input': {
        padding: '0',
      },
    },
  },
  textArea: {
    height: '90px',
    paddingTop: '12px',
    border: 'none',
    [theme.breakpoints.down('xs')]: {
      height: '85px',
    },
  },
  white: {
    background: theme.palette.white,
  },
  errorContainer: {
    height: '20px',
    [theme.breakpoints.down('xs')]: {
      height: '10px',
    },
  },
  errorContent: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  error: {
    fontSize: '16px',
    color: theme.palette.secondary.main,
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
  },
  errorIcon: {
    marginTop: '-30px',
    zIndex: 1,
  },
}));

export default useStyles;
