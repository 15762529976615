import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    fontFamily: 'Saol Display, Semibold',
    fontSize: '24px',
    margin: '32px',
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    width: '64px',
    height: '64px',
  },
  name: {
    fontFamily: 'Saol Display, Semibold',
    fontSize: '24px',
  },
}));

export default useStyles;
