import { Logout } from '../base';

export enum ActionTypes {
  SET_HASH = 'SET_HASH',
}

export interface State {
  hash?: string;
}

interface SetHash {
  type: typeof ActionTypes.SET_HASH;
  payload: State['hash'];
}

export type Actions =
  | Logout
  | SetHash;
