import React from 'react';
import clsx from 'clsx';
import { Typography } from '@material-ui/core';
import { Button } from '../../Button';

import useStyles from '../../styles';
import { ContentItemProps } from '..';

export const News = (props: ContentItemProps): React.ReactElement => {
  const classes = useStyles(props);
  const { title, body, header, linkTo } = props;
  return (
    <div className={clsx(classes['item-root'], 'news')}>
      <div className={classes['content-container']}>
        <div className={clsx(classes.content, 'news')}>
          <div className={clsx('header', classes.header)}>{header}</div>
          <div className="title">
            <Typography variant="h3">{title}</Typography>
          </div>
          <div className="body">{body}</div>
        </div>
        <div
          className={clsx(classes.button, 'news')}
          onClick={() => {
            console.log('clicked a feed item');
          }}
        >
          <Button to={linkTo} />
        </div>
      </div>
    </div>
  );
};
