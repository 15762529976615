import React from 'react';
import { CalendarAction } from '../../../Calendar/Form/state';
import { Booking } from '../../../../types/local';
import useStyles from '../styles';
import { Typography } from '@material-ui/core';

const Step4 = (props: {
  state: Booking;
  dispatch: React.Dispatch<CalendarAction>;
}): React.ReactElement => {
  const classes = useStyles({});

  // TODO: Oikea kuva + alt-teksti

  const image = 
    'https://images.ctfassets.net/3gh7jphuo2o7/6IVxqVPD4NABPLNJalY7D6/1d85f5e61b8d7ed63ea6a802138cfd04/imageplaceholder.jpg';
  const thumbnailSize = 120;

  return (
    <div className={classes.step4}>
      <div className={classes.avatar}>
        <picture>
          <source
            srcSet={`${image}?fit=thumb&w=${thumbnailSize}&h=${thumbnailSize}&f=top&r=max&fm=webp`}
            type="image/webp"
          />
          <source
            srcSet={`${image}?fit=thumb&w=${thumbnailSize}&h=${thumbnailSize}&f=top&r=max&fm=jpg`}
            type="image/jpeg"
          />
          <img
            src={`${image}?fit=thumb&w=${thumbnailSize}&h=${thumbnailSize}&f=top&r=max&fm=jpg`}
            alt={'NeuvonantajaNimi'}
            loading="lazy"
          />
        </picture>
      </div>{' '}
      <Typography variant="body1" className={classes['summary-heading']}>
        Kiitos, varauksesi on lähetetty!
      </Typography>
      <Typography variant="body1" className={classes.summaryDescription}>
        Saat pian antamaasi sähköpostiosoitteeseen vahvistuksen, lisätietoja varauksestasi ja
        kalenterimerkinnän, josta voit lisätä tapaamisen kätevästi omaan kalenteriisi.
      </Typography>
      <Typography variant="body1" className={classes.summaryDescription}>
        Tapaamisiin! 
      </Typography>
    </div>
  );
};

export default Step4;
