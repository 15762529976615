import { Reducer } from '@reduxjs/toolkit';
import { State, Actions, ActionTypes as actions } from './types';

const INITIAL_STATE: State = {
  activities: [],
  bookings: [],
};

export const activityReducer: Reducer<State, Actions> = (
  state: State = INITIAL_STATE,
  action: Actions
): State => {
  switch (action.type) {
    case actions.ADD_ACTIVITY:
      return {
        ...state,
        activities: [...state.activities, action.payload],
      };
    case actions.SET_ACTIVITIES:
      return {
        ...state,
        activities: [...state.activities, ...action.payload],
      };
    case actions.SET_BOOKINGS:
      return {
        ...state,
        bookings: action.payload,
      }
    case 'LOGOUT':
      return INITIAL_STATE;
    default:
      return state;
  }
};
