import { ActionTypes, Actions } from './types';

export function setContentOfficesLoaded(value: boolean): Actions {
  return {
    type: ActionTypes.SET_CONTENT_OFFICES_LOADED,
    payload: value,
  }
}

export function setContentContentsLoaded(value: boolean): Actions {
  return {
    type: ActionTypes.SET_CONTENT_CONTENTS_LOADED,
    payload: value,
  }
}

export function setContentAdvisorsLoaded(value: boolean): Actions {
  return {
    type: ActionTypes.SET_CONTENT_ADVISORS_LOADED,
    payload: value,
  }
}
