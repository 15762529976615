import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import useStyles from './styles';

/** list of public routes where we don't need login styling */
const isPublicRoute = (pathname: string) => {
  if (pathname == '/ajanvaraus') return true;
  if (pathname == '/vahvistus') return true;
  if (pathname == '/yleista/tietosuoja') return true;
  if (pathname == '/yleista/kayttoehdot') return true;
  if (pathname == '/yleista/saavutettavuus') return true;

  return false;
};

const getWindowHeight = () =>
  Math.ceil(window.innerHeight - document.body.scrollHeight) + 1;

/**
 * App wrapper layout
 * @param props children
 */
const App = (props: { children: JSX.Element }): React.ReactElement => {
  const classes = useStyles();
  const user = useSelector((state: RootState) => state.user.user);
  const isLoading = useSelector((state: RootState) => state.ui.isLoading);
  const isPublic = isPublicRoute(window.location.pathname);

  const [height, setHeight] = useState<number>(getWindowHeight());
  useEffect(() => {
    function handleResize() {
      setHeight(getWindowHeight());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div
      className={clsx(classes['app-center'], {
        login: !user && !isLoading && !isPublic,
      })}
    >
      <div className={classes['app-content-area']}>{props.children}</div>
      {!user && !isLoading && !isPublic && (
        <div
          className={classes.filler}
          style={{
            height: `${height}px`,
          }}
        />
      )}
    </div>
  );
};

export default App;
