import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, RouteComponentProps } from 'react-router-dom';
import { RootState } from '../store';
import { isUserInGroup } from '../utils/user';
import Loading from '../views/Loading';

interface PrivateRouteProps {
  path: string;
  exact?: boolean;
  component: React.ComponentType<RouteComponentProps>;
  roles: string[];
}

const PrivateRoute = ({
  component: Component,
  roles,
  ...rest
}: PrivateRouteProps): React.ReactElement => {
  const user = useSelector((state: RootState) => state.user.fsUser);

  return user ? (
    <Route
      {...rest}
      render={(props) => {
        if (user && roles?.every((role) => isUserInGroup(user, role))) {
          return <Component {...props} />;
        }
        return <Redirect to="/sivua-ei-löytynyt" />;
      }}
    />
  ) : (
    <Loading />
  );
};

export default PrivateRoute;
