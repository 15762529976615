import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  text: {
    margin: '32px auto 0px',
    maxWidth: 600,
    '& h3': {
      margin: '32px 0px',
    },
    '& img + p': {
      paddingTop: '1.45em',
    },
    '& iframe': {
      width: '100% !important',
    },
  },
  button: {
    justifyContent: 'flex-start',
    '& .button-label': {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    '& .button-icon': {
      marginLeft: '0.5rem',
      stroke: theme.palette.primary.main,
    },
    '&:hover': {
      '& .button-icon': {
        stroke: theme.palette.white,
      },
    },
  },
}));
