import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { Typography } from '@material-ui/core';
import { CalendarAction } from '../../../Calendar/Form/state';
import SelectDay from '../SelectDay';
import SelectOffice from '../SelectOffice';
import SelectChannel from '../SelectChannel';
import SelectView from '../SelectView';
import SelectAdvisor from '../SelectAdvisor';
import SelectTime from '../SelectTime';
import { AdvisorCard } from '../SelectAdvisor';

import { Booking } from '../../../../types/local';

import useStyles from '../styles';

const Step1 = (props: {
  state: Booking;
  dispatch: React.Dispatch<CalendarAction>;
}): React.ReactElement => {
  const classes = useStyles({});
  const { state, dispatch } = props;
  const user = useSelector((state: RootState) => state.user.fsUser);
  const offices = useSelector((state: RootState) => state.content.offices);
  const validOffice = offices.find((v) => v.ToimistoID === state.officeId);

  return validOffice || !user ? (
    <div className={classes.step1}>
      <div className="left">
        <div className={classes['form-item']}>
          <Typography variant="h1" className={classes['field-heading']}>
            <span style={{ fontStyle: 'italic' }}>Varaa</span> aika sijoitusneuvontaan
          </Typography>
          <SelectChannel
            value={state.channel}
            dispatch={dispatch}
          />
        </div>
        {!user?.neuvonantajaID ? (
          <>
            <div className={classes['form-item']}>
              <SelectView
                value={state.view}
                dispatch={dispatch}
              />
            </div>
            {state.view === 1 ? (
              <div className="right">
                <div className={classes['form-item']}>
                  <SelectDay state={state} dispatch={dispatch} />
                </div>
                <div className={classes['form-item']}>
                  <SelectTime state={state} dispatch={dispatch} />
                </div>
              </div>
            ) : (
              <>
                <div className={classes['form-item']}>
                  <SelectAdvisor
                    state={state}
                    selectedOffice={state.officeId}
                    value={state.advisorId}
                    dispatch={dispatch}
                  />
                </div>
                {user?.neuvonantajaID && (
                  <div className={classes['form-item']}>
                    <Typography variant="body1" className={classes['field-title']}>
                      Asiantuntija
                    </Typography>
                    <AdvisorCard advisorId={user.neuvonantajaID} />
                  </div>
                )}
              </>
            )}
          </>
        ) : (
          <>
            <div className={classes['form-item']}>
              <Typography variant="body1" className={classes['field-title']}>
                Toimipiste
              </Typography>
              <SelectOffice value={state.officeId} dispatch={dispatch} />
            </div>
            <div className={classes['form-item']}>
              <Typography variant="body1" className={classes['field-title']}>
                Asiantuntija
              </Typography>
              <AdvisorCard advisorId={user.neuvonantajaID} />
            </div>
            <div className="right">
              <div className={classes['form-item']}>
                <SelectDay state={state} dispatch={dispatch} />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  ) : (
    <div className={classes.step1}>
      <div className="left">
        <div className={classes['form-item']}>
          <p>
            Neuvonantajalle ei voi varata tapaamista verkossa, ole yhteydessä
            asiakaspalveluun numerossa 0200 10 100 tai neuvonantajaasi numerossa{' '}
            {state.phone}
          </p>
        </div>
        {user?.neuvonantajaID && (
          <div className={classes['form-item']}>
            <Typography variant="body1" className={classes['field-title']}>
              Asiantuntija
            </Typography>
            <AdvisorCard advisorId={user.neuvonantajaID} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Step1;
