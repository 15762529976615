import React from 'react';
import clsx from 'clsx';
import Radio, { RadioProps } from '@material-ui/core/Radio';
import useStyles from './styles';

import { ReactComponent as Checked } from '../../images/svg/radio-button-checked.svg';
import { ReactComponent as Unchecked } from '../../images/svg/radio-button-unchecked.svg';
const StyledRadio = (props: RadioProps) => {
  const classes = useStyles();

  return (
    <Radio
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<Checked className={classes.icon} />}
      icon={<Unchecked />}
      {...props}
    />
  );
};

export default StyledRadio;
