import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  overlay: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 9,
    top: 0,
    left: 0,
    '& .background': {
      background: theme.palette.grey['100'],
      opacity: 0.5,
      width: '100%',
      height: '100%',
      borderRadius: '23px 23px 23px 0px',
    },
    '& .content': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
      '-ms-transform': 'translate(-50%,-50%)',
    },
  },
  container: {
    position: 'relative',
    flex: 1,
    background: theme.palette.grey['200'],
    borderRadius: '23px 23px 23px 0px',
    padding: '32px',
    maxWidth: '794px',
    marginRight: '32px',
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      marginRight: '0px',
      maxWidth: '100%',
    },
  },
  'form-container': {
    flex: 1,
    display: 'flex',
    '& > *': {
      flex: 1,
    },
    '& .left': {
      marginRight: '32px',
      [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
        marginRight: '0px',
      },
    },
    '&:not(:first-child)': {
      marginTop: '32px',
    },
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      flexDirection: 'column',
    },
  },
  'form-others': {
    display: 'flex',
  },
  'form-footer': {
    display: 'flex',
    marginTop: '16px',
    '& > *': {
      flex: 1,
    },
  },
  'field-title': {
    textTransform: 'uppercase',
    marginBottom: '16px',
    fontWeight: 500,
  },
  'form-item': {
    display: 'flex',
    marginBottom: '16px',
    '&:last-child': {
      marginBottom: '0px',
      [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
        marginBottom: '16px',
      },
    },
    '&.flex': {
      flex: 1,
    },
    '& > *': {
      flex: 1,
    },
  },
  field: {
    marginBottom: '0px',
  },
  white: {
    background: theme.palette.white,
  },
  button: {
    '& .button-label': {
      marginLeft: 'auto',
      marginRight: 'auto',
      textTransform: 'none',
    },
    '& .button-icon': {
      stroke: theme.palette.primary.main,
      marginRight: '8px',
      '&.back': {
        marginRight: '0px',
        marginLeft: '8px',
      },
    },
    '&:hover': {
      '& .button-icon': {
        stroke: theme.palette.white,
      },
    },
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      width: '100%',
    },
  },
}));

export default useStyles;
