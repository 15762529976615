import React, { useReducer } from 'react';
import { Typography, useMediaQuery, useTheme } from '@material-ui/core';
import validator from 'validator';

import { LoginState, reducer } from '../state';
import {
  passwordSignIn,
  azureSignin,
  getViewedUser,
} from '../../../../utils/firebase';

import TextField from '../../../../components/TextField';
import Button from '../../../../components/Button';

import useStyles from '../styles';

import { ReactComponent as Forward } from '../../../../images/svg/forward.svg';

import Notification from '../../../../components/Notification';
import {
  handleAbcLogAction,
  createLogInfoData,
} from '../../../../utils/abcLogger';

const initialState: LoginState = {
  username: '',
  password: '',
  showPasswordField: true,
  showAzureInfo: false,
  error: '',
};

const handleAzureSignIn = async (username: string) => {
  const res = await azureSignin(username);
  const userData = await getViewedUser(res.result.user?.uid ?? '');
  const signInTime = res.result.user?.metadata.lastSignInTime;
  const userEmail = res.result.user?.email ?? '';

  const logInfo = await createLogInfoData(
    res.response,
    'Login',
    'User login',
    userData?.user.asiakasnumero,
    userData?.user.hetu,
    userEmail,
    userData?.user.asiakasnumero,
    userData?.user.hetu,
    signInTime
  );
  handleAbcLogAction(logInfo);
};

const handlePasswordSignIn = async (username: string, password: string) => {
  if (username.length > 0 && password.length > 0) {
    const res = await passwordSignIn(username, password);
    const userData = await getViewedUser(res.result.user?.uid ?? '');
    const signInTime = res.result.user?.metadata.lastSignInTime;
    const userEmail = res.result.user?.email ?? '';

    const logInfo = await createLogInfoData(
      res.response,
      'Login',
      'User login',
      userData?.user.asiakasnumero,
      userData?.user.hetu,
      userEmail,
      userData?.user.asiakasnumero,
      userData?.user.hetu,
      signInTime
    );
    handleAbcLogAction(logInfo);
  } else {
    throw new Error('Täytä käyttäjätunnus tai salasana.');
  }
};

const Form = (): React.ReactElement => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const classes = useStyles();
  const theme = useTheme();

  const matchesSM = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.sm)
  );
  const matchesWIDE = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.wide)
  );

  if (
    validator.isEmail(state.username) &&
    state.username.endsWith('@alexandria.fi') &&
    state.showPasswordField
  ) {
    dispatch({ type: 'set-show-password', payload: false });
    if (!state.showAzureInfo) {
      dispatch({ type: 'set-show-azure-info', payload: true });
    }
  } else if (!validator.isEmail(state.username) && !state.showPasswordField) {
    dispatch({ type: 'set-show-password', payload: true });
    if (state.showAzureInfo) {
      dispatch({ type: 'set-show-azure-info', payload: false });
    }
  }

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    dispatch({ type: 'set-error', payload: '' });
    if (state.username.endsWith('@alexandria.fi')) {
      handleAzureSignIn(state.username);
    } else {
      try {
        await handlePasswordSignIn(state.username, state.password);
      } catch (e: any) {
        dispatch({ type: 'set-error', payload: e.message || e });
      }
    }
  };

  return (
    <div className={classes['form-container']}>
      <div className="login-fields-title">
        {matchesSM ? (
          <>
            <Typography variant="h2">
              Kirjaudu <i>Sisään</i>
            </Typography>
          </>
        ) : (
          <>
            <Typography variant="h2">Kirjaudu</Typography>
            <Typography variant="h2">
              <i>Sisään</i>
            </Typography>
          </>
        )}
      </div>
      <div className={classes['login-fields-content']}>
        <Typography>
          Kirjaudu sisään sähköpostiosoitteella{' '}
          {state.showPasswordField && `ja salasanalla`}
          {state.showAzureInfo && `neuvonantajana`}
        </Typography>
      </div>
      <div className={classes['login-actions-container']}>
        <form onSubmit={handleSubmit}>
          <div className={classes['login-fields-container']}>
            <TextField
              inputProps={{ autoFocus: true }}
              fullWidth={true}
              placeholder="Sähköposti"
              type="email"
              color="secondary"
              name="email"
              id="email"
              value={state.username}
              onChange={(e) =>
                dispatch({
                  type: 'set-username',
                  payload: validator.trim(e.target.value),
                })
              }
              aria-label="Käyttäjätunnus"
            />
            {state.showPasswordField && (
              <TextField
                fullWidth={true}
                placeholder="Salasana"
                type="password"
                color="secondary"
                name="password"
                id="password"
                value={state.password}
                onChange={(e) =>
                  dispatch({ type: 'set-password', payload: e.target.value })
                }
                aria-label="Salasana"
              />
            )}
          </div>

          <Button
            disabled={!validator.isEmail(state.username)}
            className={classes.button}
            type="submit"
            aria-label="Kirjaudu"
          >
            {matchesWIDE ? (
              <span className={classes.buttonText}>Kirjaudu</span>
            ) : (
              ''
            )}
            <Forward className={classes.buttonIcon} />
          </Button>
        </form>
      </div>
      <Notification
        open={state.error !== ''}
        message={state.error}
        onClose={() => {
          dispatch({ type: 'set-error', payload: '' });
        }}
        variant={'error'}
      />
    </div>
  );
};

export default Form;
