import firebase from 'firebase/app';
import { DataSource, DataSourceUpdateType } from '../dataSource';
import { store } from '../../store';
import { setAdvisors } from '../../store/content/actions';
import { setContentAdvisorsLoaded } from '../../store/loaded/actions';
import { DataSubscription } from '../dataSubscription';
import { FSAdvisor } from '../../types/firestore';

export class AdvisorsDataSource extends DataSource {
  name: DataSourceUpdateType = 'advisors';
  subscribe(
    uid: string,
    idToken: string,
    db: firebase.firestore.Firestore
  ): DataSubscription {
    const advisorRef = db.collection('advisors').get();

    advisorRef.then((value) => {
      const advisors = value.docs.map((v) => v.data() as FSAdvisor);
      store.dispatch(setAdvisors(advisors));
      store.dispatch(setContentAdvisorsLoaded(true));
    });

    return new DataSubscription(this.name, () => null);
  }
}
