import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getTitleForUser } from '../UserSearch';
import { changeUserAction, RootState } from '../../../store';
import { DataLoader } from '../../../data-loader';
import { makeStyles } from '@material-ui/core/styles';
import { Theme, useMediaQuery } from '@material-ui/core';
import { setViewedUser } from '../../../store/ui/actions';

const useStyles = makeStyles((theme) => ({
  'viewed-user': {
    color: 'white',
    textAlign: 'center',
    padding: '6px',
    backgroundColor: theme.palette.secondary.main,
    flex: '0 0 100%',
    textTransform: 'none',
    position: 'fixed',
    top: 0,
    zIndex: 99999,
    width: '100%',
    maxWidth: '1920px',
  },
  'clear-btn': {
    marginLeft: '5px',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

const ShowViewedUser = (): React.ReactElement | null => {
  const viewedUser = useSelector((state: RootState) => state.ui.viewedUser);
  const classes = useStyles();
  const dispatch = useDispatch();
  const isXsScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down(400)
  );

  useEffect(() => {
    if (viewedUser !== null) {
      DataLoader.instance.changeViewToUser(viewedUser.uid);
    } else {
      DataLoader.instance.clearViewedUser();
    }
  }, [viewedUser]);

  const clearViewedUser = () => {
    dispatch(changeUserAction());
    dispatch(setViewedUser(null));
  };

  return viewedUser ? (
    <div className={classes['viewed-user']}>
      {isXsScreen ? (
        <>
          Näytössä <strong>{getTitleForUser(viewedUser)}</strong>.
        </>
      ) : (
        <>
          Näytetään käyttäjän <strong>{getTitleForUser(viewedUser)}</strong>{' '}
          tietoja.
        </>
      )}
      <span className={classes['clear-btn']} onClick={clearViewedUser}>
        Poistu
      </span>
    </div>
  ) : null;
};

export default ShowViewedUser;
