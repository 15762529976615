import { responsiveFontSizes, createTheme } from '@material-ui/core/styles';
// set default theme settings https://material-ui.com/customization/default-theme/

import AlexandriaFonts from './fonts';

interface InvestmentColor {
  main: string;
  light: string;
}

interface InvestmentColors {
  funds: InvestmentColor;
  structures: InvestmentColor;
  insurances: InvestmentColor;
  'corporate-financing': InvestmentColor;
}
interface ContentColors {
  article: string;
  video: string;
  newsletter: string;
  podcast: string;
}

declare module '@material-ui/core/styles/createPalette' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Palette {
    orange: Palette['primary'];
    pink: Palette['primary'];
    lightBlue: Palette['primary'];
    green: Palette['primary'];
    white: string;
    investment: InvestmentColors;
    content: ContentColors;
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface PaletteOptions {
    orange: PaletteOptions['primary'];
    pink: PaletteOptions['primary'];
    lightBlue: PaletteOptions['primary'];
    green: PaletteOptions['primary'];
    white: string;
    investment: InvestmentColors;
    content: ContentColors;
  }
}

declare module '@material-ui/core/styles/createBreakpoints' {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    smPlus: true;
    md: true;
    lg: true;
    xl: true;
    tablet: true;
    tabletPlus: true;
    wide: true;
    widePlus: true;
  }
}

const theme = createTheme({
  palette: {
    content: {
      article: '#FF6063',
      video: '#7AB8FF',
      newsletter: '#FF992A',
      podcast: '#FFB6B6',
    },
    investment: {
      funds: { main: '#7AB8FF', light: '#AFEAFF' },
      structures: { main: '#FF992A', light: '#FFCA5D' },
      insurances: { main: '#FFB6B6', light: '#FFE9E8' },
      'corporate-financing': { main: '#449F6B', light: '#87DBB1' },
    },
    primary: {
      main: '#000450', // Blue
      light: '#002388', // Blue highlight
      dark: '#000029', // Blue shadow
      contrastText: '#FF6063',
    },
    secondary: {
      main: '#FF6063', // Red
      light: '#FF938D', // Red highlight
      dark: '#C62936', // Red shadow
      contrastText: '#000450',
    },
    info: {
      main: '#FFB6B6',
      contrastText: '#000450',
    },
    text: {
      primary: '#000450',
      secondary: '#FF6063',
    },
    orange: {
      main: '#FF992A', // Orange
      light: '#FFCA5D', // Orange highlight
      dark: '#C66A00', // Orange shadow
    },
    pink: {
      main: '#FFB6B6', // Pink
      light: '#FFE9E8', // Pink highlight
      dark: '#CB8686', // Pink shadow
    },
    lightBlue: {
      main: '#7AB8FF', // Light blue
      light: '#AFEAFF', // Light blue highlight
      dark: '#4288CC', // Light blue shadow
    },
    green: {
      main: '#007040', // Green
      light: '#449F6B', // Green blue highlight
      dark: '#004318', // Green blue shadow
    },
    grey: {
      '100': '#F7F7F7', // Grey highlight
      '200': '#EEEEEE', // Grey highlight II,
      '300': '#DDDDDD', // Grey midtone
      '400': '#CCCCCC', // Grey shadow,
      '500': '#BBBBBB', // Grey shadow II
      '600': '#777777', // Grey Dark
    },
    white: '#FFFFFF',
    background: {
      paper: '#FFFFFF',
      default: '#FFFFFF',
    },
  },
  typography: {
    fontFamily: ['Scto Grotesk A'].join(','),
    h1: {
      fontFamily: ['Saol Display'].join(','),
      fontSize: '5rem',
    },
    h2: {
      fontFamily: ['Saol Display'].join(','),
      fontSize: '3rem',
    },
    h3: {
      fontFamily: ['Saol Display'].join(','),
      fontSize: '22px',
    },
    h4: {
      fontFamily: ['Saol Display'].join(','),
      fontSize: '1.5rem',
    },
    subtitle1: {
      fontFamily: ['Scto Grotesk A'].join(','),
      fontWeight: 500,
      textTransform: 'uppercase',
    },
  },
  transitions: {
    easing: {
      sharp: 'cubic-bezier(0.4, 0, 0.6, 1)',
    },
    duration: {
      leavingScreen: 200,
    },
  },
  breakpoints: {
    keys: ['xs', 'sm', 'md', 'tablet', 'lg', 'wide', 'xl'],
    values: {
      xs: 0,
      sm: 600,
      smPlus: 720,
      md: 960,
      tablet: 1024,
      tabletPlus: 1144,
      lg: 1280,
      wide: 1500,
      widePlus: 1620,
      xl: 1920,
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': AlexandriaFonts,
        '.duotone': {
          position: 'relative',
          '& img.duotone-img': {
            mixBlendMode: 'lighten',
            filter: 'grayscale(1)',
          },
        },
        '.duotone::before': {
          backgroundColor: '#000450',
          content: '""',
          display: 'block',
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
        },
        '.duotone::after': {
          backgroundColor: '#FF6063',
          content: '""',
          display: 'block',
          width: '100%',
          height: '100%',
          mixBlendMode: 'darken',
          position: 'absolute',
          top: 0,
          left: 0,
        },
        '.duotone.red::before': {
          backgroundColor: '#FF6063',
        },
        '.duotone.red::after': {
          backgroundColor: '#FF938D',
        },
      },
    },
  },
});

//AOS-36
theme.typography.h1 = {
  ...theme.typography.h1,
  overflowWrap: 'anywhere',
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.9rem',
  },
};

export default responsiveFontSizes(theme);
