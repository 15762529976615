import React from 'react';
import clsx from 'clsx';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { Link } from 'react-router-dom';

import useStyles from './styles';

interface INavItem {
  selected: boolean;
  selectedInPath?: boolean;
  open?: boolean;
  to: string;
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onIconClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  label: string;
  drawer?: boolean;
  submenu?: boolean;
  icon?: React.ReactElement;
}

const NavItem = (props: INavItem): React.ReactElement => {
  const classes = useStyles();
  const {
    label,
    to,
    onClick,
    onIconClick,
    selected,
    selectedInPath,
    open,
    drawer,
    submenu,
    icon,
  } = props;
  return (
    <ListItem
      className={clsx(
        classes['nav-item'],
        drawer && 'drawer',
        selected && 'selected',
        selectedInPath && 'selected-path',
        open && 'open',
        submenu && 'submenu'
      )}
    >
      <span>
        <Link to={to} onClick={onClick}>
          {label}
        </Link>

        {icon && (
          <ListItemIcon className={'nav-icon'} onClick={onIconClick}>
            {props.icon}
          </ListItemIcon>
        )}
      </span>
    </ListItem>
  );
};

export default NavItem;
