import React, { ReactElement } from 'react';
import {
  Link,
  Typography,
  makeStyles,
} from '@material-ui/core';

import Button from '../../components/Button';

const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.secondary.main,
    fontSize: '16px',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  button: {
    '&:hover': {
      textDecoration: 'none',
    },
  },
  paper: {
    boxSizing: 'border-box',
    backgroundColor: 'white',
    position: 'absolute',
    top: '50%',
    left: '50%',
    maxWidth: '600px',
    transform: 'translate(-50%, -50%)',
    padding: '20px',
    borderRadius: '26px',
    outline: 'none',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

interface PasswordResetProps {
  initiallyOpen?: boolean;
  className?: string;
  onModalOpen: (content: React.ReactElement) => void;
}

const PasswordReset = ({
  className,
  onModalOpen,
}: PasswordResetProps): ReactElement => {
  const classes = useStyles();

  const modalContent = (
    <div className={classes.paper}>
      <Typography variant="h3">Unohditko salasanasi?</Typography>
      <Typography variant="body1">
        Siirry vahvan tunnistautumisen kautta muuttamaan kirjautumistietojasi.
      </Typography>
      <div style={{ marginTop: '32px' }}>
        <Link className={classes.button} component={Button} href="/auth/auth">
          Siirry tunnistautumiseen
        </Link>
      </div>
    </div>
  );

  return (
    <div className={className}>
      <Link
        onClick={() => onModalOpen(modalContent)}
        classes={{ root: classes.link }}
      >
        Oletko unohtanut salasanasi?
      </Link>
    </div>
  );
};

export default PasswordReset;
