import React from 'react';
import clsx from 'clsx';
import { Link, useTheme, useMediaQuery, Typography } from '@material-ui/core';

import Button from '../../../../components/Button';

import useStyles from '../styles';

const Info = (props: {
  onLoginClick: (v: boolean) => void;
  storageKey: string;
}) => {
  const theme = useTheme();
  const classes = useStyles();

  const matchesSM = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.sm)
  );

  return (
    <div className={classes['info-container']}>
      <div className="login-fields-title">
        {matchesSM ? (
          <>
            <Typography variant="h2">
              Aloita palvelun <i>käyttö</i>
            </Typography>
          </>
        ) : (
          <>
            <Typography variant="h2">Aloita palvelun</Typography>
            <Typography variant="h2">
              <i>käyttö</i>
            </Typography>
          </>
        )}
      </div>
      <div className={classes['login-fields-content']}>
        <Typography variant="body1">
          Ensimmäisellä kerralla sinua pyydetään tunnistautumaan
          mobiilivarmenteella tai verkkopankkitunnuksilla.
        </Typography>
      </div>
      <div
        className={classes['login-actions-container']}
        style={{ marginBottom: '68px' }}
      >
        <Link
          component={Button}
          className={clsx(classes['info-buttons'], 'opposite')}
          href="/auth/auth"
        >
          Luo tunnukset
        </Link>
      </div>
      <div className={classes['login-fields-content']}>
        <Typography
          variant="body1"
          style={{
            textTransform: 'uppercase',
            letterSpacing: '1.6px',
            fontWeight: 500,
          }}
        >
          Olen jo luonut tunnukset
        </Typography>
      </div>
      <div className={classes['login-actions-container']}>
        <Button
          className={classes['info-buttons']}
          onClick={() => {
            window.localStorage.setItem(props.storageKey, 'true');
            props.onLoginClick(true);
          }}
        >
          Kirjaudu sisään
        </Button>
      </div>
    </div>
  );
};

export default Info;
