import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';
import {
  createMigrate,
  MigrationManifest,
  PersistConfig,
  PersistedState,
  persistReducer,
} from 'redux-persist';

import { useDispatch, useSelector } from 'react-redux';
import type { TypedUseSelectorHook } from 'react-redux';

import sessionStorage from 'redux-persist/lib/storage/session';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

import { Logout, ChangeUser } from './base';

// Reducers
import { uiReducer } from './ui/reducers';
import { calendarReducer } from './calendar/reducers';
import { userReducer } from './user/reducers';
import { activityReducer } from './activity/reducers';
import { contentReducer } from './content/reducers';
import { loadedReducer } from './loaded/reducers';
import { dataUpdatesReducer } from './dataUpdates/reducers';
import { adminReducer } from './admin/reducers';
import reportsReducer from './reports/reportsSlice';

import { reducers as investmentsReducer } from './investments';

export function logoutAction(): Logout {
  return {
    type: 'LOGOUT',
  };
}

export function changeUserAction(): ChangeUser {
  return {
    type: 'CHANGE_USER',
  };
}

/*
  NOTE! When you modify the state structure, you should increase
  the persistConfig's version and set the key of the object below to the same version.

  This is because when redux-persist's persisted state in session storage is loaded in the app start,
  the version numbers are compared and if they are the same, the persisted state is put into use as is,
  even if the current state model is different. This may cause all kinds of problems when the state 
  is used by the application. So by increasing the version number here, the migration is run on the persisted 
  state in the browser before using it, because the version number stored in localstorage is going 
  to be smaller.  

  The simple migration below just empties the state because we don't have anything in the state that 
  needs to be kept, and redux-persist's createMigrate doesn't work well with typescript, so more 
  complicated migrations need patching to the package and/or complicated typescript helper types.

  Unfortunately migrations don't work backwards, so in development you may need to clean session storage
  if you get errors when changing to a lower version.

  TL;DR: Increase the variable `stateVersion` below by one when the state model changes
*/
const stateVersion = 6;

const migrations: MigrationManifest = {
  [stateVersion]: (state: PersistedState): PersistedState => {
    return {
      _persist: state?._persist || { version: 0, rehydrated: false },
    };
  },
};

const persistConfig: PersistConfig<ReturnType<typeof rootReducer>> = {
  key: 'root',
  storage: sessionStorage,
  blacklist: ['activity', 'ui', 'user', 'loaded', 'dataUpdates', 'admin'],
  version: stateVersion,
  stateReconciler: autoMergeLevel2,
  migrate: createMigrate(migrations, { debug: true }),
};

const uiPersistConfig: PersistConfig<ReturnType<typeof uiReducer>> = {
  key: 'ui',
  storage: sessionStorage,
  blacklist: ['isLoading', 'viewedUser', 'pageScrolledDown'],
};

const persistedUiReducer = persistReducer(uiPersistConfig, uiReducer);
const rootReducer = combineReducers({
  ui: persistedUiReducer,
  calendar: calendarReducer,
  user: userReducer,
  activity: activityReducer,
  investments: investmentsReducer,
  content: contentReducer,
  loaded: loadedReducer,
  dataUpdates: dataUpdatesReducer,
  admin: adminReducer,
  reports: reportsReducer,
});

const persistedRootReducer = persistReducer(persistConfig, rootReducer);

const defaultMiddlewareConfig = {
  serializableCheck: false,
  immutableStateInvariant: false,
  thunk: false,
  actionCreatorInvariant: false,
};

export const store = configureStore({
  reducer: persistedRootReducer,
  middleware: (getDefaultMiddleware) => {
    const middleware = getDefaultMiddleware(defaultMiddlewareConfig);
    if (process.env.NODE_ENV === 'development') {
      middleware.prepend(createLogger());
    }
    return [createLogger()];
  },
  devTools: process.env.NODE_ENV === 'development',
});
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
