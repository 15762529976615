import firebase from 'firebase/app';
import { store } from '../../store';
import { setDataUpdates } from '../../store/dataUpdates/actions';
import { DataUpdates } from '../../types/firestore';
import { DataSource, DataSourceUpdateType } from '../dataSource';
import { DataSubscription } from '../dataSubscription';

export class DataUpdatesDataSource extends DataSource {
  name: DataSourceUpdateType = 'data-updates';
  subscribe(uid: string, ownUuid: string, db: firebase.firestore.Firestore): DataSubscription {
    const docs = db.collection('dataUpdates').doc(uid);

    const unsubscribe = docs.onSnapshot(
      (snapshot) => {
        console.log('Received dataUpdates snapshot');
        if (snapshot.exists) {
          const data = snapshot.data() as DataUpdates;
          store.dispatch(setDataUpdates(data))
        }
      },
      (err) => {
        console.log(`Encountered error: ${err}`);
      }
    );

    return new DataSubscription(this.name, unsubscribe);
  }
}
