import React, { useEffect } from 'react';
import firebase from 'firebase/app';
import {
  OfficesDataSource,
  AdvisorsDataSource,
} from '../../data-loader/dataSources';

import Form from './Form';
import Notification from '../../components/Notification';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

const CalendarAnonymous = (): React.ReactElement => {
  const notification = useSelector((state: RootState) => state.ui.notification);

  useEffect(() => {
    const db = firebase.firestore();
    const offices = new OfficesDataSource().subscribe('', '', db);
    const advisors = new AdvisorsDataSource().subscribe('', '', db);

    return () => {
      offices.unsubscribe(true);
      advisors.unsubscribe(true);
    };
  }, []);

  return (
    <>
      <Form anonymous={true} />
      <Notification
        open={notification.open}
        message={notification.message}
        variant={notification.type}
      />
    </>
  );
};

export default CalendarAnonymous;
