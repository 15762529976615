import { Reducer } from '@reduxjs/toolkit';
import { State, Actions, ActionTypes as actions } from './types';

const INITIAL_STATE: State = {};

export const userReducer: Reducer<State, Actions> = (
  state: State = INITIAL_STATE,
  action: Actions
): State => {
  switch (action.type) {
    case actions.SET_USER:
      return {
        ...state,
        user: action.payload,
      };
    case actions.SET_FS_USER:
      return {
        ...state,
        fsUser: action.payload,
      };
    case actions.SET_ACCESS_TOKEN:
      return {
        ...state,
        accessToken: action.payload,
      };
    case actions.SET_CONTROLLABLE_USERS: {
      return {
        ...state,
        controllableUsers: action.payload,
      };
    }
    case actions.SET_USERS_ADVISOR: {
      return {
        ...state,
        advisor: action.payload,
      };
    }
    case 'CHANGE_USER': {
      return {
        ...state,
        advisor: undefined,
      };
    }
    case actions.CLEAR_USER:
    case 'LOGOUT':
      return INITIAL_STATE;
    default:
      return state;
  }
};
