import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setViewedUser, setSearchFocused } from '../../../store/ui/actions';
import { changeUserAction, useAppSelector } from '../../../store';
import InputAdornment from '@material-ui/core/InputAdornment';
import useAutocomplete, {
  AutocompleteChangeReason,
} from '@material-ui/lab/useAutocomplete';
import { matchSorter } from 'match-sorter';
import { VariableSizeList, ListChildComponentProps } from 'react-window';
import clsx from 'clsx';

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from '../../TextField';
import { ReactComponent as SearchIcon } from '../../../images/svg/search.svg';
import { RootState } from '../../../store';
import { FSUser } from '../../../types/firestore';
import { ViewableUser } from '../../../types/local';

import useStyles from './styles';
import { searchControllableUsers } from '../../../utils/firebase';
import { groups } from '../../../utils/common';
import {
  createLogInfoData,
  handleAbcLogAction,
} from '../../../utils/abcLogger';

interface UserSearchUserData {
  uid: string;
  user: FSUser;
}

export interface UserSearchProps {
  users: UserSearchUserData[];
  onChange: (value: ViewableUser | null) => void;
  admin?: boolean;
}

function getTextWidth(text: string): number {
  // re-use canvas object for better performance
  const canvas =
    (getTextWidth as any).canvas ||
    ((getTextWidth as any).canvas = document.createElement('canvas'));
  const context = canvas.getContext('2d');
  context.font = 'bold 1rem Scto Grotesk A';
  const metrics = context.measureText(text);
  return metrics.width;
}

const parseCssValue = (value: string | number | undefined) => {
  return parseFloat(value ? value.toString() : '0');
};

/**
 * Component that can be used to search for other users that the current user has access to.
 * Users with Extranetadmin-group can search everybody.
 * If user has no access to other users, this renders nothing.
 */
const UserSearch = (): React.ReactElement | null => {
  const dispatch = useDispatch();
  const user = useAppSelector((state) => state.user);
  const userIsAdmin = useAppSelector(
    (state) => state.user.fsUser?.groups?.includes(groups.admin) || false
  );
  const fsUser = useAppSelector((state) => state.user.fsUser);
  const signInTime = useAppSelector(
    (state) => state.user.user?.metadata.lastSignInTime
  );

  if (
    (user.controllableUsers && user.controllableUsers?.length > 0) ||
    userIsAdmin
  ) {
    const handleUserChange = async (value: ViewableUser | null) => {
      dispatch(changeUserAction());
      const response: any = dispatch(setViewedUser(value));

      response.status = response !== null ? 200 : 400;
      response.statusText = response !== null ? 'OK' : 'Set viewed user failed';

      const logInfo = await createLogInfoData(
        response,
        'Impersonate',
        'Impersonate',
        response?.payload.user?.asiakasnumero,
        response?.payload.user?.hetu,
        fsUser?.email,
        fsUser?.asiakasnumero,
        fsUser?.hetu,
        signInTime
      );
      handleAbcLogAction(logInfo);
    };

    return (
      <UserSearchView
        users={user.controllableUsers || []}
        onChange={handleUserChange}
        admin={userIsAdmin}
      />
    );
  } else return null;
};

export const getTitleForUser = (viewedUser: ViewableUser): string => {
  if (viewedUser.user.etunimet && viewedUser.user.sukunimi) {
    return `${viewedUser.user.etunimet} ${viewedUser.user.sukunimi}`;
  }
  // Corporate accounts seem to have the name in `sukunimi` field
  return viewedUser.user.sukunimi || viewedUser.user.email || '?';
};

export const getHetuForUser = (viewedUser: ViewableUser): string => {
  return viewedUser.user.hetu || '';
};

/**
 * This component it the view part and doesn't handle data itself.
 * This way the view can be easily tested and developed in storybook,
 * but the component that is used in the site (`UserSearch`) still
 * handles the data it needs automatically.
 * @param param0
 */
export const UserSearchView = ({
  users,
  onChange,
  admin,
}: UserSearchProps): React.ReactElement => {
  const [options, setOptions] = useState<UserSearchUserData[]>([]);
  const [inputValue, setInputValue] = useState('');

  const {
    getRootProps,
    getInputProps,
    getOptionProps,
    getListboxProps,
    groupedOptions,
    focused,
  } = useAutocomplete({
    id: 'user-search',
    options: options,
    getOptionLabel: getTitleForUser,
    onChange: (
      e: React.ChangeEvent<Record<string, unknown>>,
      value: ViewableUser | null,
      reason: AutocompleteChangeReason
    ) => {
      switch (reason) {
        case 'select-option':
          onChange(value);
          break;
        default:
          break;
      }
    },
    onInputChange: (_: unknown, newValue: string) => {
      setInputValue(newValue);
    },
    filterOptions: (options, { inputValue }) =>
      admin // If admin, don't filter because we are searching from firestore
        ? options
        : matchSorter(options, inputValue, {
            keys: [
              'user.hetu',
              'user.email',
              'user.kokonimi',
              'user.etunimet',
              'user.sukunimi',
            ],
          }),
    getOptionSelected: (option, value) => option.uid === value.uid,
    debug: process.env.NODE_ENV === 'development',
  });

  const inputRef = React.useRef<HTMLInputElement>(null);
  const classes = useStyles();
  const dispatch = useDispatch();
  const isSearchFocused = useSelector(
    (state: RootState) => state.ui.isSearchFocused
  );

  useEffect(() => {
    if (admin) {
      if (inputValue !== '') {
        if (inputValue.length > 1) {
          searchControllableUsers(inputValue).then((newUsers) => {
            setOptions(newUsers);
          });
        }
      } else {
        setOptions([]);
      }
    } else if (users && users.length > 0) {
      setOptions(users);
    }
  }, [inputValue, users]);

  const renderRow = ({ index, style, data }: ListChildComponentProps) => {
    const _users = data as ViewableUser[];
    return (
      <ListItem
        button
        style={{
          ...style,
          top: `${parseCssValue(style.top) + 16}px`,
          left: `${parseCssValue(style.left) + 16}px`,
          width: 'calc(100% - 32px)',
          transition: 'none',
        }}
        key={index}
        classes={{ root: classes.item }}
        {...getOptionProps({ option: _users[index], index })}
        tabIndex={0}
        aria-label="Hakutulos"
      >
        <ListItemText
          primary={getTitleForUser(_users[index])}
          secondary={getHetuForUser(_users[index])}
          classes={{
            primary: classes.itemTextPrimary,
            secondary: classes.itemTextSecondary,
          }}
        />
      </ListItem>
    );
  };

  const calculateItemHeight = (index: number): number => {
    const userData = groupedOptions[index];

    const titleWidth = getTextWidth(getTitleForUser(userData));

    const maxAllowedWidth = 170;

    const rowHeight = 16;

    const baseHeight = 4 * rowHeight;

    const neededRows = Math.ceil(titleWidth / maxAllowedWidth);

    if (neededRows === 1) return baseHeight;
    return baseHeight + (neededRows + 1) * rowHeight;
  };

  React.useEffect(() => {
    if (isSearchFocused !== focused) dispatch(setSearchFocused(focused));
  }, [focused]);

  return (
    <div
      {...getRootProps()}
      className={clsx(classes.container, {
        focused: isSearchFocused,
      })}
    >
      <TextField
        inputRef={inputRef}
        className={clsx(classes.textField)}
        placeholder="Vaihda asiointiroolia"
        type="search"
        endAdornment={
          <InputAdornment position="end">
            <SearchIcon
              onClick={() => {
                inputRef.current?.focus();
              }}
            />
          </InputAdornment>
        }
        {...getInputProps()}
      />
      {groupedOptions.length > 0 && (
        <div className={classes['list-container']} {...getListboxProps()}>
          <VariableSizeList
            height={Math.min(500, groupedOptions.length * 64 + 32)}
            width={'100%'}
            itemSize={calculateItemHeight}
            itemCount={groupedOptions.length}
            itemData={groupedOptions}
            className={classes.list}
          >
            {renderRow}
          </VariableSizeList>
        </div>
      )}
    </div>
  );
};

export default UserSearch;
