import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  buttons: {
    display: 'flex',
    gap: '5px',
    [theme.breakpoints.down('xs')]: {
      gap: '3px',
    },
  },
  button: {
    background: 'rgba(255, 182, 182, 0.2)',
    borderRadius: '40px',
    border: 'none',
    textTransform: 'none',
    height: '36px',
    padding: '8px 12px',
    [theme.breakpoints.up('sm')]: {
      minWidth: '135px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
      padding: '6px',
    },
    [theme.breakpoints.down(400)]: {
      fontSize: '13px',
    },
    [theme.breakpoints.down(320)]: {
      fontSize: '12px',
    },

    '&.active': {
      background: theme.palette.pink.main,
    },

    '&:hover': {
      background: 'rgba(255, 182, 182, 0.4)',
    },
  },
}));

export default useStyles;
