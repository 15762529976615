import { Typography } from '@material-ui/core';
import React from 'react';
import { useDataLoader } from '../../data-loader';

import Page from '../Page';

import Form from './Form';
import Instructions from './Instructions';
import Header from './Header';

import useStyles from './styles';
import Notification from '../../components/Notification';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

const ContactUs = (props: { anonymous?: boolean }): React.ReactElement => {
  const notification = useSelector((state: RootState) => state.ui.notification);

  useDataLoader('offices', 'advisors');
  const classes = useStyles();

  return props.anonymous ? (
    <div className={classes['anonymous-page']}>
      <Header />
      <div className="content">
        <Typography variant="h1">Ota meihin yhteyttä</Typography>
        <div className={classes.container}>
          <Form anonymous={props.anonymous} />
          <Instructions />
        </div>
      </div>
      <Notification
        open={notification.open}
        message={notification.message}
        variant={notification.type}
      />
    </div>
  ) : (
    <Page title={<>Ota meihin yhteyttä</>}>
      <div className={classes.container}>
        <Form />
        <Instructions />
      </div>
    </Page>
  );
};

export default ContactUs;
