import React, { useContext, useEffect } from 'react';
import firebase from 'firebase/app';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

import { DataSubscription } from '../../data-loader/dataSubscription';
import { GlobalContentDataSource } from '../../data-loader/dataSources';
import { MiddleContext } from '../../layouts/Middle';
import Article from '../Content/Article';
import LoadingIndicator from '../../components/LoadingIndicator';

const Accessibility = (): React.ReactElement => {
  const accessibilityPolicy = useSelector(
    (state: RootState) => state.content.globalContent?.accessibilityPage
  );

  const { anonymous } = useContext(MiddleContext);
  useEffect(() => {
    let globalContent = null as null | DataSubscription;
    if (anonymous) {
      const db = firebase.firestore();
      globalContent = new GlobalContentDataSource().subscribe('', '', db);
    }
    return () => {
      if (globalContent) globalContent.unsubscribe(true);
    };
  }, []);

  return accessibilityPolicy ? (
    <Article page={accessibilityPolicy} />
  ) : (
    <LoadingIndicator variant="medium" />
  );
};

export default Accessibility;
