import { makeStyles } from '@material-ui/core/styles';
import DecorationMeeting from '../../images/svg/bubble-decoration-down-meeting.svg';
import DecorationNews from '../../images/svg/bubble-decoration-down-news.svg';
const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: '50px',
    [theme.breakpoints.down('lg')]: {
      marginBottom: '40px',
    },
    [theme.breakpoints.down('md')]: {
      marginBottom: '30px',
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: '20px',
    },
  },
  'item-root': {
    '&:after': {
      content: '""',
      display: 'block',
      width: '3rem',
      height: '1.5rem',
      marginRight: 'auto',
      marginLeft: '4rem',
      backgroundRepeat: 'no-repeat',
    },
    '&.news': {
      '&:after': {
        backgroundImage: `url(${DecorationNews})`,
      },
    },
    '&.meeting': {
      '&:after': {
        backgroundImage: `url(${DecorationMeeting})`,
      },
    },
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  'content-container': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '322px',
    [theme.breakpoints.down(400)]: {
      width: '100%',
    },
  },
  content: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.primary,
    padding: '32px',
    [theme.breakpoints.down('lg')]: {
      padding: '40px',
    },
    [theme.breakpoints.down('md')]: {
      padding: '30px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '15px',
    },
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    borderRadius: '23px 0px 0px 23px',
    '&.funds': {
      backgroundColor: theme.palette.secondary.main,
      '& .header': {
        color: theme.palette.white,
      },
      '& .body': {
        color: theme.palette.white,
      },
    },
    '&.milestone': {
      backgroundColor: theme.palette.orange.main,
      '& .header': {
        color: 'white',
      },
    },
    '&.meeting': {
      backgroundColor: theme.palette.pink.main,
    },
    '&.news': {
      backgroundColor: theme.palette.lightBlue.main,
    },
  },
  header: {
    color: theme.palette.white,
    textTransform: 'uppercase',
  },
  button: {
    display: 'flex',
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.secondary,
    alignItems: 'center',
    cursor: 'pointer',
    borderRadius: '0px 23px 23px 0px',
    width: '32px',
    '&.news': {
      backgroundColor: theme.palette.lightBlue.light,
    },
    '&.funds': {
      backgroundColor: theme.palette.secondary.light,
    },
    '&.milestone': {
      backgroundColor: theme.palette.orange.light,
    },
    '&.meeting': {
      backgroundColor: theme.palette.pink.light,
    },
    '& a': {
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
    },
  },
}));

export default useStyles;
