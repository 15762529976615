import { ActionTypes, Actions, StructuresState } from './types';

export function setStructuresPositions(positions: StructuresState['positions']): Actions {
  return {
    type: ActionTypes.SET_OVERVIEW,
    payload: positions,
  };
}

export function setStructuresTransactions(transactions: StructuresState['transactions']): Actions {
  return {
    type: ActionTypes.SET_TRANSACTION,
    payload: transactions,
  };
}

export function setStructuresReports(report: StructuresState['reports']): Actions {
  return {
    type: ActionTypes.SET_REPORT,
    payload: report,
  };
}

export function clearStructures(): Actions {
  return {
    type: ActionTypes.CLEAR_STRUCTURES,
  };
}

export function setStructuresSecurities(
  securities: StructuresState['securities']
): Actions {
  return {
    type: ActionTypes.SET_SECURITY,
    payload: securities,
  };
}

export function addStructuresSecurities(
  securities: StructuresState['securities']
): Actions {
  return {
    type: ActionTypes.ADD_SECURITY,
    payload: securities,
  };
}

export function setStructuresSecurityInfo(
  info: StructuresState['securityInfo']
): Actions {
  return {
    type: ActionTypes.SET_STRUCTURES_SECURITY_INFO,
    payload: info,
  };
}

export function addStructuresSecurityInfo(
  info: StructuresState['securityInfo']
): Actions {
  return {
    type: ActionTypes.ADD_STRUCTURES_SECURITY_INFO,
    payload: info,
  };
}
