import React from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import List from '@material-ui/core/List';
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';

import firebase from 'firebase/app';

import { RootState } from '../../../store';

import Avatar from '../../Avatar';

import { setNavigation } from '../../../store/ui/actions';

import useStyles from './styles';

import logoutUrl from '../../../images/svg/log_out.svg';

const Vertical = (props: {
  children: React.ReactElement[];
}): React.ReactElement => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user.user);

  const open = useSelector((state: RootState) => state.ui.isNavigationOpen);
  const toggleDrawer = (openState: boolean): void => {
    dispatch(setNavigation(openState));
  };

  const history = useHistory();
  return (
    <div className={clsx(classes.drawer, { 'nav-open': open })}>
      <div className={classes.top}>
        <Link
          component={RouterLink}
          to="/omat_tiedot"
          className={classes.omat_tiedot}
        >
          <Avatar photoUrl={user?.photoURL} />
        </Link>
        <Link
          component="button"
          className={classes.signout}
          onClick={() => {
            toggleDrawer(false);
            history.replace('/');
            firebase.auth().signOut();
          }}
        >
          <img src={logoutUrl} alt="" />
          Kirjaudu ulos
        </Link>
      </div>
      <div className={classes['menu-container']}>
        <List className={classes['drawer-ul']}>{props.children}</List>
      </div>
    </div>
  );
};

export default Vertical;
