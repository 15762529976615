import { Reducer } from '@reduxjs/toolkit';
import {
  StructuresState as State,
  Actions,
  ActionTypes as actions,
} from './types';

const INITIAL_STATE: State = {
  positions: null,
  transactions: null,
  reports: null,
  securities: null,
  securityInfo: null,
};

export const structuresReducer: Reducer<State, Actions> = (
  state: State = INITIAL_STATE,
  action: Actions
): State => {
  switch (action.type) {
    case actions.SET_OVERVIEW:
      return {
        ...state,
        positions: action.payload,
      };
    case actions.SET_TRANSACTION:
      return {
        ...state,
        transactions: action.payload,
      };
    case actions.SET_REPORT:
      return {
        ...state,
        reports: action.payload,
      };
    case actions.SET_SECURITY:
      return {
        ...state,
        securities: action.payload,
      };
    case actions.ADD_SECURITY: {
      const oldIds = state.securities?.map((v) => v.security.id) || [];
      const newSecurities = action.payload?.filter(
        (v) => !oldIds.includes(v.security.id)
      ) || [];
      return {
        ...state,
        securities: [...state.securities || [], ...newSecurities],
      };
    }
    case actions.SET_STRUCTURES_SECURITY_INFO:
      return {
        ...state,
        securityInfo: action.payload,
      };
    case actions.ADD_STRUCTURES_SECURITY_INFO: {
      const oldIds = state.securityInfo?.map((v) => v.sys.id) || [];
      const newSecurityInfo = action.payload?.filter(
        (v) => !oldIds.includes(v.sys.id)
      ) || [];
      return {
        ...state,
        securityInfo: [...state.securityInfo || [], ...newSecurityInfo],
      };
    }
    case actions.CLEAR_STRUCTURES:
    case 'LOGOUT':
    case 'CHANGE_USER':
      return INITIAL_STATE;
    default:
      return state;
  }
};
