import React, { useEffect } from 'react';
import firebase from 'firebase/app';
import {
  Switch,
  Route,
  Redirect,
  useLocation,
  useHistory,
} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { RootState } from '../store';
import MainLayout from '../layouts/Main';
import MiddleLayout from '../layouts/Middle';
import MainRoutes from './main';
import { Login } from '../views/Login';
import CalendarAnonymous from '../views/CalendarAnonymous';
import EmailHandler from '../views/EmailHandler';
import ContactUs from '../views/ContactUs';
import Loading from '../views/Loading';
import { selectTab, setNavigation, setRedirect } from '../store/ui/actions';

import { LocationState } from '../types/local';
import { setHash } from '../store/calendar/actions';

import GeneralRoute from './general';

const App = (): React.ReactElement => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const redirect = useSelector((state: RootState) => state.ui.redirect);

  useEffect(() => {
    if (redirect) {
      dispatch(setRedirect(null));
      history.replace(redirect);
    }
  }, [redirect]);

  useEffect(() => {
    dispatch(selectTab(`nav-item${location.pathname.replace(/\//g, '-')}`));
    dispatch(setNavigation(false));
  }, [location]);

  return (
    <MainLayout>
      <MainRoutes />
    </MainLayout>
  );
};

export const Routes = (): React.ReactElement => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state: RootState) => state.ui.isLoading);

  const userData = useSelector((state: RootState) => state.user.user);
  const userAuth = firebase.auth().currentUser;
  const user = userData && userAuth;

  const location = useLocation();
  const state = location.state as LocationState;
  if (state) {
    dispatch(setRedirect(state.from));
  }

  useEffect(() => {
    // Grab hash parameter from query and save it to state
    const hash = new URLSearchParams(location.search).get('hash');
    if (hash) {
      dispatch(setHash(hash));
    }
  }, [location]);

  return isLoading ? (
    <Loading />
  ) : (
    <Switch>
      <Route exact path="/ajan_varaus">
        <Redirect to="/ajanvaraus" />
      </Route>
      <Route exact path="/ajanvaraus">
        <CalendarAnonymous />
      </Route>
      <Route exact path="/vahvistus">
        <EmailHandler />
      </Route>
      <Route exact path="/yhteydenotto">
        <ContactUs anonymous={true} />
      </Route>
      {!user && (
        <Route path="/yleista">
          <MiddleLayout anonymous>
            <GeneralRoute />
          </MiddleLayout>
        </Route>
      )}
      <Route exact path="/login">
        {user ? <Redirect to="/" /> : <Login />}
      </Route>
      {!user ? (
        <Redirect to={{ pathname: '/login', state: { from: location } }} />
      ) : (
        <Route path="/*" component={App} />
      )}
    </Switch>
  );
};

export default Routes;
