import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  dates: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  datesRow: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(100px, 0.5fr))',
    width: '100%',
    height: '50px',
    columnGap: '5px',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      height: 'auto',
      minHeight: '50px',
    },
  },
  dateButton: {
    background: 'rgba(122, 184, 255, 0.4)',
    borderRadius: '30px',
    border: 'none',
    textTransform: 'none',
    height: '45px',
    flexGrow: 1,
    marginBottom: '5px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    '&.active': {
      background: theme.palette.lightBlue.main,
    },
    '&.disabled': {
      display: 'none',
      pointerEvents: 'none',
    },
    '&:hover': {
      background: 'rgba(122, 184, 255, 0.6)',
    },
    '& span': {
      display: 'flex',
      flexDirection: 'column',
    }
  },
  textContainer: {
    display: 'inline-flex',
    alignItems: 'center',
    width: '100%',
  },
  fieldTitle: {
    textTransform: 'uppercase',
    fontWeight: 500,
    fontSize: '14px',
    margin: '8px 5px 12px 0',
  },
  weekButton: {
    fontWeight: 400,
    fontSize: '16px',
  },
  line: {
    flexGrow: 1,
    border: '0.5px dashed' + theme.palette.primary.main,
  },
  date: {
    fontFamily: 'Saol Display',
    fontWeight: 600,
    fontSize: '22px',
    lineHeight: '24px',
  },
  weekday: {
    fontFamily: 'Scto Grotesk A',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '17px',
  },
  button: {
    padding: 0,
    margin: '8px 0 0 auto',
    textTransform: 'none',
    fontSize: '16px',
    '&.backward': {
      margin: '8px auto 0 0',
    },
    '& span': {
      display: 'flex',
      gap: '7.5px',
    },
  },
  arrows: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
  check: {
    position: 'absolute',
    display: 'flex',
    top: '-3px',
    right: '-4px',
    width: '24px',
    height: '24px',
    borderRadius: '50%',
    background: theme.palette.primary.main,
    justifyContent: 'center',
    alignItems: 'center',
  },
  checkIcon: {
    fill: 'none',
    stroke: theme.palette.white,
    strokeWidth: '1px',
    width: '10.35px',
    height: '7.2px',
  },
}));

export default useStyles;
