/**
 * Firebase & features
 */
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import { store } from '../store';
import { setAccessToken } from '../store/user/actions';
import { FSUser } from '../types/firestore';
import { updateGroups } from './api';
import { groups } from './common';

export const getErrorMessage = (errorCode: string) => {
  switch (errorCode) {
    case 'auth/user-not-found':
    case 'auth/wrong-password':
    case 'auth/user-disabled':
      return 'Virheellinen käyttäjätunnus tai salasana.';
    case 'auth/too-many-requests':
      return 'Liian monta virheellistä kirjautumisyritystä. Yritä hetken päästä uudelleen.';
    default:
      return undefined;
  }
};

export const passwordSignIn = async (
  username: string,
  password: string
): Promise<{ response: Response; result: firebase.auth.UserCredential }> => {
  return new Promise((resolve, reject) => {
    const signOut = firebase.auth().signOut();
    const setPersistence = firebase
      .auth()
      .setPersistence(firebase.auth.Auth.Persistence.SESSION);
    const signIn = firebase
      .auth()
      .signInWithEmailAndPassword(username, password)
      .then((result) => {
        resolve({
          response: new Response(
            JSON.stringify({ status: 200, statusText: 'OK' })
          ),
          result: result,
        });
      })

      .catch((error) => {
        reject({
          status: error.code || error.message,
          response: error,
          statusText: 'Error',
        });
      });
    signOut.then(() => setPersistence).then(() => signIn);
  });
};

/**
 *
 * @param username used as login_hint
 */
export const azureSignin = async (
  username: string
): Promise<{ response: Response; result: firebase.auth.UserCredential }> => {
  return new Promise((resolve, reject) => {
    const signOut = firebase.auth().signOut();
    const provider = new firebase.auth.OAuthProvider('microsoft.com');
    provider.setCustomParameters({
      tenant: process.env.REACT_APP_AZURE_TENANT,
      login_hint: username,
      prompt: 'login',
    });
    const setPersistence = firebase
      .auth()
      .setPersistence(firebase.auth.Auth.Persistence.SESSION);

    const signIn = firebase
      .auth()
      .signInWithPopup(provider)
      .then((result) => {
        if (result.user !== null && result.credential) {
          const accessToken =
            'accessToken' in result.credential
              ? result.credential['accessToken']
              : '';

          store.dispatch(setAccessToken(accessToken as string));
          resolve({
            response: new Response(
              JSON.stringify({ status: 200, statusText: 'OK' })
            ),
            result: result,
          });
        }
      })
      .catch((error) => {
        reject({
          status: error.code || error.message,
          response: error,
          statusText: 'Error',
        });
      });

    signOut.then(() => setPersistence).then(() => signIn);
  });
};

// export const subscribeToActivities = (
//   user: firebase.User | null,
//   onNext: (
//     snapshot: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
//   ) => void
// ): (() => void) | undefined => {
//   if (user) {
//     const db = firebase.firestore();
//     const docs = db
//       .collection('activities')
//       .doc(user.uid)
//       .collection('activity');
//     const observer = docs.onSnapshot(onNext, (err) => {
//       console.log(`Encountered error: ${err}`);
//     });
//     return observer;
//   }
// };

// export const subscribeToUser = (user: firebase.User | null, onNext: (
//   snapshot: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
// ) => void): (() => void) | undefined => {
//   if (user) {
//     const db = firebase.firestore();
//     const docs = db
//       .collection('users')
//       .doc(user.uid)
//     const observer = docs.onSnapshot(onNext, (err) => {
//       console.log(`Encountered error: ${err}`);
//     });
//     return observer;
//   }
// }

export const resetPassword = async (emailAddress: string): Promise<void> => {
  if (!emailAddress) {
    throw new Error('emailAddress empty');
  }

  const auth = firebase.auth();

  try {
    await auth.sendPasswordResetEmail(emailAddress);
  } catch (error: any) {
    throw new Error(error.message as string);
  }
};

export const getFileUrl = async (filePath: string): Promise<any> => {
  const storageRef = firebase.storage().ref();
  const fileRef = storageRef.child(filePath);
  try {
    return await fileRef.getDownloadURL();
  } catch (error: any) {
    throw new Error(error.message);
  }
};

export const getControllableUsers = async (
  user: FSUser
): Promise<{ uid: string; user: FSUser }[]> => {
  const users = await Promise.all(
    (user.accessToUsers || []).map(async (uid) => ({
      uid,
      user: (
        await firebase.firestore().collection('users').doc(uid).get()
      ).data() as FSUser,
    }))
  );
  return users;
};

/**
 * Search users from firestore's users-collection.
 * The users needs to have group: Extranetadmin or
 * have access to every user (which is not likely) for this to work.
 * @param query The query to search with
 */
export const searchControllableUsers = async (
  query: string
): Promise<{ uid: string; user: FSUser }[]> => {
  const result = await firebase
    .firestore()
    .collection('users')
    .where('keywords', 'array-contains', query.toLowerCase())
    .orderBy('sukunimi')
    .limit(10)
    .get();

  return result.docs
    .map((user) => ({
      uid: user.id,
      user: user.data() as FSUser,
    }))
    .filter((user) => !user.user.groups?.includes(groups.admin));
};

export const getViewedUser = async (
  uid: string
): Promise<{ uid: string; user: FSUser } | null> => {
  try {
    const result = await firebase
      .firestore()
      .collection('users')
      .doc(uid)
      .get();
    if (!result.exists) return null;
    return { uid: uid, user: result.data() as FSUser };
  } catch (e: any) {
    throw new Error(e);
  }
};

/**
 * Get the idToken from firebase/auth and refresh it if necessary
 */
export const getIdToken = async (): Promise<string | undefined> => {
  return firebase.auth().currentUser?.getIdToken();
};

// Expose getIdToken in development so the token
// can be used for local api testing
if (process.env.NODE_ENV === 'development') {
  (window as any).getIdToken = getIdToken;
}
