import { makeStyles } from '@material-ui/core/styles';

import { SpinnerProps } from './index';

const frames = Array(160)
  .fill(null)
  .reduce((prev: any, _curr, i) => {
    const n = i + 1;
    prev[`&:nth-child(${n})`] = {
      animationDelay: `${0 - 0.0025 * n}s`,
    };
    return prev;
  }, {});

const sizeUnit = 32; 

const useStyles = makeStyles(() => ({
  '@keyframes lds-roller': {
    '0%': {
      transform: 'rotate(90deg)',
    },
    '100%': {
      transform: 'rotate(810deg)',
    },
  },
  container: {
    position: 'relative',
    '&.large': {
      width: sizeUnit * 4,
      height: sizeUnit * 4,
    },
    '&.medium': {
      width: sizeUnit * 2,
      height: sizeUnit * 2,
    },
    '&.small': {
      width: sizeUnit,
      height: sizeUnit,
    },
    '& .lds-bg-ring': {
      position: 'absolute',
      borderRadius: '50%',
      '&.large': {
        width: sizeUnit * 4,
        height: sizeUnit * 4,
        border: '8px solid #000450',
      },
      '&.medium': {
        width: sizeUnit * 2,
        height: sizeUnit * 2,
        border: '4px solid #cccccc',
      },
      '&.small': {
        display: 'none',
      },
    },

    '& .lds-roller': {
      position: 'absolute',
      '&.large': {
        width: sizeUnit * 4,
        height: sizeUnit * 4,
        bottom: -sizeUnit * 2,
      },
      '&.medium': {
        width: sizeUnit * 2,
        height: sizeUnit * 2,
        bottom: -sizeUnit,
      },
      '&.small': {
        width: sizeUnit,
        height: sizeUnit,
        bottom: -sizeUnit / 2,
      },
      '& div': {
        animation: '$lds-roller 2s cubic-bezier(0.66, 0, 0.33, 1) infinite',
        transformOrigin: '50% 50%',
        '&:after': {
          content: '""',
          display: 'block',
          position: 'absolute',
          width: (props: SpinnerProps) => {
            if (props.variant === 'large') return '8px';
            else if (props.variant === 'medium') return '4px';
            else return '1px';
          },
          height: (props: SpinnerProps) => {
            if (props.variant === 'large') return '8px';
            else if (props.variant === 'medium') return '4px';
            else return '1px';
          },
          borderRadius: '50%',
          background: (props: SpinnerProps) => {
            if (props.color) return props.color;
            else if (props.variant === 'large') return '#ff6063';
            else return '#000450';
          },
        },
        ...frames,
      },
    },
  },
}));

export default useStyles;
