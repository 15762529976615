import { useSelector } from 'react-redux';
import firebase from 'firebase/app';
import { getViewedUser } from './firebase';
import { RootState, store, logoutAction } from '../store';
import validator from 'validator';
import { FSAdvisor, FSUser } from '../types/firestore';
import { updateGroups, updateRequest } from './api';
import { setFSUser, setUser, setUsersAdvisor } from '../store/user/actions';
import { getIdToken } from './firebase';
import { setLoading, setViewedUser } from '../store/ui/actions';
import { DataLoader } from '../data-loader';
import { useLogger } from './logger';

export const isUserInGroup = (user: FSUser, group: string): boolean => {
  return user.groups?.includes(group) || false;
};

export const useDisplayName = (): string | null | undefined => {
  const user = useSelector((state: RootState) => state.user.user);
  const name = user?.displayName || user?.email;
  return name && validator.isEmail(name)
    ? name
        .toLowerCase()
        .replace(/@.*$/, '')
        .split('.')
        .join(' ')
        .replace(/\w\S*/g, (w) => w.replace(/^\w/, (c) => c.toUpperCase()))
    : name;
};

export const useUserHasIncentiveClass = (): boolean => {
  const fsUser = useSelector((state: RootState) => state.user.fsUser);
  const viewedUser = useSelector((state: RootState) => state.ui.viewedUser);
  const activeUser = viewedUser?.user ?? fsUser;
  return (activeUser?.kannustinluokka ?? 0) !== 0;
};

export const useViewedUserId = (): string | null => {
  const viewedUser = useSelector((state: RootState) => state.ui.viewedUserUid);
  return viewedUser ? viewedUser : null;
};

export const useAdvisorView = (): boolean | undefined => {
  const user = useSelector((state: RootState) => state.user.user);
  const viewedUser = useSelector((state: RootState) => state.ui.viewedUserUid);

  return user?.email?.endsWith('@alexandria.fi') && !viewedUser;
};

/**
 * Does things that need to be done on login
 * @param firebaseUser The user gotten from login
 * @returns An unsubscribe function that unsubscribes from any subscriptions created for the user
 */
export const login = async (
  firebaseUser: firebase.User,
  accessToken: string
): Promise<() => void> => {
  const db = firebase.firestore();
  const token = await firebaseUser.getIdToken();
  const { uid, email } = firebaseUser;
  const loginLogger = useLogger('Auth');
  loginLogger.log('Logged in user', email);

  /**
   * User might have arrived via redirect (Azure AD)
   */

  if (accessToken) {
    updateGroups(token, accessToken as string);
  }

  store.dispatch(setUser(firebaseUser));
  store.dispatch(setLoading(false));

  // If viewedUserUid is set, we need to set the viewedUser,
  const viewedUserUid = store.getState().ui.viewedUserUid;
  if (viewedUserUid) {
    const viewedUser = await getViewedUser(viewedUserUid);
    if (viewedUser) {
      store.dispatch(setViewedUser(viewedUser));
    }
  }
  DataLoader.instance.initialize(
    db,
    firebaseUser.uid,
    viewedUserUid || undefined
  );

  // Subscribe to "global" data sources here
  DataLoader.instance.subscribeToDataSource('user-access-to-users');
  DataLoader.instance.subscribeToDataSource('global-content');
  DataLoader.instance.subscribeToDataSource('frontpage-content');
  DataLoader.instance.subscribeToDataSource('custom-pages-content');
  DataLoader.instance.subscribeToDataSource('activities-content');
  DataLoader.instance.subscribeToDataSource('data-updates');

  // Update users for advisors
  if (email?.endsWith('@alexandria.fi')) {
    updateRequest({ uid, target: 'advisor-users' }, token);
  } else {
    updateRequest({ uid, target: 'user-access-to-users' }, token);
  }

  const docs = db.collection('users').doc(uid);

  const unsubscribe = docs.onSnapshot(
    async (snapshot) => {
      console.log('Received user snapshot');
      const idToken = await getIdToken();

      if (!idToken) return;

      const fsUser = snapshot.data() as FSUser | undefined;
      if (fsUser) {
        store.dispatch(setFSUser(fsUser));

        if (fsUser.neuvonantajaID && !email?.endsWith('@alexandria.fi')) {
          //get user's personal advisor
          const advisorDoc = await db
            .collection('advisors')
            .doc(fsUser.neuvonantajaID.toString())
            .get();
          store.dispatch(setUsersAdvisor(advisorDoc.data() as FSAdvisor));
        }
      }
    },
    (err) => {
      loginLogger.error(`Encountered error: ${err}`);
    }
  );
  return unsubscribe;
};

/**
 * Does things that need to be done on logout
 */
export const logout = (): void => {
  useLogger('Auth').log('User logged out');
  store.dispatch(logoutAction());
  store.dispatch(setLoading(false));
  DataLoader.instance.logout();
};
