import React, { useEffect, useState } from 'react';
import { Button, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { format } from 'date-fns-tz';
import { Slot } from '../../../../types/abc';
import { Booking } from '../../../../types/local';
import { CalendarAction } from '../../../Calendar/Form/state';
import { RootState } from '../../../../store';
import { getIdToken } from '../../../../utils/firebase';
import { FormatTime, TZ } from '../../../../utils/date';
import { getSlotsByOfficeDate_v2 } from '../../../../utils/api';
import { useSelector } from 'react-redux';
import Spinner from '../../../../components/LoadingIndicator';

import { ReactComponent as Check } from '../../../../images/svg/check-nostyle.svg';

import useStyles from './styles';

const SelectTimeForAdvisor = (props: {
    state: Booking;
    dispatch: React.Dispatch<CalendarAction>;
  }): React.ReactElement => {
  const { state, dispatch } = props;
  const classes = useStyles();
  const [selectedTime, setSelectedTime] = useState<string>(state.time);
  const [slots, setSlots] = useState<Slot[]>([]);
  const [loading, setLoading] = useState(false);
  const user = useSelector((state: RootState) => state.user.fsUser);

  const handleTimeSelect = (advisorId: number, time: string) => {
    dispatch({ type: 'set-advisor', payload: advisorId });
    dispatch({ type: 'set-time', payload: time });
  };

  /*
  const resetSelectedAdvisor = (
    state: Booking,
    slots: Slot[],
    dispatch: React.Dispatch<CalendarAction>
  ) => {
    const availableAdvisorIds = slots.map((v) => v.NeuvonantajaID);
    if (state.advisorId && !availableAdvisorIds.includes(state.advisorId)) {
      // dispatch({ type: 'set-advisor', payload: undefined });
      dispatch({ type: 'set-time', payload: '' });
    }
  };
  */

  useEffect(() => {
    async function updateDates() {
      const idToken = await getIdToken();
      if (state.date) {
        setLoading(true);
        try {
          const response = await (
            await getSlotsByOfficeDate_v2(idToken, {
              office: -1,
              date: format(state.date, 'yyyy-MM-dd', {
                timeZone: TZ,
              }),
              advisor: state.advisorId,
              channel: state.channel,
            })
          ).json();
          setSlots(response);
          // resetSelectedAdvisor(state, response, dispatch);
          setLoading(false);
        } catch (e) {
          setLoading(false);
        }
      }
    }
    updateDates();
  }, [state.channel, state.date]);

  // Filter out duplicates by creating a Set and then converting it back to an array
  const uniqueTimeSlots = Array.from(new Set(slots.map((v) => v.Aika)));

  return !loading ? (
    <>
      {slots && slots.length > 0 && (
        <div className={classes.timesContainer}>
          <span className={classes.line} />
          <Typography variant="body1" className={classes.fieldTitle}>Valitse kellonaika</Typography>
          <div className={classes.times}>
          {uniqueTimeSlots.map((timeSlot) => {
              // Filter slots by the current timeSlot
              const slotsForTime = slots.filter((v) => v.Aika === timeSlot);
              const isActive = selectedTime === timeSlot;

              return (
                <Button
                  className={clsx(classes.timeButton, {
                    active: isActive,
                  })}
                  key={timeSlot}
                  onClick={(event) => {
                    event.stopPropagation();
                    setSelectedTime(timeSlot);
                    // You may need to find the corresponding Slot object here.
                    // This assumes that timeSlot is unique, so it will select the first slot with the matching time.
                    const matchingSlot = slotsForTime[0];
                    if (matchingSlot) {
                      handleTimeSelect(matchingSlot.NeuvonantajaID, timeSlot);
                    }
                  }}
                >
                  {FormatTime(timeSlot)}{' '}
                  {isActive && (
                    <span className={classes.check}>
                      <Check className={classes.checkIcon} />
                    </span>
                  )}
                </Button>
              );
            })}
          </div>
        </div>
      )}
    </>
  ) : (
    <Spinner variant="medium" />
  );
}

export default SelectTimeForAdvisor;
