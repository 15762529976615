import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

import { RootState } from './store';
import { DataLoader } from './data-loader';
import { login, logout } from './utils/user';

/**
 * themes and styles
 */
import theme from './theme';
import useStyles from './styles';

import AppLayout from './layouts/App';
import Content from './routes';
import {
  ActivitiesDataSource,
  FundsDataSource,
  FundsTransactionDataSource,
  InsurancesOverviewDataSource,
  InsurancesPositionDataSource,
  StructuresDataSource,
  StructuresTransactionDataSource,
  StructuresReportDataSource,
  AccessToUsersDataSource,
  CorporateFinancingBalanceDataSource,
  CorporateFinancingInvestmentsDataSource,
  ReportsDataSource,
  UserBookingsDataSource,
  ContentDataSource,
  ActivitiesContentDataSource,
  GlobalContentDataSource,
  FrontpageContentDataSource,
  ReviewContentDataSource,
  OfficesDataSource,
  AdvisorsDataSource,
  DataUpdatesDataSource,
  CorporateFinancingContentDataSource,
  CustomPagesDataSource,
  UtmostReportsDataSource,
} from './data-loader/dataSources';
import { useLogger } from './utils/logger';
import useInactivityLogout from './utils/useInactivityLogout';
import GoogleAnalyticsRouteTracker from './components/GoogleAnalyticsRouteTracker';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  projectId: process.env.REACT_APP_FIRESTORE_PROJECTID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

DataLoader.instance.addDataSources([
  new StructuresDataSource(),
  new StructuresTransactionDataSource(),
  new StructuresReportDataSource(),
  new FundsDataSource(),
  new FundsTransactionDataSource(),
  new InsurancesOverviewDataSource(),
  new InsurancesPositionDataSource(),
  new ActivitiesDataSource(),
  new CorporateFinancingBalanceDataSource(),
  new CorporateFinancingInvestmentsDataSource(),
  new ReportsDataSource(),
  new UserBookingsDataSource(),
  new ContentDataSource(),
  new ActivitiesContentDataSource(),
  new OfficesDataSource(),
  new AdvisorsDataSource(),
  new AccessToUsersDataSource(),
  new GlobalContentDataSource(),
  new FrontpageContentDataSource(),
  new ReviewContentDataSource(),
  new DataUpdatesDataSource(),
  new CorporateFinancingContentDataSource(),
  new CustomPagesDataSource(),
  new UtmostReportsDataSource(),
]);

export const App = (): React.ReactElement => {
  const classes = useStyles();
  const viewedUser = useSelector((state: RootState) => state.ui.viewedUser);
  const accessToken = useSelector((state: RootState) => state.user.accessToken);

  useInactivityLogout(1000 * 3600);
  const authLogger = useLogger('Auth');

  /**
   * Authentication subscription
   */
  useEffect(() => {
    let unsubscribeFromUser: () => void | undefined;
    async function onAuthChange(firebaseUser: firebase.User | null) {
      // User logged in
      if (firebaseUser) {
        unsubscribeFromUser = await login(firebaseUser, accessToken as string);
      } else {
        // User logged out
        unsubscribeFromUser && unsubscribeFromUser();
        logout();
      }
    }

    const unsubscribe = firebase.auth().onAuthStateChanged(onAuthChange);
    return () => {
      authLogger.log('Unsub from auth');
      if (unsubscribe) unsubscribe();
    };
  }, [accessToken]);

  useEffect(() => {
    if (viewedUser !== null) {
      DataLoader.instance.changeViewToUser(viewedUser.uid);
    } else {
      DataLoader.instance.clearViewedUser();
    }
  }, [viewedUser]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className={classes.app}>
        <AppLayout>
          <Router>
            <GoogleAnalyticsRouteTracker />
            <Content />
          </Router>
        </AppLayout>
      </div>
    </ThemeProvider>
  );
};
