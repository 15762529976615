import React from 'react';
import clsx from 'clsx';

import { Typography } from '@material-ui/core';
import { Button } from '../../Button';

import useStyles from '../../styles';
import { ContentItemProps } from '..';

export const Milestone = (props: ContentItemProps): React.ReactElement => {
  const classes = useStyles(props);
  const { title, body, header, linkTo } = props;
  return (
    <div className={classes['content-container']}>
      <div className={clsx(classes.content, 'milestone')}>
        <div className={clsx('header', classes.header)}>{header}</div>
        <div className="title">
          <Typography variant="h1">{title}</Typography>
        </div>
        <div className="body">{body}</div>
      </div>
      <div
        className={clsx(classes.button, 'milestone')}
        onClick={() => {
          console.log('clicked a feed item');
        }}
      >
        <Button to={linkTo} />
      </div>
    </div>
  );
};
