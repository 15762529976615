import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { setActivityFeed } from '../../../store/ui/actions';

import useStyles from './styles';
import arrowUrl from '../../../images/svg/right.svg';

export const Button = (props: { to: string }): React.ReactElement => {
  const classes = useStyles();
  const dispatch = useDispatch();
  return (
    <Link to={props.to} onClick={() => dispatch(setActivityFeed(false))}>
      <div className={classes.root}>
        <img src={arrowUrl} />
      </div>
    </Link>
  );
};
