import React, { useState } from 'react';
import clsx from 'clsx';
import { Button } from '@material-ui/core';

import { CalendarAction } from '../../../Calendar/Form/state';
import { BookingView } from '../../../../types/local';

import { ReactComponent as Timeline } from '../../../../images/svg/timeline.svg';
import { ReactComponent as Expert } from '../../../../images/svg/expert.svg';

import useStyles from './styles';

export const ChannelToLabel = (view: BookingView): string => {
  switch (view) {
    case BookingView.Date:
      return 'Valitse ajankohta';
    case BookingView.Expert:
      return 'Valitse asiantuntija';
    default:
      return '';
  }
};

const SelectView = (props: {
  value: BookingView;
  dispatch: React.Dispatch<CalendarAction>;
}): React.ReactElement => {
  const { value, dispatch } = props;
  const classes = useStyles();
  const [selectedView, setSelectedView] = useState<BookingView>(value);

  const handleChange = (view: BookingView) => {
    setSelectedView(view);
    dispatch({
      type: 'set-view',
      payload: view,
    });
  };

  return (
    <div
      aria-label="tapaamisen-näkymä"
      className={classes.buttons}
    >
      <Button
        className={clsx(classes.button, { active: selectedView === BookingView.Date })}
        onClick={() => handleChange(BookingView.Date)}
        disableRipple
      >
        <Timeline className="button-icon" />
        {ChannelToLabel(BookingView.Date)}
      </Button>
      <Button
        className={clsx(classes.button, { active: selectedView === BookingView.Expert })}
        onClick={() => handleChange(BookingView.Expert)}
        disableRipple
      >
        <Expert className="button-icon" />
        {ChannelToLabel(BookingView.Expert)}
      </Button>
    </div>
  );
};

export default SelectView;
