import { InsuranceOverview, InsurancePosition } from '../../../types/abc';
import { Insurance } from '../../../types/contentful';
import { ChangeUser, Logout } from '../../base';

export enum ActionTypes {
  SET_OVERVIEW = 'SET_INSURANCE_OVERVIEW',
  SET_POSITION = 'SET_INSURANCE_POSITION',
  CLEAR_INSURANCES = 'CLEAR_INSURANCES',
  SET_INSURANCE_SECURITY_INFO = 'SET_INSURANCE_SECURITY_INFO',
}

export interface InsurancesState {
  overviews: InsuranceOverview[] | null;
  positions: InsurancePosition[] | null;
  securityInfo: Insurance[];
}

interface SetInsuranceOverviews {
  type: typeof ActionTypes.SET_OVERVIEW;
  payload: InsurancesState['overviews'];
}

interface SetInsurancePositions {
  type: typeof ActionTypes.SET_POSITION;
  payload: InsurancesState['positions'];
}

interface ClearInsurances {
  type: typeof ActionTypes.CLEAR_INSURANCES;
}

interface SetInsuranceSecurityInfo {
  type: typeof ActionTypes.SET_INSURANCE_SECURITY_INFO;
  payload: InsurancesState['securityInfo'];
}

export type Actions =
  | Logout
  | ChangeUser
  | SetInsuranceOverviews
  | SetInsurancePositions
  | ClearInsurances
  | SetInsuranceSecurityInfo;
