import React from 'react';
import { RadioGroup, FormControlLabel, Typography } from '@material-ui/core';
import Radio from '../../../../components/Radio';

import { ContactUsAction } from '../state';
import { ContactPreference } from '../../../../types/local';

const SelectContactPreference = (props: {
  value: ContactPreference;
  dispatch: React.Dispatch<ContactUsAction>;
}) => {
  const { value, dispatch } = props;
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: 'set-contact-preference',
      payload: (event.target as HTMLInputElement).value as ContactPreference,
    });
  };

  return (
    <>
      <Typography style={{ textTransform: 'uppercase' }}>
        Toivon vastauksen ensisijaisesti
      </Typography>
      <RadioGroup
        aria-label="Vastauksen kanava"
        name="service-method"
        value={value}
        onChange={handleChange}
      >
        {Object.keys(ContactPreference)
          .sort()
          .map((v) => {
            const k = ContactPreference[v as keyof typeof ContactPreference];
            return (
              <FormControlLabel
                key={k}
                value={k}
                control={<Radio />}
                label={v}
              />
            );
          })}
      </RadioGroup>
    </>
  );
};

export default SelectContactPreference;
