import firebase from 'firebase/app';
import { gql } from 'graphql-request';

import { DataSource } from '../dataSource';
import { store } from '../../store';
import { UpdateType } from '../../types/api';
import { DataSubscription } from '../dataSubscription';
import { getClient } from '../../utils/graphql';
import {
  setContent,
  setFrontpageContent,
  setGlobalContent,
  setReviewContent,
  setCorporateFinancingContent,
  setCustomPagesContent,
} from '../../store/content/actions';
import { setContentContentsLoaded } from '../../store/loaded/actions';
import {
  Content,
  CorporateFinancingContent,
  FrontpageContent,
  GlobalContent,
  ReviewContent,
  Activity as ContentfulActivity,
  CustomPagesContent,
} from '../../types/contentful';
import { setActivities } from '../../store/activity/actions';
import { Activity } from '../../store/activity/types';

export class ContentDataSource extends DataSource {
  name: UpdateType = 'content';
  subscribe(
    uid: string,
    ownUid: string,
    db: firebase.firestore.Firestore
  ): DataSubscription {
    const client = getClient();

    const query = gql`
      {
        omasalkkuCollection(limit: 1) {
          items {
            articlesCollection {
              items {
                ... on Article {
                  sys {
                    id
                  }
                  __typename
                  slug
                  title
                  description
                  publicationDate
                  previewBody
                  category
                  body
                  image {
                    url
                  }
                  customerGroups
                }
                ... on PodcastEpisode {
                  sys {
                    id
                  }
                  __typename
                  slug
                  body
                  title
                  category
                  image {
                    url
                  }
                  previewBody
                  episodeNumber
                  seasonNumber
                  videoEmbed
                  spotifyEmbed
                  runTime
                  heading
                  publicationDate
                }
                ... on Page {
                  sys {
                    id
                  }
                  __typename
                  slug
                  title
                  heading
                  description
                  previewBody
                  previewHeading
                  body
                  image {
                    url
                  }
                }
              }
            }
          }
        }
      }
    `;
    client.request(query).then(
      (data: {
        omasalkkuCollection: {
          items: { articlesCollection: { items: Content[] } }[];
        };
      }) => {
        const {
          omasalkkuCollection: {
            items: [
              {
                articlesCollection: { items },
              },
            ],
          },
        } = data;

        const sortedByPublicationDate = [...items].sort((a, b) => {
          // If the item doesn't have `publicationDate`, give it an old date and throw it at the end of the array
          const dateA = a.publicationDate
            ? new Date(a.publicationDate)
            : new Date('2000-01-01');
          const dateB = b.publicationDate
            ? new Date(b.publicationDate)
            : new Date('2000-01-01');
          return dateB.getTime() - dateA.getTime();
        });

        store.dispatch(setContent(sortedByPublicationDate));
        store.dispatch(setContentContentsLoaded(true));
      }
    );

    return new DataSubscription(this.name, () => null);
  }
}

export class GlobalContentDataSource extends DataSource {
  name: UpdateType = 'global-content';
  subscribe(
    uid: string,
    ownUid: string,
    db: firebase.firestore.Firestore
  ): DataSubscription {
    const client = getClient();

    const query = gql`
      {
        omasalkkuCollection(limit: 1) {
          items {
            termsOfUse {
              ...legalFields
            }
            privacyPolicy {
              ...legalFields
            }
            accessibilityPage {
              sys {
                id
              }
              slug
              title
              heading
              leadParagraph
              image {
                url
              }
              body
            }
          }
        }
      }

      fragment legalFields on Legal {
        title
        heading
        body {
          json
          links {
            entries {
              hyperlink {
                sys {
                  id
                }
              }
            }
            assets {
              hyperlink {
                sys {
                  id
                }
                url
                fileName
              }
            }
          }
        }
      }
    `;

    client
      .request(query)
      .then((data: { omasalkkuCollection: { items: GlobalContent[] } }) => {
        store.dispatch(setGlobalContent(data.omasalkkuCollection.items[0]));
      });

    return new DataSubscription(this.name, () => null);
  }
}

export class ActivitiesContentDataSource extends DataSource {
  name: UpdateType = 'activities-content';
  subscribe(
    uid: string,
    ownUid: string,
    db: firebase.firestore.Firestore
  ): DataSubscription {
    const client = getClient();

    const query = gql`
      {
        omasalkkuCollection {
          items {
            activitiesCollection {
              items {
                ... on OmasalkkuActivity {
                  sys {
                    id
                  }
                  title
                  body
                  type
                  header
                  authorName
                  time
                  linkTo
                }
              }
            }
          }
        }
      }
    `;

    const toActivityItem = (contentfulItem: ContentfulActivity): Activity => {
      return {
        id: contentfulItem.sys.id,
        title: contentfulItem.title,
        body: contentfulItem.body,
        type: contentfulItem.type,
        header: contentfulItem.header,
        author: {
          name: contentfulItem.authorName,
          photo: '',
          time: contentfulItem.time
            ? {
                seconds: new Date(contentfulItem.time).getTime() / 1000,
                nanoseconds: 0,
              }
            : undefined,
        },
        url: contentfulItem.linkTo ?? '',
      };
    };

    client.request(query).then(
      (data: {
        omasalkkuCollection: {
          items: { activitiesCollection: { items: ContentfulActivity[] } }[];
        };
      }) => {
        const activities =
          data.omasalkkuCollection.items[0].activitiesCollection.items.map(
            toActivityItem
          );
        store.dispatch(setActivities(activities));
      }
    );

    return new DataSubscription(this.name, () => null);
  }
}

export class FrontpageContentDataSource extends DataSource {
  name: UpdateType = 'frontpage-content';
  subscribe(
    uid: string,
    ownUid: string,
    db: firebase.firestore.Firestore
  ): DataSubscription {
    const client = getClient();

    const query = gql`
      {
        omasalkkuCollection(limit: 1) {
          items {
            upperBanner: frontPageUpperBanner {
              ...bannerFields
            }
            lowerBanner: frontPageLowerBanner {
              ...bannerFields
            }
            faultMessage {
              json
            }
            showFaultMessage
          }
        }
      }

      fragment bannerFields on Banner {
        title
        text
        linkTarget
        linkTargetFile {
          title
          fileName
          url
          contentType
          description
        }
        targetPage {
          sys {
            id
          }
          slug
        }
        linkText
        linkContent
        linkHeading
        image {
          title
          description
          contentType
          fileName
          url
        }
      }
    `;

    client
      .request(query)
      .then((data: { omasalkkuCollection: { items: FrontpageContent[] } }) => {
        store.dispatch(setFrontpageContent(data.omasalkkuCollection.items[0]));
      });

    return new DataSubscription(this.name, () => null);
  }
}
export class CustomPagesDataSource extends DataSource {
  name: UpdateType = 'custom-pages-content';
  subscribe(
    uid: string,
    ownUid: string,
    db: firebase.firestore.Firestore
  ): DataSubscription {
    const client = getClient();

    const query = gql`
      {
        omasalkkuCollection(limit: 1) {
          items {
            benefitProgramPageBody1 {
              json
            }
            benefitProgramPageBody2 {
              json
            }
            benefitProgramPageMenuInfo {
              json
            }
            benefitProgramPageMenuSmallprint {
              json
            }
            benefitProgramPageBodyImagesCollection {
              items {
                title
                description
                url
              }
            }
            benefitProgramPageInfoPdf {
              url
            }
          }
        }
      }
    `;

    client.request(query).then((data: any) => {
      const item = data.omasalkkuCollection.items[0];
      store.dispatch(
        setCustomPagesContent({
          benefitProgramPage: {
            bodySections: [
              {
                body: item.benefitProgramPageBody1,
                image:
                  item.benefitProgramPageBodyImagesCollection.items[0] ?? null,
              },
              {
                body: item.benefitProgramPageBody2,
                image:
                  item.benefitProgramPageBodyImagesCollection.items[1] ?? null,
              },
            ],
            menuContent: {
              info: item.benefitProgramPageMenuInfo,
              smallprint: item.benefitProgramPageMenuSmallprint,
            },
            infoContent: {
              info: item.benefitProgramPageInfoPdf,
            },
          },
        })
      );
    });

    return new DataSubscription(this.name, () => null);
  }
}

export class ReviewContentDataSource extends DataSource {
  name: UpdateType = 'review-content';
  subscribe(
    uid: string,
    ownUid: string,
    db: firebase.firestore.Firestore
  ): DataSubscription {
    const client = getClient();

    const query = gql`
      {
        omasalkkuCollection(limit: 1) {
          items {
            fundReview: fundReview {
              ...bannerFields
            }
            businessLoanReview: businessLoanReview {
              ...bannerFields
            }
          }
        }
      }

      fragment bannerFields on Banner {
        title
        text
        linkTarget
        linkTargetFile {
          title
          fileName
          url
          contentType
          description
        }
        targetPage {
          sys {
            id
          }
          slug
        }
        linkText
        linkContent
        linkHeading
        image {
          title
          description
          contentType
          fileName
          url
        }
      }
    `;

    client
      .request(query)
      .then((data: { omasalkkuCollection: { items: ReviewContent[] } }) => {
        store.dispatch(setReviewContent(data.omasalkkuCollection.items[0]));
      });

    return new DataSubscription(this.name, () => null);
  }
}

export class CorporateFinancingContentDataSource extends DataSource {
  name: UpdateType = 'corporate-finance-content';
  subscribe(
    uid: string,
    ownUid: string,
    db: firebase.firestore.Firestore
  ): DataSubscription {
    const client = getClient();

    const query = gql`
      {
        omasalkkuCollection(limit: 1) {
          items {
            makeAdditionalInvestment {
              json
            }
            withdrawMoney {
              json
            }
            automaticReallocationOfFunds {
              json
            }
            recoveryProcessCardTitle
            recoveryProcessCardContent
            recoveryProcessCardImage {
              title
              description
              contentType
              fileName
              url
            }
            loanDebtProcessCardTitle
            loanDebtProcessCardContent {
              json
            }
            loanDebtProcessCardImage {
              title
              description
              contentType
              fileName
              url
            }
          }
        }
      }
    `;

    client
      .request(query)
      .then(
        (data: {
          omasalkkuCollection: { items: CorporateFinancingContent[] };
        }) => {
          store.dispatch(
            setCorporateFinancingContent(data.omasalkkuCollection.items[0])
          );
        }
      );

    return new DataSubscription(this.name, () => null);
  }
}
