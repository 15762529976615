import { BookingChannel, Timestamp } from '../types/local';

export enum Tuotetyyppi {
  Muut = 0,
  Strukturoidut = 6,
  Rahastot = 8,
  /** not in use? */
  Vakuutus = 7,
  'Käteinen vakuutuksessa' = 9,
  Yrityslaina = 10,
  Indeksi = 11,
}

/** Omasalkku_Vakuutukset */
export interface InsuranceOverview {
  HetuTaiYTunnus: string;
  Vakuutustyyppi: number;
  Vakuutusnumero: string;
  VakuutuksenNimi: string;
  Voimaantulopvm: Timestamp;
  Maksuera: number;
  MaksueriaVuodessa: number;
  SijoitettuPaaoma: number;
  Arvo: number;
  Takaisinostoarvo: number;
  Valuutta: string;
  Tila: string;
}

/** Omasalkku_Vakuutusomistukset */
export interface InsurancePosition {
  Vakuutustyyppi: number;
  Vakuutusnumero: string;
  ISIN: string;
  Tuote: string;
  Kpl: number;
  Arvo: number;
  Valuutta: string;
  Arvostuspvm: Timestamp;
  URL: string;
  Tuotetyyppi: Tuotetyyppi;
}

export enum Tuoteryhma {
  Funds = 1,
  Structures = 2,
  CorporateFinancing = 6,
}

/** Omasalkku_AsiakkaanRaportit */
export interface Report {
  HetuTaiYTunnus: string;
  Tuoteryhma: Tuoteryhma;
  TuoteryhmaSelite: string;
  Vuosi: number;
  Vuosiraportti: boolean;
  Vuosiraportti_Puuttuu_Selite: string;
  KulutJaVeloitukset: boolean;
  KulutJaVeloitukset_Puuttuu_Selite: string;
  RahamyllyKulutJaVeloituksetUrl?: string;
}

export interface Office {
  Katuosoite1: string;
  Katuosoite2: string;
  Postinumero: string;
  Postitoimipaikka: string;
  Puhelinnumero: string;
  ToimistoID: number;
  ToimistoNimi: string;
}
enum GdprDataType {
  CSV = 1,
  PDF = 3,
}

export interface Slot {
  Aika: string;
  NeuvonantajaID: number;
  NeuvonantajaNimi: string;
}

export interface GDPRDataRequest {
  email: string;
  phone: string;
  dataType: GdprDataType;
}

export interface CalendarDatesByOfficePayload {
  office?: number;
  advisor?: number;
  channel?: BookingChannel;
}

export interface CalendarSlotsByOfficeDatePayload {
  office?: number;
  date?: string | null;
  advisor?: number;
  channel?: BookingChannel;
}

export interface AddBookingRequest {
  surname: string;
  firstnames: string;
  phone: string;
  email: string;
  advisorId: number;
  officeId: number;
  meetingTime: string;
  meetingDuration: string;
  messageForAdvisor: string;
  channel: BookingChannel;
  hash?: string;
}
