import { combineReducers } from 'redux';
import { structuresReducer } from './structures/reducers';
import { StructuresState } from './structures/types';
import { fundsReducer } from './funds/reducers';
import { FundsState } from './funds/types';

import { insurancesReducer } from './insurances/reducers';
import { InsurancesState } from './insurances/types';

import {corporateFinancingReducer} from './corporateFinancing/reducers';
import { CorporateFinancingState } from './corporateFinancing/types';

export const reducers = combineReducers<State>({
  structures: structuresReducer,
  funds: fundsReducer,
  insurances: insurancesReducer,
  corporateFinancing: corporateFinancingReducer,
});

export interface State {
  structures: StructuresState;
  funds: FundsState;
  insurances: InsurancesState;
  corporateFinancing: CorporateFinancingState;
}
