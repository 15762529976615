import React, { useState } from 'react';

import Form from './Form';
import Info from './Info';
import PasswordReset from '../passwordReset';

import useStyles from './styles';
import LoginHelp from "../loginHelp";

const LoginBox = (props: {
  onModalOpen: (content: React.ReactElement) => void;
}): React.ReactElement => {
  const classes = useStyles();
  const storageKey = 'skipLoginInfo';
  const skipLoginInfo = window.localStorage.getItem(storageKey);
  const [showForm, setShowForm] = useState<boolean>(
    (skipLoginInfo === 'true' && true) ||
      (skipLoginInfo === 'false' && false) ||
      false
  );
  return (
    <div>
      <div className={classes['login-container']}>
        {showForm ? (
          <Form />
        ) : (
          <Info onLoginClick={setShowForm} storageKey={storageKey} />
        )}
      </div>
        <PasswordReset
            className={classes.resetPassword}
            onModalOpen={props.onModalOpen}
        />
        <LoginHelp className={classes.resetPassword} />
    </div>
  );
};

export default LoginBox;
