import React from 'react';

import Spinner from '../../components/LoadingIndicator';
import useStyles from './styles';
const Loading = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Spinner variant="large" />
    </div>
  );
};

export default Loading;
