import React from 'react';
import clsx from 'clsx';
import List from '@material-ui/core/MenuList';
import MuiPopper from '@material-ui/core/Popper';
import { ClickAwayListener, Paper } from '@material-ui/core';

import useStyles from '../styles';

interface IPopper {
  anchorEl: HTMLElement | null;
  onClickAway: (event?: React.MouseEvent<Document, MouseEvent>) => void;
  children: React.ReactElement[] | null;
  id: string;
}

const Popper = (props: IPopper): React.ReactElement => {
  const classes = useStyles();

  const { anchorEl, onClickAway, children, id } = props;
  return (
    <MuiPopper
      id={id}
      style={{ zIndex: 99999 }}
      popperOptions={{
        positionFixed: true,
        modifiers: {
          preventOverflow: {
            enabled: false,
          },
        },
      }}
      open={anchorEl !== null}
      anchorEl={anchorEl}
      disablePortal
      className={classes.popper}
    >
      <ClickAwayListener onClickAway={onClickAway}>
        <Paper className="popper-paper">
          <List
            autoFocusItem={anchorEl !== null}
            className={clsx(classes['child-menu'], 'horizontal')}
            onKeyDown={(event: React.KeyboardEvent) => {
              if (event.key === 'Escape') {
                onClickAway();
              }
            }}
          >
            {children}
          </List>
        </Paper>
      </ClickAwayListener>
    </MuiPopper>
  );
};

export default Popper;
