import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch, RootState } from '../../../store';
import { setPopperMenu } from '../../../store/ui/actions';
import NavItem from '../../NavItem';

import MenuIcon from '../MenuIcon';
import Popper from './popper';
import { NavSpec } from '../../../types/local';
const setMenu = (menu: string, dispatch: AppDispatch) => {
  dispatch(setPopperMenu(menu));
};

const menuFactory = (props: {
  navItems: NavSpec[];
  isChild?: boolean;
  parentKey?: string;
  parentTo?: string;
}): React.ReactElement[] => {
  const dispatch = useDispatch();
  const { navItems, parentKey, parentTo, isChild } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const selectedNav = useSelector((state: RootState) => state.ui.selectedTab);
  const popperMenu = useSelector((state: RootState) => state.ui.popperMenu);

  const menu = navItems.map((item, index) => {
    const itemKey = `${parentKey ? parentKey : 'nav-item'}-${item.to.slice(1)}`;
    const to = `${parentTo ? parentTo + item.to : item.to}`;
    const childMenu =
      item.children && item.children.length > 0
        ? menuFactory({
            navItems: item.children,
            isChild: true,
            parentKey: itemKey,
            parentTo: item.to,
          })
        : undefined;

    const selectedInPath = selectedNav.includes(itemKey);
    const isSelected = selectedNav === itemKey;
    const isPopperOpen = popperMenu.indexOf(itemKey) > -1;

    const itemCmp = (
      <NavItem
        label={item.label}
        to={to}
        key={itemKey + index + '-navitem'}
        onClick={() => {
          if (childMenu && isPopperOpen) {
            setAnchorEl(null);
            setMenu('', dispatch);
          }
          if (isChild) {
            setAnchorEl(null);
            setMenu('', dispatch);
          }
        }}
        onIconClick={(event) => {
          if (childMenu) {
            if (anchorEl === null || !isPopperOpen) {
              setAnchorEl(event.currentTarget.parentElement);
              setMenu(itemKey, dispatch);
            } else {
              setAnchorEl(null);
              setMenu('', dispatch);
            }
          }
        }}
        selectedInPath={selectedInPath}
        selected={isSelected}
        drawer={false}
        icon={
          childMenu ? (
            <MenuIcon open={isPopperOpen} selected={isSelected} />
          ) : undefined
        }
        aria-controls={
          childMenu && isPopperOpen ? itemKey + '-menu-list' : undefined
        }
        aria-haspopup="true"
      />
    );
    return childMenu ? (
      <React.Fragment key={itemKey + index}>
        {itemCmp}
        {isPopperOpen && (
          <Popper
            anchorEl={anchorEl}
            id={`${itemKey}-menu-list`}
            onClickAway={() => {
              setAnchorEl(null);
              setMenu('', dispatch);
            }}
          >
            {childMenu}
          </Popper>
        )}
      </React.Fragment>
    ) : (
      itemCmp
    );
  });

  return menu;
};

export default menuFactory;
