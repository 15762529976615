import firebase from 'firebase/app';
import { FSAdvisor, FSUser } from '../../types/firestore';
import { ViewableUser } from '../../types/local';
import { ChangeUser, Logout } from '../base';

export enum ActionTypes {
  SET_USER = 'SET_USER',
  SET_FS_USER = 'SET_FS_USER',
  SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN',
  SET_CONTROLLABLE_USERS = 'SET_CONTROLLABLE_USERS',
  SET_USERS_ADVISOR = 'SET_USERS_ADVISOR',
  CLEAR_USER = 'CLEAR_USER',
}

export interface State {
  user?: firebase.User;
  fsUser?: FSUser;
  accessToken?: string;
  controllableUsers?: ViewableUser[];
  advisor?: FSAdvisor;
}

interface SetUser {
  type: typeof ActionTypes.SET_USER;
  payload: State['user'];
}

interface SetFSUser {
  type: typeof ActionTypes.SET_FS_USER;
  payload: State['fsUser'];
}

interface SetAccessToken {
  type: typeof ActionTypes.SET_ACCESS_TOKEN;
  payload: State['accessToken'];
}

interface SetControllableUsers {
  type: typeof ActionTypes.SET_CONTROLLABLE_USERS;
  payload: State['controllableUsers'];
}

interface SetUsersAdvisor {
  type: typeof ActionTypes.SET_USERS_ADVISOR;
  payload: State['advisor'];
}

interface ClearUserAction {
  type: typeof ActionTypes.CLEAR_USER;
}

export type Actions =
  | Logout
  | ChangeUser
  | SetUser
  | SetFSUser
  | SetAccessToken
  | SetControllableUsers
  | SetUsersAdvisor
  | ClearUserAction;
