import { useEffect, forwardRef, MutableRefObject } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * Scrolls to the top of the page when current route changes.
 * If `ref` is given it is used for the scrolling,
 * otherwise window is used.
 */
const ScrollToTop = forwardRef<HTMLElement>((_, ref) => {
  const { pathname } = useLocation();

  useEffect(() => {
    const target = (ref as MutableRefObject<HTMLElement>)?.current;

    if (target !== undefined) {
      target.scrollTo(0, 0);
    }
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
});

ScrollToTop.displayName = 'ScrollToTop';

export default ScrollToTop;
