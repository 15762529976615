import React from 'react';
import { Activity, Booking, Kanava } from '../../store/activity/types';
import { Office } from '../../types/abc';
import { ActivityFeedItemProps } from '../ActivityFeedItem';
import { FormatDate, FormatTime } from '../../utils/date';
import { FSAdvisor } from '../../types/firestore';

export const formatOfficeInfo = (
  office?: Office
): React.ReactElement | string | null => {
  if (office) {
    return (
      <div>
        Toimisto: {office.ToimistoNimi}
        <br></br>
        Osoite:{' '}
        {`${office.Katuosoite1}${
          office.Katuosoite2 ? ', ' + office.Katuosoite2 : ''
        }`}
      </div>
    );
  }
  return null;
};

export const createBookingBody = (
  booking: Booking,
  office?: Office
): React.ReactElement | string | null => {
  switch (booking.Kanava) {
    case Kanava.Toimistolla:
      return formatOfficeInfo(office);
    case Kanava.Puhelimessa:
      return 'Puhelimessa'; // Add adviser's number here?
    case Kanava.Videotapaaminen:
      return 'Videotapaaminen';
    case Kanava['Toimiston ulkopuolella']:
      return 'Toimiston ulkopuolella';
    default:
      return null;
  }
};

export const createAuthorAdditionalInfo = (
  author?: FSAdvisor
): React.ReactElement | string => {
  return author ? (
    <div>
      <a href={'mailto:' + author.Sahkopostiosoite}>
        {author.Sahkopostiosoite}
      </a>
      <br></br>
      Puh. <a href={'tel:' + author.Puhelinnumero}>{author.Puhelinnumero}</a>
    </div>
  ) : (
    ''
  );
};

export const bookingToFeedItem = (offices: Office[]) => (
  booking: Booking
): ActivityFeedItemProps => {
  const office = offices.find(
    (office) => office.ToimistoID === booking.ToimistoID
  );
  return {
    type: 'meeting',
    header: `${FormatDate(
      (booking.Alkuaika?.seconds || 0) * 1000,
      true
    )} - ${FormatTime((booking.Loppuaika?.seconds || 0) * 1000)}`,
    title: 'Tapaaminen',
    body: createBookingBody(booking, office),
    author: {
      name: booking.Neuvonantaja?.NeuvonantajaNimi || 'Tuntematon',
      photo: booking.Neuvonantaja?.Kuva || '',
      additionalInfo: createAuthorAdditionalInfo(booking.Neuvonantaja),
    },
    linkTo: `/tapaamiset/${booking.KayntiID}`,
  };
};

export function activityToFeedItem(activity: Activity): ActivityFeedItemProps {
  return {
    type: activity.type,
    header: activity.header || 'salkku',
    title: activity.title,
    body: activity.body,
    author: {
      name: activity.author.name,
      photo: activity.author.photo,
      additionalInfo: activity.author.time
        ? FormatDate(activity.author.time.seconds * 1000, true)
        : '',
    },
    linkTo: activity.url || '/',
  };
}
