import theme from '../../theme';

export const getLinkOverrideStyles = (linkText: string): object => {
  switch (linkText) {
    case 'Etuohjelman tietopaketti':
      return {
        textDecoration: 'none',
        padding: '.75em 1em',
        backgroundColor: theme.palette.secondary.light,
        color: theme.palette.text.primary,
        borderRadius: '48px',
      };
    default:
      return {};
  }
};
