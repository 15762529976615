import { makeStyles } from '@material-ui/core/styles';
import Decoration from '../../../images/svg/bubble-decoration-up-gray.svg';
const useStyles = makeStyles((theme) => ({
  item: {
    border: 'none',
    color: theme.palette.primary.main,
    fontSize: '16px',
    borderRadius: '23px',

    '&:hover, &[data-focus]': {
      backgroundColor: theme.palette.grey['200'],
      boxShadow:
        ' -3px -3px 4px rgba(255, 255, 255, 0.7), 1px 3px 6px rgba(0, 0, 0, 0.08), 0px 16px 10px -10px rgba(0, 0, 0, 0.1)',
    },
  },
  itemTextPrimary: {
    fontWeight: 500,
  },
  itemTextSecondary: {
    color: theme.palette.primary.main,
  },
  container: {
    width: '100%',
    '&.focused': {
      marginLeft: 0,
    },
    marginLeft: 'calc(100% - 273px)',
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      width: '90px',
      position: 'absolute',
      '&.focused': {
        width: '60vw',
        marginLeft: '16px',
      },
      marginLeft: 0,
    },
    [theme.breakpoints.down(400)]: {
      width: '50px',
    },
    transition: theme.transitions.create('margin-left', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  'list-container': {
    position: 'fixed',
    width: '300px',
    '&:before': {
      content: '""',
      display: 'block',
      backgroundImage: `url(${Decoration})`,
      width: '46px',
      height: '23px',
      marginRight: 'auto',
      marginLeft: 'auto',
    },
    [theme.breakpoints.down(400)]: {
      width: '250px',
    },
    [theme.breakpoints.down(600)]: {
      right: 10,
    },
  },
  list: {
    backgroundColor: theme.palette.grey['100'],
    borderRadius: '36px',
    boxShadow: '8px 16px 16px #00000015',
  },
  textField: {
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.primary.main,
    width: '100%',
    [theme.breakpoints.down(400)]: {
      padding: '0 1rem',
    },
    '& ::placeholder': {
      opacity: 1,
      color: theme.palette.secondary.main,
    },
    '& .MuiInputAdornment-positionEnd': {
      marginLeft: '0px',
    },
  },
}));

export default useStyles;
