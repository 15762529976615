import { Reducer } from '@reduxjs/toolkit';
import {
  InsurancesState as State,
  Actions,
  ActionTypes as actions,
} from './types';

const INITIAL_STATE: State = {
  overviews: null,
  positions: null,
  securityInfo: [],
};

export const insurancesReducer: Reducer<State, Actions> = (
  state: State = INITIAL_STATE,
  action: Actions
): State => {
  switch (action.type) {
    case actions.SET_OVERVIEW:
      return {
        ...state,
        overviews: action.payload,
      };
    case actions.SET_POSITION:
      return {
        ...state,
        positions: action.payload,
      };
    case actions.SET_INSURANCE_SECURITY_INFO:
      return {
        ...state,
        securityInfo: action.payload,
      };
    case actions.CLEAR_INSURANCES:
    case 'LOGOUT':
    case 'CHANGE_USER':
      return INITIAL_STATE;
    default:
      return state;
  }
};
