import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  main: {
    zIndex: 1,
    width: '100vw',
    marginLeft: '0px',
    position: 'relative',
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    overflowX: 'hidden',
    left: 0,
    transition: theme.transitions.create('left', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    '&.nav-open': {
      left: '354px',
      borderRadius: '0px 0px 0px 46px',
      transition: theme.transitions.create('left', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      [theme.breakpoints.down(400)]: {
        left: 'calc(100% - 35px)',
      },
    },
  },
  views: {
    position: 'relative',
    backgroundColor: theme.palette.white,
    width: '100vw',
    maxWidth: '1920px',
    marginBottom: '5rem',
    flex: '1 1 auto',
    [theme.breakpoints.up(theme.breakpoints.values.xs)]: {
      marginTop: '105px',
    },
    [theme.breakpoints.up(theme.breakpoints.values.md)]: {
      marginTop: '125px',
    },
    [theme.breakpoints.up(theme.breakpoints.values.lg)]: {
      marginTop: '135px',
    },
  },
  content: {
    [theme.breakpoints.up(theme.breakpoints.values.xs)]: {
      padding: '0 16px 0px 16px',
    },
    [theme.breakpoints.up(theme.breakpoints.values.md)]: {
      padding: '0 75px 0px 75px',
    },
    [theme.breakpoints.up(theme.breakpoints.values.lg)]: {
      padding: '0 150px 0px 150px',
    },
  },
  decoration: {
    position: 'absolute',
    top: '30px',
    '&.left': {
      left: 0,
    },
    '&.right': {
      right: 0
    },
    [theme.breakpoints.up(theme.breakpoints.values.xs)]: {
      visibility: 'hidden',
    },
    [theme.breakpoints.up(theme.breakpoints.values.lg)]: {
      visibility: 'visible',
    },
  },
}));

export default useStyles;
