import React from 'react';
import { Header } from '../../components/Header';
import ShowViewedUser from '../../components/Header/ShowViewedUser';

const Top = (): React.ReactElement => {
  return (
    <>
      <ShowViewedUser />
      <Header />
    </>
  );
};

export default Top;
