import { parse } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { getFormatString, TZ } from '../utils/date';

export type Order = 'asc' | 'desc';
export type DataType = 'date' | 'number' | 'component';
/**
 * Format value to a different presentation format.
 * Used for comparison where direct string comparison doesn't make sense
 * @param dataType to determine the type of value and how it should be formatted for comparison
 * @param value to be formatted
 */
function formatDataValue<T>(dataType: DataType | undefined, value: T) {
  switch (dataType) {
    case 'date': {
      if (typeof value === 'string') {
        const dateObj = parse(
          value,
          getFormatString(),
          utcToZonedTime(Date.now(), TZ)
        );
        return isNaN(dateObj.getTime())
          ? new Date(0).toISOString()
          : dateObj.toISOString();
      }
      return value;
    }
    case 'number': {
      if (typeof value == 'string') {
        const number = parseFloat(
          value.replace(/\u00a0/g, '').replace(/,/g, '.')
        );
        return isNaN(number) ? -1 : number;
      }
      return value;
    }
    case 'component': {
      if (
        typeof value == 'object' &&
        Object.prototype.hasOwnProperty.call(value, 'key')
      ) {
        const element = (value as unknown) as { [key: string]: string };
        return element['key'];
      }
      return '';
    }
    default:
      return value;
  }
}

function descendingComparator<T>(
  a: T,
  b: T,
  orderBy: keyof T,
  dataType?: DataType
) {
  const valueA = formatDataValue(dataType, a[orderBy]);
  const valueB = formatDataValue(dataType, b[orderBy]);
  if (valueB < valueA) {
    return -1;
  }
  if (valueB > valueA) {
    return 1;
  }
  return 0;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
  dataType?: DataType
): (
  a: { [key in Key]: number | string | React.ReactElement },
  b: { [key in Key]: number | string | React.ReactElement }
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy, dataType)
    : (a, b) => -descendingComparator(a, b, orderBy, dataType);
}

/**
 * Sorts an array with a sorting function
 * @param array list of objects
 * @param comparator sorting function 
 * order: 'asc' | 'desc'
 * orderBy: 'key to sort with'
 * 
 * Example:
    ``` ts
    const rows = [
      { id: 1, name: 'a' },
      { id: 2, name: 'b' },
      { id: 4, name: 'c' },
      { id: 3, name: 'c' },
    ];
    const comparator = getComparator('desc', 'name')
    const sorted = stableSort<{id:number, name:string}>(rows, comparator)
    //[
    //  { id: 4, name: 'c' },
    //  { id: 3, name: 'c' },
    //  { id: 2, name: 'b' },
    //  { id: 1, name: 'a' }
    //]
    ```
 */
export function stableSort<T>(
  array: T[],
  comparator: (a: T, b: T) => number
): T[] {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
