import { GraphQLClient } from 'graphql-request';

export const getClient = (): GraphQLClient => {
  const url = process.env.CONTENTFUL_GRAPHQL_URL;
  const spaceId = process.env.CONTENTFUL_SPACE_ID;
  const token = process.env.CONTENTFUL_GRAPHQL_TOKEN;
  const environment = process.env.CONTENTFUL_ENVIRONMENT;

  const baseUrl = `${url}/${spaceId}`;
  return new GraphQLClient(
    `${baseUrl}${environment === 'test' ? '/environments/test' : ''}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};
