import { Typography, Link } from '@material-ui/core';
import React from 'react';

import { ReactComponent as Phone } from '../../../images/svg/phone.svg';
import { ReactComponent as Email } from '../../../images/svg/email.svg';

import useStyles from './styles';

const Instructions = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.textBox}>
        <div className="section">
          <Typography variant="body1" className="title">
            Asiakaspalvelu
          </Typography>
          <Typography variant="body1">
            <Phone /> <a href="tel:0200 10 100">0200 10 100</a> (pvm/mpm)
          </Typography>
          <Typography variant="body1">ma-pe klo 8:00-16:30</Typography>
        </div>
        <div className="section">
          <Typography variant="body1" className="title">
            Sähköpostitse
          </Typography>

          <Typography variant="body1">
            <Email /> <a href="mailto:info@alexandria.fi">info@alexandria.fi</a>
          </Typography>
        </div>
        <div className="section">
          <Typography variant="body1">
            Etkö löytänyt etsimääsi? Tarkemmat yhteystiedot löydät
            verkkosivuiltamme{' '}
            <Link
              href="https://www.alexandria.fi/toimipaikat-ja-yhteystiedot"
              target="_blank"
              rel="noreferrer"
            >
              alexandria.fi
            </Link>
            .
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default Instructions;
