import { Reducer } from '@reduxjs/toolkit';
import { State, Actions, ActionTypes as actions } from './types';

const INITIAL_STATE: State = {
  content: {
    offices: false,
    advisors: false,
    content: false,
  },
};

const modifyState = <K1 extends keyof State, K2 extends keyof State[K1]>(
  state: State,
  key: K1,
  subKey: K2,
  value: State[K1][K2]
): State => {
  return {
    ...state,
    [key]: { ...state[key], [subKey]: value },
  };
};

export const loadedReducer: Reducer<State, Actions> = (
  state: State = INITIAL_STATE,
  action: Actions
): State => {
  switch (action.type) {
    case actions.SET_CONTENT_ADVISORS_LOADED:
      return modifyState(state, 'content', 'advisors', action.payload);
    case actions.SET_CONTENT_CONTENTS_LOADED:
      return modifyState(state, 'content', 'content', action.payload);
    case actions.SET_CONTENT_OFFICES_LOADED:
      return modifyState(state, 'content', 'offices', action.payload);
    case 'LOGOUT':
      return INITIAL_STATE;
    default:
      return state;
  }
};
