import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';

/**
 * Send pageviews to Google Analytics
 */
const GoogleAnalyticsRouteTracker = (): null => {
  const location = useLocation();

  // This sets the page for the tracker instance, so that any hits sent
  // on the same page after this are sent with the page information
  ReactGA.set({ page: location.pathname });

  ReactGA.pageview(location.pathname);

  return null;
};

export default GoogleAnalyticsRouteTracker;
