import firebase from 'firebase/app';
import { DataSource, DataSourceUpdateType } from '../dataSource';
import { store } from '../../store';
import { setActivities } from '../../store/activity/actions';
import { DataSubscription } from '../dataSubscription';
import { Activity } from '../../store/activity/types';

export class ActivitiesDataSource extends DataSource {
  name: DataSourceUpdateType = 'activities';
  subscribe(
    uid: string,
    ownUuid: string,
    db: firebase.firestore.Firestore
  ): DataSubscription {
    const docs = db.collection('activities').doc(uid);

    const unsubscribe = docs.onSnapshot(
      async (snapshot) => {
        const activities = snapshot.data() as
          | { activities: Activity[] }
          | undefined;

        if (activities && activities.activities) {
          store.dispatch(setActivities(activities.activities));
        }
      },
      (err) => {
        console.log(`Encountered error: ${err}`);
      }
    );

    return new DataSubscription(this.name, unsubscribe);
  }
}
