import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';

import { RootState } from '../../store';
import { setNavigation, setPageScrolledDown } from '../../store/ui/actions';
import { ReactComponent as Star } from '../../images/svg/line-star.svg';
import Notification from '../../components/Notification';

import useStyles from './styles';
import Footer from '../../components/Footer';
import ScrollToTop from '../../routes/scrollToTop';

interface IContext {
  anonymous?: boolean;
}

export const MiddleContext = React.createContext<IContext>({});

/**
 * The layout for the page's content.
 * NOTE: This is the element that scrolls when you scroll down the page, not `body`.
 * The id `scroll-root` is assigned so we can find this element easily
 * and scroll the page manually with it.
 */
const Middle = (
  props: { children: JSX.Element } & IContext
): React.ReactElement => {
  const classes = useStyles();
  const isNavigationOpen = useSelector(
    (state: RootState) => state.ui.isNavigationOpen
  );
  const notification = useSelector((state: RootState) => state.ui.notification);
  const pageScrolledDown = useSelector(
    (state: RootState) => state.ui.pageScrolledDown
  );

  const context: IContext = {
    anonymous: props.anonymous || false,
  };

  const dispatch = useDispatch();

  const handleClick = () => {
    if (isNavigationOpen) {
      dispatch(setNavigation(false));
    }
  };

  const handleScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const isScrolled = e.currentTarget.scrollTop > 0;
    pageScrolledDown !== isScrolled &&
      dispatch(setPageScrolledDown(isScrolled));
  };

  const ownRef = React.useRef(null);

  return (
    <div
      id="scroll-root"
      className={clsx(classes.main, {
        'nav-open': isNavigationOpen,
      })}
      onClick={handleClick}
      onScroll={handleScroll}
      ref={ownRef}
    >
      <ScrollToTop ref={ownRef} />
      <MiddleContext.Provider value={context}>
        <div className={clsx(classes.views, { 'nav-open': isNavigationOpen })}>
          <Notification
            open={notification.open}
            message={notification.message}
            variant={notification.type}
          />
          <div className={clsx(classes.decoration, 'left')}>
            <Star />
          </div>
          <div className={classes.content}>{props.children}</div>
          <div className={clsx(classes.decoration, 'right')}>
            <Star />
          </div>
        </div>
        <Footer />
      </MiddleContext.Provider>
    </div>
  );
};

export default Middle;
