import { Snackbar, SnackbarContent, makeStyles } from '@material-ui/core';
import React from 'react';
import theme from '../../theme';

interface NotificationProps {
  message?: string;
  open: boolean;
  onClose?: () => void;
  variant: 'primary' | 'secondary' | 'error';
}

const useStylesContent = makeStyles(() => ({
  root: {
    backgroundColor: 'white',
    color: (props: NotificationProps) => {
      switch (props.variant) {
        case 'primary':
          return theme.palette.primary.main;
        case 'secondary':
          return theme.palette.secondary.main;
        case 'error':
          return theme.palette.secondary.main;
        default:
          return theme.palette.primary.main;
      }
    },
    borderRadius: '24px',
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: (props: NotificationProps) => {
      switch (props.variant) {
        case 'primary':
          return theme.palette.primary.main;
        case 'secondary':
          return theme.palette.secondary.main;
        case 'error':
          return theme.palette.secondary.main;
        default:
          return theme.palette.primary.main;
      }
    },
    fontWeight: 500,
    padding: '6px 20px',
    zIndex: 10000,
  },
}));

const Notification = (props: NotificationProps): React.ReactElement => {
  const {
    open,
    message,
    onClose,
  } = props;

  const contentClasses = useStylesContent(props);

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={open}
      onClose={onClose}
      key={message}
      autoHideDuration={5000}
    >
      <SnackbarContent message={message} classes={contentClasses} />
    </Snackbar>
  );
};

export default Notification;
