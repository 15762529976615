import firebase from 'firebase/app';
import { DataSource } from '../dataSource';
import { store } from '../../store';
import {
  setBalance,
  setInvestments,
} from '../../store/investments/corporateFinancing/actions';
import { UpdateType } from '../../types/api';
import { DataSubscription } from '../dataSubscription';
import { RahamyllyBalance, RahamyllyLoan } from '../../types/rahamylly';
import { utcToZonedTime } from 'date-fns-tz';
import { TZ } from '../../utils/date';

const collectionName = 'corporateFinancing';

export class CorporateFinancingBalanceDataSource extends DataSource {
  name: UpdateType = 'rahamylly-balance';
  subscribe(
    uid: string,
    ownUid: string,
    db: firebase.firestore.Firestore
  ): DataSubscription {
    const balanceRef = db
      .collection(collectionName)
      .doc(uid)
      .collection('balance')
      .doc(utcToZonedTime(Date.now(), TZ).toISOString().split('T')[0]);

    const unsubscribeFirestore = balanceRef.onSnapshot(
      async (snapshot) => {
        const data = snapshot.data() as
          | (RahamyllyBalance & { noData: boolean })
          | undefined;
        const idToken = await this.getIdToken();

        if (data) {
          store.dispatch(setBalance(data));
        } else if (idToken) {
          this.requestUpdate(
            { target: 'rahamylly-balance', parameters: {}, uid },
            idToken
          );
        }
      },
      (error) => {
        console.error(error);
      }
    );

    const unsubscribe = () => {
      unsubscribeFirestore();
    };

    return new DataSubscription(this.name, unsubscribe);
  }
}

export class CorporateFinancingInvestmentsDataSource extends DataSource {
  name: UpdateType = 'rahamylly-investments';
  subscribe(
    uid: string,
    ownUid: string,
    db: firebase.firestore.Firestore
  ): DataSubscription {
    const investmentsRef = db
      .collection(collectionName)
      .doc(uid)
      .collection('investments')
      .doc(utcToZonedTime(Date.now(), TZ).toISOString().split('T')[0]);

    const unsubscribeFirestore = investmentsRef.onSnapshot(
      async (snapshot) => {
        const data = snapshot.data() as
          | { investments: RahamyllyLoan[] }
          | undefined;

        const idToken = await this.getIdToken();

        if (data) {
          store.dispatch(setInvestments(data.investments));
        } else if (idToken) {
          this.requestUpdate(
            { target: 'rahamylly-investments', parameters: {}, uid },
            idToken
          );
        }
      },
      (error) => {
        console.error(error);
      }
    );

    const unsubscribe = () => {
      unsubscribeFirestore();
    };

    return new DataSubscription(this.name, unsubscribe);
  }
}
