import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  nav: {},
  container: {},
  ul: {
    display: 'flex',
    paddingLeft: '0px',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
    },
    '& li': {
      listStyleType: 'none',
      textDecoration: 'none',
      '&.selected-path': {
        background: theme.palette.secondary.light,
        borderRadius: '36px',
        fontWeight: 500,
      },
      '&.selected': {
        background: theme.palette.secondary.light,
        borderRadius: '36px',
      },
      '& span': {
        flex: 1,
        display: 'flex',
      },
    },
  },
}));

export default useStyles;
