import React, { useEffect, useState } from 'react';
import { Button, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { getIdToken } from '../../../../utils/firebase';
import { TZ } from '../../../../utils/date';
import { Booking, FormattedDateToYearMap } from '../../../../types/local';
import { CalendarAction } from '../../../Calendar/Form/state';
import { getOpenDatesByOffice_v2 } from '../../../../utils/api';
import Spinner from '../../../../components/LoadingIndicator';
import { toDate, utcToZonedTime } from 'date-fns-tz';
import { addDays, addWeeks, startOfDay, isBefore, isWeekend, format, parse, getWeek, setWeek } from 'date-fns';
import useStyles from './styles';

import { ReactComponent as Backward } from '../../../../images/svg/arrow_left_small.svg';
import { ReactComponent as Forward } from '../../../../images/svg/arrow_right_small.svg';
import { ReactComponent as Check } from '../../../../images/svg/check-nostyle.svg';

export const formatDatesResponse = (response: string[]): number[] =>
  response.map((d: string) => utcToZonedTime(d, TZ).getTime());

const SelectDay = (props: {
  state: Booking;
  dispatch: React.Dispatch<CalendarAction>;
}): React.ReactElement => {
  const { state, dispatch } = props;
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [allDates, setAllDates] = useState<number[]>([]);
  const [selectedDate, setSelectedDate] = useState<string>(() => {
    // Retrieve the previously selected date from local storage or any other storage mechanism
    const storedDate = localStorage.getItem('selectedDate');
    return storedDate || '';
  });
  const [weeksInFuture, setWeeksInFuture] = useState<number>(0);

  const user = useSelector((state: RootState) => state.user.fsUser);

  useEffect(() => {
    async function updateDates() {
      const idToken = await getIdToken();
      setLoading(true);
      try {
        const response = (await (
          await getOpenDatesByOffice_v2(idToken, {
            office: -1,
            channel: state.channel,
          })
        ).json()) as string[];
        const openDates = formatDatesResponse(response);
        setAllDates(openDates);
        setSelectedDate(openDates.length > 0 ? convertToDateStrings(openDates)[0] : '');
        openDates.length > 0 && handleDateChange(convertToDate(selectedDate));
        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    }
    updateDates();
  }, [state.channel]);

  useEffect(() => {
    // Save the selected date to local storage or any other storage mechanism
    localStorage.setItem('selectedDate', selectedDate);
  }, [selectedDate]);

  const handleDateChange = (date: Date | Date[]) => {
    const payload = Array.isArray(date) ? date[0] : date;
    dispatch({ type: 'set-date', payload: payload });
  };

  const convertToDateStrings = (dateNumbers: number[]) => {
    const dateStrings: string[] = [];

    dateNumbers.forEach((dateNumber) => {
      const date = new Date(dateNumber);
      const day = String(date.getDate());
      const month = String(date.getMonth() + 1);
      const dateString = `${day}.${month}.`;
      dateStrings.push(dateString);
    });

    return dateStrings;
  };

  const convertToZonedTime = (dateString: string) => {
    const [day, month] = dateString.split('.');
    const year = new Date().getFullYear();
    const fullDateString = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    const zonedDate = utcToZonedTime(fullDateString, TZ);
    const zonedTime = zonedDate.getTime();

    return zonedTime;
  };

  const convertToDate = (d: string) => {
    setSelectedDate(d);
    return toDate(utcToZonedTime(convertToZonedTime(d), TZ).getTime(), { timeZone: TZ });
  };

  const parseDate = (dateString: string) => {
    if (!dateString) return false;
    const [day, month] = dateString.split('.').map(Number);
    const today = new Date();
    const year = today.getFullYear();
    return new Date(year, month - 1, day);
  };

  const getWeekdaysLeft = () => {
    const today = new Date();
    const currentDay = today.getDay();
    const daysLeft = 5 - currentDay;

    if (daysLeft < 0) {
      return 0;
    } else if (daysLeft > 5) {
      return 5;
    } else {
      return daysLeft;
    }
  };

  const dateStrings = [...convertToDateStrings(allDates)];

  
  function getNextAvailableDates(inputDates: string[]): string[] {
    // Create an array to store the result
    const resultDates: string[] = [];
  
    // Start date (tomorrow)
    const startDate = addDays(startOfDay(new Date()), 1);
  
    // Calculate the end date (4 weeks from now)
    const endDate = addDays(startDate, 28);
  
    // Function to format dates as 'd.M.'
    const formatDate = (date: Date) => format(date, 'd.M.');
  
    // Function to parse date strings to Date objects
    const parseDateString = (dateString: string) => parse(dateString, 'd.M.', new Date());
  
    // Add the input dates to the result if they are not weekends
    inputDates.forEach((dateString) => {
      const dateObject = parseDateString(dateString);
      if (!isWeekend(dateObject)) {
        resultDates.push(formatDate(dateObject));
      }
    });

    const formattedDateToYearMap: FormattedDateToYearMap  = {};

    // Iterate through the date range
    let currentDate = startDate;
    while (isBefore(currentDate, endDate)) {
      // Check if the current date is not a weekend and is not in the inputDates array
      if (
        !isWeekend(currentDate) &&
        !resultDates.some((date) => date === formatDate(currentDate))
      ) {
        formattedDateToYearMap[formatDate(currentDate)] = currentDate.getFullYear();
        resultDates.push(formatDate(currentDate));
      }
  
      // Move to the next day
      currentDate = addDays(currentDate, 1);
    }
  
    // Sort the resultDates array in ascending order
    resultDates.sort((a, b) => {
      const d1Year = formattedDateToYearMap[a];
      const d1 = parseDateString(a).setFullYear(d1Year);
      const d2Year = formattedDateToYearMap[b];
      const d2 = parseDateString(b).setFullYear(d2Year);

      return d1 - d2;
    });

    return resultDates;
  }

  const updatedDateStrings = getNextAvailableDates(dateStrings);

  const weekDates = updatedDateStrings.slice(0, getWeekdaysLeft() + 5);

  const [firstRowDates, setFirstRowDates] = useState<string[]>(weeksInFuture === 0 ? weekDates.slice(0, getWeekdaysLeft()) : weekDates.slice(0, 5));
  const [secondRowDates, setSecondRowDates] = useState<string[]>(weekDates.slice(getWeekdaysLeft()));

  const remainingDates = updatedDateStrings.slice(getWeekdaysLeft() + 5);

  const getWeekday = (date: string) => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);
    const dayAfterTomorrow = new Date(today);
    dayAfterTomorrow.setDate(today.getDate() + 2);

    const selectedDate = toDate(
      utcToZonedTime(convertToZonedTime(date), TZ).getTime(),
      { timeZone: TZ }
    );

    if (selectedDate.toDateString() === tomorrow.toDateString()) {
      return 'Huomenna';
    } else if (selectedDate.toDateString() === dayAfterTomorrow.toDateString()) {
      return 'Ylihuomenna';
    } else {
      const weekday = ['Sunnuntai', 'Maanantai', 'Tiistai', 'Keskiviikko', 'Torstai', 'Perjantai', 'Lauantai'];
      return weekday[selectedDate.getDay()];
    }
  };

  const getWeekLabel = (row: number): string => {
    const currentWeekNumber = getWeek(new Date());
    const futureWeekNumber = getFutureWeekNumber();

    if (row === currentWeekNumber) {
      return 'Tällä viikolla';
    } else if (row === futureWeekNumber && weeksInFuture === 0) {
      return 'Ensi viikolla';
    } else {
      return `Viikko ${row}`;
    }
  };

  const [currentWeekNumber, setCurrentWeekNumber] = useState<number>(getWeek(new Date()));

  const getFutureWeekNumber = (weeksCountOffset?: number): number => getWeek(addWeeks(setWeek(new Date(), currentWeekNumber), weeksCountOffset || 1));

  const handleBack = () => {
    const previousFirstWeekStrings = weekDates.slice(0, getWeekdaysLeft());

    const previousSecondWeekStrings = weekDates.slice(getWeekdaysLeft());
  
    setFirstRowDates(previousFirstWeekStrings);
    setSecondRowDates(previousSecondWeekStrings);
    setWeeksInFuture(0);
    setCurrentWeekNumber(getWeek(new Date()));
  };
  
  const handleLater = () => {
    const nextWeekDateStrings = remainingDates.slice(
      weeksInFuture === 0 ? 0 : 10,
      weeksInFuture === 0 ? 5 : 15
    );
  
    const secondNextWeekDateStrings = remainingDates.slice(
      weeksInFuture === 0 ? 5 : 15,
      weeksInFuture === 0 ? 10 : 20
    );
  
    setFirstRowDates(nextWeekDateStrings);
    setSecondRowDates(secondNextWeekDateStrings);
    setWeeksInFuture(weeksInFuture + 2);
    setCurrentWeekNumber(getFutureWeekNumber(2));
  };

  return !loading ? (
    <div className={classes.dates}>
      <div className={classes.textContainer}>
        <Typography variant="body1" className={classes.fieldTitle}>
          {getWeekLabel(currentWeekNumber)}
        </Typography>
        <span className={classes.line} />
      </div>
      <div className={classes.datesRow}>
        {firstRowDates.map((date: string) => (
          <Button
            key={date}
            className={clsx(classes.dateButton, { active: selectedDate === date, disabled: !dateStrings.includes(date) })}
            onClick={() => handleDateChange(convertToDate(date))}
          >
            <Typography className={classes.date}>{date}</Typography>
            <Typography className={classes.weekday}>{getWeekday(date)}</Typography>
            {selectedDate === date && (
              <span className={classes.check}>
                <Check className={classes.checkIcon} />
              </span>
            )}
          </Button>
        ))}
      </div>
      <div className={classes.textContainer}>
        <Typography variant="body1" className={classes.fieldTitle}>
          {getWeekLabel(getFutureWeekNumber())}
        </Typography>
        <span className={classes.line} />
      </div>
      <div className={classes.datesRow}>
        {secondRowDates.map((date) => (
          <Button
            key={date}
            className={clsx(classes.dateButton, { active: selectedDate === date, disabled: !dateStrings.includes(date) })}
            onClick={() => handleDateChange(convertToDate(date))}
          >
            <Typography className={classes.date}>{date}</Typography>
            <Typography className={classes.weekday}>{getWeekday(date)}</Typography>
            {selectedDate === date && (
              <span className={classes.check}>
                <Check className={classes.checkIcon} />
              </span>
            )}
          </Button>
        ))}
      </div>
      <div className={classes.arrows}>
        {weeksInFuture > 0 && (
          <Button className={clsx(classes.button, { backward: true })} onClick={handleBack}>
            <Backward /> Takaisin
          </Button>
        )}
        {((remainingDates.length > 0 && weeksInFuture === 0 && parseDate(convertToDateStrings(allDates)[allDates.length - 1]) >= parseDate(remainingDates[0])) ||
          (remainingDates.length > 10 && weeksInFuture === 2 && parseDate(convertToDateStrings(allDates)[allDates.length - 1]) >= parseDate(remainingDates[10])) ||
          (remainingDates.length > 20 && weeksInFuture === 4 && parseDate(convertToDateStrings(allDates)[allDates.length - 1]) >= parseDate(remainingDates[20]))) && (
            <Button className={classes.button} onClick={handleLater}>
              Myöhemmin <Forward />
            </Button>
        )}
      </div>
    </div>
  ) : (
    <Spinner variant="medium" />
  );
};

export default SelectDay;
