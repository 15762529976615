import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import clsx from 'clsx';

import { RootState } from '../../store';
import { setNavigation } from '../../store/ui/actions';

import ActivityHeader from '../ActivityFeed/Header';
import Logo from './Logo';
import Navigation from './Navigation';

import useStyles from './styles';
import { ReactComponent as Menu } from '../../images/svg/menu_light.svg';
import { ReactComponent as MenuClose } from '../../images/svg/menu_close.svg';
import UserSearch from './UserSearch';

export const Header = (): React.ReactElement => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isActivityFeedOpen = useSelector(
    (state: RootState) => state.ui.isActivityFeedOpen
  );
  const isNavigationOpen = useSelector(
    (state: RootState) => state.ui.isNavigationOpen
  );
  const isPageScolledDown = useSelector(
    (state: RootState) => state.ui.pageScrolledDown
  );
  const viewedUser = useSelector((state: RootState) => state.ui.viewedUser);
  return (
    <div
      className={clsx(classes['header-container'], {
        open: isNavigationOpen,
        'scrolled-down': isPageScolledDown,
      })}
    >
      <div
        className={clsx(classes.header, {
          open: isNavigationOpen,
          'viewed-user': viewedUser !== null,
        })}
      >
        <div
          className={clsx(classes['logo-container'], {
            open: isNavigationOpen,
          })}
        >
          <button
            className={clsx(classes.burger, { open: isNavigationOpen })}
            onClick={() => dispatch(setNavigation(!isNavigationOpen))}
            aria-label="Päävalikko"
          >
            {isNavigationOpen ? <MenuClose /> : <Menu />}
          </button>

          <Logo />
        </div>
        <Navigation />

        <div className={clsx(classes.search, { open: isNavigationOpen })}>
          <UserSearch />
        </div>

        <div
          className={clsx(classes['activity-header'], {
            'feed-open': isActivityFeedOpen,
            'nav-open': isNavigationOpen,
          })}
        >
          <ActivityHeader className={clsx({ 'nav-open': isNavigationOpen })} />
        </div>
      </div>
    </div>
  );
};
