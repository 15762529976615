import React from 'react';
import { useLocation } from 'react-router-dom';

import Nav from './nav';

import { getRelativeNav } from '../../routes/navItems';
import { NavSpec } from '../../types/local';
import useStyles from './styles';

const SettingsBar = (props: {
  title?: string;
  showNav?: boolean;
  customNav?: NavSpec[];
}): React.ReactElement => {
  const { title, showNav, customNav } = props;
  const classes = useStyles();
  const location = useLocation();
  const relNav = showNav ? getRelativeNav(location) : [];
  const navItems = customNav ? [...customNav, ...relNav] : relNav;
  return (
    <div className={classes['settings-bar']}>
      {title && <div>{title}</div>}
      <Nav items={navItems} submenu />
      <div className={classes.spacer}></div>
    </div>
  );
};

export default SettingsBar;
