import React from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';

import { Typography } from '@material-ui/core';
import { RootState } from '../../store';
import SettingsBar from '../../components/SettingsBar';

import useStyles from './styles';
import { NavSpec } from '../../types/local';
import LoadingIndicator from '../../components/LoadingIndicator';
export interface IPageProps {
  title: React.ReactFragment | string;
  titleRowComponents?: React.ReactElement | React.ReactElement[];
  barTitle?: string;
  children: React.ReactNode;
  showNav?: boolean;
  customNav?: NavSpec[];
  loading?: boolean;
  noDataMessage?: string | React.ReactElement;
  noDataMessageSmallprint?: string | React.ReactElement;
  alert?: React.ReactNode;
}

const Page = (props: IPageProps): React.ReactElement => {
  const classes = useStyles();
  const isNavigationOpen = useSelector(
    (state: RootState) => state.ui.isNavigationOpen
  );

  const {
    title,
    titleRowComponents,
    barTitle,
    children,
    showNav,
    customNav,
    loading,
    noDataMessage,
    noDataMessageSmallprint,
    alert,
  } = props;
  return (
    <div className={classes['page-content']}>
      {alert && <div className={classes.alert}>{alert}</div>}
      <div className={classes['title-content']}>
        <div className={classes.title}>
          <Typography variant="h1">{title}</Typography>
          {!loading && !noDataMessage && (
            <div className={classes['title-components']}>
              {titleRowComponents}
            </div>
          )}
        </div>
      </div>
      <div
        className={clsx(classes['center-content'], {
          'nav-open': isNavigationOpen,
        })}
      >
        {(barTitle || showNav || customNav) && (
          <SettingsBar
            title={barTitle}
            showNav={showNav}
            customNav={customNav}
          />
        )}
        {loading && (
          <div className={classes['loading-indicator']}>
            <LoadingIndicator variant="medium" />
          </div>
        )}
        <div className={classes['content-container']}>
          {!loading && noDataMessage && (
            <div className={classes['no-data']}>
              <Typography variant="h3">{noDataMessage}</Typography>
              <Typography variant="caption">
                {noDataMessageSmallprint}
              </Typography>
            </div>
          )}
          {!loading && !noDataMessage && children}
        </div>
      </div>
    </div>
  );
};

export default Page;
