import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '322px',
    background: theme.palette.grey['200'],
    borderRadius: '23px 23px 23px 0px',
    padding: '32px',
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      width: '100%',
    },
  },
  textBox: {
    '& .section': {
      '&:not(:first-child)': {
        marginTop: '32px',
      },
      '& .title': {
        textTransform: 'uppercase',
        marginBottom: '16px',
        fontWeight: 500,
      },
    },
  },
}));

export default useStyles;
